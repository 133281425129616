import React from "react";
import { Container } from "react-bootstrap";
import DonationTable from "../../components/DonationTable";

function Donation() {
  return (
    <Container>
      <DonationTable url="/api/donation" />
    </Container>
  );
}

export default Donation;
