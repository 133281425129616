import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../services/api";

export default function UsersCreate() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    await api
      .post("/api/auth/register", {
        name: fullName,
        email: email,
        password: password,
      })
      .then((resp) => {
        toast.success("✅ Usuário criado com successo!");
        setFullName("");
        setEmail("");
        setPassword("");
        setLoading(false);

        setTimeout(() => {
          history.push("/users");
        }, 2000);
      })
      .catch((errr) => {
        toast.error("❌ Não foi possível criar o usuário!");
        setFullName("");
        setEmail("");
        setPassword("");
        setLoading(false);
      });
  }

  return (
    <Container>
      <h1>Criar Usuário</h1>

      <Link to="/users">Voltar</Link>

      <Form className="mt-4" onSubmit={handleSubmit}>
        <Form.Group controlId="name">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Digite o nome e sobrenome"
            className="w-50"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            type="email"
            placeholder="Digite o e-mail"
            className="w-50"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Digite a senha"
            className="w-50"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Row id="showPass">
          <Button
            variant="link"
            size="sm"
            onClick={() => setShowPassword(!showPassword)}
          >
            <small>Mostrar senha</small>
          </Button>
        </Form.Row>

        <Button variant="primary" className="mt-3" type="submit">
          {loading ? "Salvando..." : "Salvar"}
        </Button>
      </Form>
    </Container>
  );
}
