import { parse, isDate } from "date-fns";

import moment from "moment";

export function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}

export function formatDateAndHours(date) {
  return moment(date).format("DD/MM/YYYY HH:MM");
}

export function formatDateAndHoursUS(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

export function parseDateString(originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}

export function parseYearString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy", new Date());

  return parsedDate;
}

export function formatDateISO(date) {
  const dateMomentObject = moment(date, "DD/MM/YYYY");
  const dateObject = dateMomentObject.toDate();
  return moment(dateObject).format("YYYY-MM-DD");
}

export function formatBRDate(date) {
  const dateMomentObject = moment(date, "YYYY-MM-DD");

  const dateObject = dateMomentObject.toDate();

  return moment(dateObject).format("DD/MM/YYYY");
}

export function formatAndValidDateISO(date) {
  const dateMomentObject = moment(date, "DD/MM/YYYY");

  const dateObject = dateMomentObject.toDate();

  const parsedDate = moment(dateObject).format("YYYY-MM-DD");

  return {
    parsedDate: parsedDate,
    isDate: !!dateMomentObject.isValid(),
  };
}

export function formatDateTimeISO(date) {
  const dateMomentObject = moment(date, "DD/MM/YYYY HH:mm");

  const dateObject = dateMomentObject.toDate();

  const parsedDate = moment(dateObject).format("YYYY-MM-DD HH:mm:ss");

  return {
    parsedDate: parsedDate,
    isDate: !!dateMomentObject.isValid(),
  };
}
