import React, { useState, useEffect, useCallback } from "react";
import { Table, Spinner, Button, Badge, ButtonGroup } from "react-bootstrap";
import { FaEye, FaPlus, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import api from "../../services/api";

function UsersTable({ url }) {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPag] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUsers = useCallback(async () => {
    setLoading(true);

    await api.get(`${url}?page=${page}`).then((resp) => {
      setUsers(resp.data.data);
      setLastPag(resp.data.last_page);
      setTotal(resp.data.total);
      setLoading(false);

      if (page === 1) {
        setIsFirstPage(true);
      } else {
        setIsFirstPage(false);
      }

      if (page === lastPage) {
        setIsLastPage(true);
      } else {
        setIsLastPage(false);
      }
    });
  }, [lastPage, page, url]);

  function nextPage() {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  //action functions

  async function handleDisapproved(userId) {
    setActionLoading(true);
    await api
      .put(`/api/auth/disapproved/${userId}`)
      .then((resp) => {
        setActionLoading(false);
        toast.success("✅ Acesso removido com sucesso!");
      })
      .catch((error) => {
        setActionLoading(false);
        toast.error("❌ Não foi possível remover acesso!");
      });

    loadUsers();
  }

  async function handleApproved(userId) {
    setActionLoading(true);
    await api
      .put(`/api/auth/approved/${userId}`)
      .then((resp) => {
        setActionLoading(false);
        toast.success("✅ Acesso concedido com sucesso!");
      })
      .catch((error) => {
        setActionLoading(false);
        toast.error("❌ Não foi possível dar acesso!");
      });

    loadUsers();
  }

  async function handleRemoveUser(userId) {
    setActionLoading(true);

    await api
      .delete(`/api/user/delete/${userId}`)
      .then((resp) => {
        toast.success("✅ Usuário removido com sucesso!");
        setActionLoading(false);
      })
      .catch((error) => {

        if (error.response.status === 409) {
          setActionLoading(false);
          return toast.error(
            "❌ Este usuário é o logado atualmente!"
          );
        }

        toast.error("❌ Não foi possível excluir o usuário!");
        setActionLoading(false);
      });
    loadUsers();
  }

  return (
    <>
      <div className="text-center mb-4">
        <h2>Usuários do Sistema</h2>
      </div>

      <Link to="/users-create" className="btn btn-sm btn-primary mb-3">
        <FaPlus className="mr-1" /> Novo Usuário
      </Link>

      <Table striped bordered responsive hover className="text-center">
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>
              {actionLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                  "Acesso Concedido"
                )}
            </th>
            <th>Acesso</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="5" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {users.map((u) => (
            <tr key={u.id}>
              <td>
                <>
                  {u.name}{" "}
                  {u.profile === 'admin' ? (
                    <Badge pill variant="primary">
                      admin
                    </Badge>
                  ) : null}
                </>
              </td>
              <td>{u.email}</td>
              <td>
                {u.is_approved ? (
                  <h5>
                    <Badge variant="success">Sim</Badge>
                  </h5>
                ) : (
                    <h5>
                      <Badge variant="danger">Não</Badge>
                    </h5>
                  )}
              </td>
              <td>
                {u.is_approved ? (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDisapproved(u.id)}
                  >
                    Retirar
                  </Button>
                ) : (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleApproved(u.id)}
                    >
                      Conceder
                    </Button>
                  )}
              </td>
              <td>
                <ButtonGroup size="sm">
                  <Link to={`/users-view/${u.id}`} className="btn btn-primary">
                    <FaEye color="#fff" />
                  </Link>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveUser(u.id)}
                  >
                    <FaTrash />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <Button
          variant="link"
          onClick={() => setPage(1)}
          disabled={isFirstPage}
        >
          Primeiro
        </Button>
        <Button variant="primary" onClick={prevPage} disabled={isFirstPage}>
          <RiArrowLeftSLine />
          Anterior
        </Button>
        <h5>Total: {total}</h5>
        <Button variant="primary" onClick={nextPage} disabled={isLastPage}>
          Próximo
          <RiArrowRightSLine />
        </Button>
        <Button
          variant="link"
          onClick={() => setPage(lastPage)}
          disabled={isLastPage}
        >
          Último
        </Button>
      </div>
    </>
  );
}

export default UsersTable;
