import { useCallback, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import GoBackButton from "../../components/GoBackButton";
import Loading from "../../components/Loading";
import api from "../../services/api";

function MissionaryCampaignReports() {
  const [baseYear, setBaseYear] = useState(new Date());

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [reports] = useState([
    { type: "quantity", name: "Quantidade de Materiais", disabled: false },
    {
      type: "diocese_that_did_not_request",
      name: "Dioceses que não solicitaram materiais",
      disabled: false,
    },
    {
      type: "diocese_that_requested",
      name: "Materiais por dioceses ",
      disabled: false,
    },
    { type: "amount_to_receipt", name: "Valores a receber ", disabled: true },
    { type: "box_weight", name: "Peso/Qtde Caixas ", disabled: false },
  ]);

  const handleDownload = useCallback(
    async (type) => {
      setDownloadLoading(true);
      await api
        .get(
          `/api/missionary-campaign/report?baseYear=${baseYear.getFullYear()}&type=${type}`,
          {
            responseType: "blob",
          }
        )
        .then((resp) => {
          let filename = resp.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");

          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`); //or any other extension
          document.body.appendChild(link);
          link.click();
          setDownloadLoading(false);

          toast.success("✅ Download concluído!");
        })
        .catch((errors) => {
          toast.error(
            "❌ Não foi possível fazer o download, tente novamente mais tarde!"
          );

          setDownloadLoading(false);

          console.log(errors);
        });
    },
    [baseYear]
  );

  if (downloadLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <GoBackButton color="primary" isButton={false} />
      <div className="text-center">
        <h1>Relatórios Campanha Missionária</h1>
      </div>

      <Row className="mt-5">
        <Form>
          <Form.Label>
            <strong>Ano Base</strong>
          </Form.Label>

          <DatePicker
            selected={baseYear}
            onChange={(date) => setBaseYear(date)}
            showYearPicker
            dateFormat="yyyy"
            yearItemNumber={9}
            timeInputLabel="Label"
            className="form-control"
          />
        </Form>

        <Table striped bordered hover responsive className="text-center mt-2">
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((item, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                <td style={{ verticalAlign: "middle", fontSize: "1.2rem" }}>
                  <strong>
                    <u>{item.name}</u>
                  </strong>
                </td>

                <td style={{ verticalAlign: "middle" }}>
                  <ButtonGroup aria-label={`Action General ${index}`}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-excel-report-${index}`}>
                          Clique para baixar
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          handleDownload(item.type);
                        }}
                        disabled={item.disabled}
                      >
                        <FaDownload size={16} className="mr-2" />
                        Baixar
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default MissionaryCampaignReports;
