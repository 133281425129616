import React, { useState } from "react";
import { Container, Card, CardDeck, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "../../services/api";

function Finance() {
  const { register, handleSubmit, reset } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (!data["returns"]) {
      toast.error("Adicione pelo menos um arquivo retorno");
      return;
    }

    for (const key of Object.keys(data["returns"])) {
      formData.append(`returns[${key}]`, data["returns"][key]);
    }

    setLoading(true);

    await api
      .post("/api/finance", formData)
      .then((resp) => {
        setLoading(false);

        const filesCount = resp.data.archives;
        toast.success(`${filesCount} Arquivo(s) Retorno enviado(s)`);

        reset();
      })
      .catch((err) => {
        setLoading(false);

        reset();

        toast.error("Arquivo(s) de Retorno não pode(m) ser enviado(s)");
      });
  };

  return (
    <Container>
      <h1 className="text-center mb-4">Financeiro</h1>

      <CardDeck>
        <Card>
          <Card.Body>
            <Card.Title>Enviar Arquivo Retorno</Card.Title>

            <Form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
              method="POST"
            >
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Default file input example</Form.Label>
                <Form.Control
                  type="file"
                  name="returns[]"
                  accept=".RET,.ret"
                  required
                  multiple={true}
                  ref={register}
                />
              </Form.Group>

              <Button type="submit">{loading ? "Aguarde..." : "Enviar"}</Button>
            </Form>
          </Card.Body>
        </Card>
      </CardDeck>
    </Container>
  );
}

export default Finance;
