import React, { useEffect, useState } from "react";
import { Col, Spinner, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import "./styles.css";

import api from "../../../services/api";
import PublicContent from "../Layout";
import { isEmptyObject } from "../../../utils/checkObject";
import { Link, useHistory } from "react-router-dom";
import { Card } from "../styles";
import GoBackButton from "../../../components/GoBackButton";

function MissionaryCollectionPublic() {
  const history = useHistory();
  const [modules, setModules] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get("/api/missionary-collection/active")
        .then((resp) => {
          if (isEmptyObject(resp.data)) {
            setLoading(false);

            setTimeout(() => {
              history.push("/");
            }, 3500);

            toast.info(
              "ℹ Não há nenhuma Coleta aberta no momento, tente novamente depois!",
              { autoClose: 3000 }
            );
          }

          const data = resp.data.map((i) => ({
            ...i,
            link: `/missionary-collection/${i.id}`,
          }));

          setModules(data);

          setLoading(false);
        })
        .catch((error) => {
          toast.error(
            "❌ Não foi possível carregar os dados, tente novamente depois!",
            { autoClose: 8000 }
          );
          setLoading(false);
        });
    })();
  }, [history]);

  return (
    <>
      <PublicContent>
        <GoBackButton />
        <h2 className="text-center text-muted mb-5">
          Selecione uma Coleta para continuar
        </h2>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Row className="d-flex justify-content-center">
            {modules.map(
              (item, index) =>
                !item.disabled && (
                  <Col md={4} sm={12} key={index}>
                    <Link
                      to={item.link}
                      className={item.disabled ? "disabled-link" : ""}
                    >
                      <Card>
                        <h5>{item.description}</h5>
                      </Card>
                    </Link>
                  </Col>
                )
            )}
          </Row>
        )}
      </PublicContent>
    </>
  );
}

export default MissionaryCollectionPublic;
