import { useCallback, useEffect, useState } from "react";
import PublicContent from "../Layout";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { isEmptyObject } from "../../../utils/checkObject";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Spinner,
  ProgressBar,
} from "react-bootstrap";
import { removeEspecialCharacters } from "../../../utils/removeMask";
import SubmitButtonApp from "../../../components/SubmitButton";
import GoBackButton from "../../../components/GoBackButton";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import InputMask from "react-input-mask";
import { FaSearch } from "react-icons/fa";

function MissionaryCampaignPublicNew() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [champaingn, setChampaingn] = useState({});

  const [dioceseToSearch, setDioceseToSearch] = useState("");
  const [searchedDioceses, setSearchedDioceses] = useState([]);
  const [diocese, setDiocese] = useState({
    id: "",
    name: "",
  });
  const [searchLoading, setSearchLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);

  const searchDiocese = useCallback(async () => {
    setSearchLoading(true);
    setSearchedDioceses("");

    await api
      .get(`/api/diocese/search?query=${dioceseToSearch}`)
      .then((resp) => {
        if (!resp.data) {
          setSearchedDioceses("");
          setSearchLoading(false);
          setShowTable(true);
          toast.warning("⁉ Nenhuma diocese encontrada");
          return;
        }

        setSearchedDioceses(resp.data);
        setSearchLoading(false);
        setShowTable(true);
      })
      .catch((error) => {
        setSearchedDioceses("");
        setSearchLoading(false);
        setShowTable(true);
      });
  }, [dioceseToSearch]);

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#bababa",
    columnWidth: "60px",
    onSelect: onRowSelect,
  };

  function onRowSelect(row) {
    setDiocese({ id: row.id, name: row.name });
  }

  const resetSolicitationForm = useCallback(() => {
    setName("");
    setEmail("");
    setTelephone("");
    setShowTable(false);
    setDioceseToSearch("");
    setSearchedDioceses([]);
    setDiocese({ id: "", name: "" });
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setSubmitLoading(true);

      const { id: dioceseId } = diocese;

      if (!dioceseId) {
        toast.error(
          "❌ Diocese não informada, por favor busque e selecione uma Diocese."
        );
        setSubmitLoading(false);
        return;
      }

      await api
        .post("/api/missionary-campaign/solicitation?origin=public", {
          name: name,
          email: email,
          telephone: removeEspecialCharacters(telephone),
          diocese_id: dioceseId,
          missionary_campaign_id: champaingn.id,
        })
        .then((resp) => {
          setSubmitLoading(false);
          toast.success("✅ Solicitação criada com sucesso!");
          resetSolicitationForm();
          history.push(`/champaing-solicitation-view/${resp.data.code}`);
        })
        .catch((errors) => {
          if (errors.response) {
            Object.values(errors.response.data.errors).forEach((item) => {
              toast.error(`❌ ${item}`, { autoClose: 8000 });
            });
            setSubmitLoading(false);
            return;
          }

          toast.error(
            "❌ Não foi possível criar uma solicitação, tente novamente!"
          );
          setSubmitLoading(false);
        });
    },
    [
      champaingn.id,
      diocese,
      email,
      history,
      name,
      resetSolicitationForm,
      telephone,
    ]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get("/api/missionary-campaign/active")
        .then((resp) => {
          if (isEmptyObject(resp.data)) {
            toast.info(
              "ℹ Não há nenhuma Campanha aberta no momento, tente novamente depois!",
              { autoClose: 8000 }
            );
            setLoading(false);
            setTimeout(() => {
              history.goBack();
            }, 1000);
          }

          setChampaingn(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          toast.error(
            "❌ Não foi possível carregar os dados, tente novamente depois!",
            { autoClose: 8000 }
          );
          setLoading(false);
        });
    })();
  }, [history]);

  return (
    <PublicContent>
      <Container>
        <GoBackButton />

        {loading ? (
          <div className="text-center">
            <Spinner variant="primary" animation="border" />
          </div>
        ) : (
          <>
            <h1 className="text-center text-muted">{champaingn.description}</h1>
            <Alert variant="warning" className="mt-3 mb-4 text-center">
              <Alert.Heading>Aviso Importante!</Alert.Heading>
              <p>
                Preencha os dados para gerar uma solicitação! Após a conclusão
                você será notificado por e-mail com o link e o código da sua
                solicitação.
              </p>
            </Alert>

            <ProgressBar now={33.33} label="Passo 1" />

            <Form onSubmit={onSubmit} method="POST" className="mt-4">
              <Form.Row>
                <Form.Group as={Col} controlId="name">
                  <Form.Label>Nome do Solicitatante</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Digite o nome do Solicitatante"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="email">
                  <Form.Label>E-mail do Solicitatante</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Digite o e-mail do solicitatante"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="telephone">
                  <Form.Label>Digite o telefone do Solicitatante</Form.Label>
                  <InputMask
                    type="tel"
                    name="telephone"
                    className="form-control"
                    mask="(99) 99999-9999"
                    placeholder="Telefone do Solicitatante"
                    required
                    onChange={(e) => setTelephone(e.target.value)}
                    value={telephone}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row className="d-flex align-items-center">
                <Col xs="8" className="my-2">
                  <Form.Control
                    className="mb-2"
                    placeholder="Digite o nome da Diocese e clique em buscar 🔎"
                    onChange={(e) => setDioceseToSearch(e.target.value)}
                    value={dioceseToSearch}
                  />
                </Col>
                <Col xs="auto" className="my-1">
                  <Button className="mb-2" onClick={searchDiocese}>
                    {searchLoading ? (
                      <>
                        {" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      <FaSearch />
                    )}
                  </Button>
                </Col>
              </Form.Row>

              <Form.Row className="mb-4">
                {showTable ? (
                  searchLoading ? (
                    <div className="text-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <>
                      <BootstrapTable
                        data={searchedDioceses}
                        striped
                        hover
                        selectRow={selectRowProp}
                      >
                        <TableHeaderColumn dataField="id" isKey hidden>
                          ID da Diocese
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="name">
                          Nome
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="city">
                          Cidade
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="uf" width="60">
                          UF
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </>
                  )
                ) : (
                  ""
                )}
              </Form.Row>

              <SubmitButtonApp
                color="primary"
                type="submit"
                loading={submitLoading}
              >
                Gerar Solicitação
              </SubmitButtonApp>
            </Form>
          </>
        )}
      </Container>
    </PublicContent>
  );
}

export default MissionaryCampaignPublicNew;
