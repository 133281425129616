import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";

//private pages
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import ProfileAuth from "../pages/Profile/Auth";
import Dashboard from "../pages/Dashboard";
import ParishRegion from "../pages/ParishRegion";
import ParishRegionView from "../pages/ParishRegion/view";
import ParishRegionCreate from "../pages/ParishRegion/create";
import Dioceses from "../pages/Dioceses";
import DiocesesCreate from "../pages/Dioceses/create";
import DioceseView from "../pages/Dioceses/view";
import Users from "../pages/Users";
import UsersView from "../pages/Users/view";
import UsersCreate from "../pages/Users/create";
import Parish from "../pages/Parish";
import ParishView from "../pages/Parish/view";
import ParishCreate from "../pages/Parish/create";
import Donation from "../pages/Donation";
import DonationCreate from "../pages/Donation/create";
import DonationView from "../pages/Donation/view";
import SafeBox from "../pages/SafeBox/index";
import SafeBoxCreate from "../pages/SafeBox/create";
import SafeBoxView from "../pages/SafeBox/view";
import MissionaryCollection from "../pages/MissionaryCollection";
import MissionaryCollectionView from "../pages/MissionaryCollection/view";
import MissionaryCollectionCreate from "../pages/MissionaryCollection/create";
import MissionaryCollectionContrib from "../pages/MissionaryCollectContrib";
import MissionaryCampaign from "../pages/MissionaryCampaign";
import Reports from "../pages/Reports";
import GlobalConfig from "../pages/GlobalConfig";
import MissionaryCampaignView from "../pages/MissionaryCampaign/view";
import MissionaryCampaignCreate from "../pages/MissionaryCampaign/create";
import ChampaingnSolicitationView from "../pages/ChampaingnSolicitation";
import MissionaryCampaignSettings from "../pages/MissionaryCampaign/setting";
import MissionaryCampaignReports from "../pages/MissionaryCampaign/reports";
import Finance from "../pages/Finance";
import { MissionaryCollectionReport } from "../pages/MissionaryCollection/report";
import SafeBoxReports from "../pages/Donation/reports";

function PrivateRoutes() {
  return (
    <Switch>
      {/* Private Routes */}

      <Route exact path="/" component={Home} isPrivate />
      <Route exact path="/profile" component={Profile} isPrivate />
      <Route exact path="/profile-auth" component={ProfileAuth} isPrivate />

      <Route exact path="/parish-region" component={ParishRegion} isPrivate />
      <Route
        exact
        path="/parish-region-view/:id"
        component={ParishRegionView}
        isPrivate
      />
      <Route
        exact
        path="/parish-region-create"
        component={ParishRegionCreate}
        isPrivate
      />
      <Route exact path="/dioceses" component={Dioceses} isPrivate />

      <Route
        exact
        path="/diocese-create"
        component={DiocesesCreate}
        isPrivate
      />

      <Route exact path="/diocese-view/:id" component={DioceseView} isPrivate />

      <Route exact path="/dashboard" component={Dashboard} isPrivate />

      <Route exact path="/users" component={Users} isPrivate />

      <Route exact path="/users-view/:id" component={UsersView} isPrivate />

      <Route exact path="/users-create" component={UsersCreate} isPrivate />

      <Route exact path="/parish" component={Parish} isPrivate />

      <Route exact path="/parish-view/:id" component={ParishView} isPrivate />

      <Route exact path="/parish-create" component={ParishCreate} isPrivate />

      <Route exact path="/donation" component={Donation} isPrivate />

      <Route
        exact
        path="/donation-create"
        component={DonationCreate}
        isPrivate
      />

      <Route
        exact
        path="/donation-view/:id"
        component={DonationView}
        isPrivate
      />

      <Route exact path="/safe-box" component={SafeBox} isPrivate />

      <Route
        exact
        path="/safe-box/reports"
        component={SafeBoxReports}
        isPrivate
      />

      <Route
        exact
        path="/safe-box-create"
        component={SafeBoxCreate}
        isPrivate
      />

      <Route
        exact
        path="/safe-box-view/:id"
        component={SafeBoxView}
        isPrivate
      />

      <Route
        exact
        path="/missionary-collection"
        component={MissionaryCollection}
        isPrivate
      />

      <Route
        exact
        path="/missionary-collection-view/:id"
        component={MissionaryCollectionView}
        isPrivate
      />

      <Route
        exact
        path="/missionary-collection-create"
        component={MissionaryCollectionCreate}
        isPrivate
      />

      <Route
        exact
        path="/missionary-collection-reports"
        component={MissionaryCollectionReport}
        isPrivate
      />

      <Route
        exact
        path="/missionary-collection-contrib/create/:missionary_collection_id"
        component={MissionaryCollectionContrib}
        isPrivate
      />

      <Route
        exact
        path="/missionary-campaign-create"
        component={MissionaryCampaignCreate}
        isPrivate
      />
      <Route
        exact
        path="/missionary-campaign"
        component={MissionaryCampaign}
        isPrivate
      />

      <Route
        exact
        path="/missionary-campaign-setting"
        component={MissionaryCampaignSettings}
        isPrivate
      />

      <Route
        exact
        path="/missionary-campaign-view/:id"
        component={MissionaryCampaignView}
        isPrivate
      />

      <Route
        exact
        path="/missionary-campaign-report"
        component={MissionaryCampaignReports}
        isPrivate
      />

      <Route
        exact
        path="/campaign-solicitation-view/:id"
        component={ChampaingnSolicitationView}
        isPrivate
      />

      <Route exact path="/finance" component={Finance} isPrivate />

      <Route exact path="/reports" component={Reports} isPrivate />

      <Route exact path="/global-settings" component={GlobalConfig} isPrivate />

      <Redirect to="/" />
    </Switch>
  );
}

export default PrivateRoutes;
