import React, { useState } from "react";
import { Container, Button, Spinner, Col, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { FaSave } from "react-icons/fa";

import api from "../../services/api";
import axios from "axios";
import { toast } from "react-toastify";
import { removeEspecialCharacters } from "../../utils/removeMask";
import { FiSearch } from "react-icons/fi";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

function ParishCreate() {
  const history = useHistory();

  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [newParish, setNewParish] = useState("");
  const [newTelephone, setNewTelephone] = useState("");
  const [newContactMail, setNewContactMail] = useState("");
  const [newDioceseId, setNewDioceseId] = useState("");
  const [newZipCode, setNewZipCode] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newNeighborhood, setNewNeighborhood] = useState("");
  const [newComplement, setNewComplement] = useState("");
  const [newNumber, setNewNumber] = useState("");

  //Search Diocese - States and Functions

  const [dioceseToSearch, setDioceseToSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [searchedDiocese, setSearchedDiocese] = useState({});

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitButtonLoading(true);

    await api
      .post("/api/parish", {
        name: newParish,
        zip_code: removeEspecialCharacters(newZipCode),
        address: newAddress,
        complement: newComplement,
        number: newNumber,
        neighborhood: newNeighborhood,
        city: newCity,
        uf: newState,
        telephone: removeEspecialCharacters(newTelephone),
        email: newContactMail,
        diocese_id: newDioceseId,
      })
      .then((resp) => {
        toast.success("✅ Paróquia adicionada com sucesso!");
        setSubmitButtonLoading(false);
        history.push(`/parish-view/${resp.data.id}`);
      })
      .catch((error) => {
        toast.error(
          "❌ Não foi possível adicionar a Paróquia! Verifique os dados e tente novamente!"
        );
        setSubmitButtonLoading(false);
      });
  }

  async function searchDiocese() {
    setSearchLoading(true);

    await api
      .get(`/api/diocese/search?query=${dioceseToSearch}`)
      .then((resp) => {
        setSearchedDiocese(resp.data);
        setSearchLoading(false);
        setShowTable(true);
      })
      .catch((error) => {
        setDioceseToSearch("");
        setSearchLoading(false);
        setShowTable(true);
      });
  }

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#bababa",
    columnWidth: "60px",
    onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    setNewDioceseId(row.id);
  }

  async function searchAddress() {
    await axios
      .get(`https://viacep.com.br/ws/${newZipCode}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          toast.error("❌ CEP inválido!");
          setNewCity("");
          setNewState("");
          setNewAddress("");
          setNewNeighborhood("");
          return;
        }
        toast.success("✅ CEP válido!");
        setNewCity(resp.data.localidade);
        setNewState(resp.data.uf);
        setNewAddress(resp.data.logradouro);
        setNewNeighborhood(resp.data.bairro);
      })
      .catch((error) => {
        toast.error("❌ CEP inválido!");
        setNewCity("");
        setNewState("");
        setNewAddress("");
        setNewNeighborhood("");
      });
  }

  return (
    <Container>
      <h1>Adicionar Nova Paróquia</h1>

      <Link to="/parish">Voltar</Link>

      <form onSubmit={handleSubmit} method="POST" className="mt-4">
        <h5>Dados da Paróquia</h5>
        <hr />
        <div className="form-row">
          <div className="form-group col-md-7">
            <label htmlFor="newParish">Nome da Paróquia</label>
            <input
              type="text"
              className="form-control"
              name="newParish"
              id="newParish"
              value={newParish}
              onChange={(e) => setNewParish(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-5">
            <label htmlFor="telephone">Telefone</label>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Ex: (99) 99999-9999"
              type="tel"
              className="form-control"
              name="telephone"
              id="telephone"
              value={newTelephone}
              onChange={(e) => setNewTelephone(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="contact_email">E-mail para Contato</label>
            <input
              type="email"
              className="form-control"
              name="contact_email"
              id="contact_email"
              value={newContactMail}
              onChange={(e) => setNewContactMail(e.target.value)}
              required
            />
          </div>
        </div>

        <h5>Dados de Endereço</h5>
        <hr />
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="zipCode">CEP</label>
            <InputMask
              mask="99999-999"
              type="text"
              className="form-control"
              name="zipCode"
              id="zipCode"
              value={newZipCode}
              onChange={(e) => setNewZipCode(e.target.value)}
              onBlur={searchAddress}
              required
            />
            <small className="text-primary">
              *Ao digitar um CEP válido e retirar o foco do campo, os campos em
              cinza serão preenchidos automaticamente.
            </small>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="city">Cidade</label>
            <input
              type="text"
              className="form-control"
              name="city"
              id="city"
              value={newCity}
              onChange={(e) => setNewCity(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="state">UF</label>
            <input
              type="text"
              className="form-control"
              name="state"
              id="state"
              value={newState}
              onChange={(e) => setNewState(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-8">
            <label htmlFor="address">Endereço</label>
            <input
              type="text"
              className="form-control"
              name="address"
              id="address"
              value={newAddress}
              onChange={(e) => setNewAddress(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="neighborhood">Bairro</label>
            <input
              type="text"
              className="form-control"
              name="neighborhood"
              id="neighborhood"
              value={newNeighborhood}
              onChange={(e) => setNewNeighborhood(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-5">
            <label htmlFor="complement">Complemento</label>
            <input
              type="text"
              className="form-control"
              name="complement"
              id="complement"
              value={newComplement}
              onChange={(e) => setNewComplement(e.target.value)}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="number">Número</label>
            <input
              type="number"
              className="form-control"
              name="number"
              id="number"
              value={newNumber}
              onChange={(e) => setNewNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-3">
          <hr />
        </div>

        <h3>Diocese que a Paróquia pertence</h3>

        <Form.Row className="align-items-center">
          <Col xs="5" className="my-2">
            <Form.Control
              className="mb-2 mr-sm-2"
              id="parishName"
              placeholder="Busque a Diocese pelo nome"
              onChange={(e) => setDioceseToSearch(e.target.value)}
              value={dioceseToSearch}
            />
          </Col>
          <Col xs="auto" className="my-1">
            <Button className="mb-2" onClick={searchDiocese}>
              {searchLoading ? (
                <>
                  {" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                  <FiSearch />
                )}
            </Button>
          </Col>
        </Form.Row>

        <Form.Row>
          {showTable ? (
            searchLoading ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
                <>
                  <BootstrapTable
                    data={searchedDiocese}
                    striped
                    hover
                    selectRow={selectRowProp}
                  >
                    <TableHeaderColumn dataField="id" isKey hidden>
                      Diocese ID
                  </TableHeaderColumn>
                    <TableHeaderColumn dataField="name">
                      Nome da Diocese
                  </TableHeaderColumn>
                    <TableHeaderColumn dataField="city">Cidade</TableHeaderColumn>
                    <TableHeaderColumn dataField="uf" width="60">
                      UF
                  </TableHeaderColumn>
                    <TableHeaderColumn dataField="contact_mail">
                      E-mail de Contato
                  </TableHeaderColumn>
                  </BootstrapTable>
                </>
              )
          ) : (
              <small>* Faça a busca por uma Diocese</small>
            )}
        </Form.Row>

        <Button variant="primary" type="submit" className="mt-4">
          <FaSave className="mr-1" />{" "}
          {submitButtonLoading ? "Salvando..." : "Salvar"}
        </Button>
      </form>
    </Container>
  );
}

export default ParishCreate;
