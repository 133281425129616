import React, { useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {
  Container,
  Spinner,
  Jumbotron,
  Button,
  Modal,
  Form,
  Table,
  ButtonGroup,
} from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { FiPlus, FiSearch, FiDollarSign } from "react-icons/fi";
import IntlCurrencyInput from "react-intl-currency-input";
import { toast } from "react-toastify";

import api from "../../services/api";
import formatCurrency from "../../utils/formatCurrency";
import { formatDate } from "../../utils/formatDate";
import { FaEdit, FaTrash } from "react-icons/fa";

function Donation() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [donation, setDonation] = useState({});
  const [safeBoxGroups, setSafeBoxGroups] = useState([]);

  //add donation states
  const [modalShow, setModalShow] = useState(false);
  const [searchGroup, setSearchGroup] = useState("");
  const [loadingSearchGroup, setLoadingSearchGroup] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [searchedGroups, setSearchedGroups] = useState([]);
  const [dataToAdd, setDataToAdd] = useState({
    donation_id: id,
    safe_box_group_id: 0,
    value: 0.0,
  });
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  async function loadDonation(value) {
    setLoading(true);

    await api
      .get(`/api/donation/${value}`)
      .then((resp) => {
        setDonation(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  async function loadGroups(value) {
    setLoading(true);

    await api
      .get(`/api/donation/groups-donation/${value}`)
      .then((resp) => {
        const groups = resp.data.map((gp) => {
          const data = {
            ...gp,
            value: parseFloat(gp.value),
            valueCurrency: formatCurrency(parseFloat(gp.value)),
            donation_at_formatted: formatDate(gp.donation_at),
          };

          return {
            ...data,
          };
        });

        setSafeBoxGroups(groups);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadDonation(id);
    loadGroups(id);
  }, [id]);

  const totalValue = useMemo(() => {
    const total = safeBoxGroups.reduce(
      (prevVal, elem) => prevVal + elem.value,
      0
    );

    return total;
  }, [safeBoxGroups]);

  function resetData() {
    setModalShow(false);
    setSearchGroup("");
    setLoadingSearchGroup(false);
    setShowTable(false);
    setSearchedGroups([]);
    setDataToAdd({ ...dataToAdd, safe_box_group_id: 0, value: 0.0 });
  }

  async function groupToSearch(e) {
    e.preventDefault();

    setLoadingSearchGroup(true);

    await api
      .get(`/api/safe_box_groups/search?query=${searchGroup}`)
      .then((resp) => {
        setShowTable(true);
        setSearchedGroups(resp.data);
        setLoadingSearchGroup(false);
      });
  }

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#e9e9e9",
    columnWidth: "60px",
    onSelect: onRowSelect,
  };

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  function onRowSelect(row, isSelected, e) {
    setDataToAdd({ ...dataToAdd, safe_box_group_id: row.id });
  }

  const handleCurrencyInput = (event, value, maskedValue) => {
    event.preventDefault();
    setDataToAdd({ ...dataToAdd, value: value });
  };

  async function handleAddDonation() {
    await api
      .post("/api/groups_donation", dataToAdd)
      .then((resp) => {
        loadDonation(id);
        loadGroups(id);
        resetData();
        setModalShow(false);
        toast.success("Valor incluido com sucesso!");
      })
      .catch((error) => {
        toast.error("Adicione um valor para doação!");
      });
  }

  async function handleFineshedDonation() {
    if (totalValue === 0.0) {
      return toast.warn("A doação ainda está zerada!");
    }
    setLoadingFinished(true);
    await api
      .put(`/api/donation/finished/${id}`, { total_value: totalValue })
      .then((resp) => {
        toast.success("Doação finalizada com sucesso!");
        toast.info("O recibo foi enviado para o(s) e-mail(s) cadastrados!");
        setLoadingFinished(false);
        loadDonation(id);
        loadGroups(id);
      });
  }

  async function handleDeleteDonation(id) {
    setActionLoading(true);
    await api
      .delete(`/api/groups_donation/${id}`)
      .then((resp) => {
        toast.success("Doação removida com sucesso!");
        setSafeBoxGroups((prevState) =>
          prevState.filter((g) => g.donation_id !== id)
        );
        setActionLoading(false);
      })
      .catch((err) => {
        setActionLoading(false);
      });
  }

  console.log(safeBoxGroups);

  return (
    <>
      <Container>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 mb-4">
                <Link to="/donation">Voltar</Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                <Jumbotron fluid className="h-100">
                  <Container>
                    <h1>{donation.title}</h1>
                    <p>{donation.description}</p>
                    <p>
                      <strong>
                        Criada em: {formatDate(donation.created_at)}
                      </strong>
                    </p>
                    <p>
                      <strong>Código de verificação: </strong>
                      {donation.cod}
                    </p>
                  </Container>
                </Jumbotron>
              </div>

              <div className="col-md-3">
                <div className="d-flex flex-column bd-highlight justify-content-between h-100">
                  <div className="bd-highlight">
                    <h2>Total recebido: </h2>
                    <div className="p-3 bg-success text-white text-center">
                      <strong>{formatCurrency(totalValue)}</strong>
                    </div>
                  </div>
                  <div className="bd-highlight">
                    <h2>Status: </h2>
                    <div
                      className={`p-3 text-center text-white ${
                        donation.finished ? "bg-success" : "bg-danger"
                      }`}
                    >
                      <strong>
                        {donation.finished ? "Finalizada" : "Não finalizada"}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-3 pb-3">
              <hr />
            </div>

            <div className="mt-3">
              <div className="d-flex justify-content-between pl-0 pr-0">
                <div className="bd-highlight">
                  <Button
                    variant="warning"
                    size="lg"
                    onClick={handleFineshedDonation}
                    disabled={donation.finished}
                  >
                    {loadingFinished ? "Finalizando ..." : "Finalizar"}
                  </Button>
                </div>
                <div className="bd-highlight">
                  <Button
                    variant="primary"
                    onClick={() => setModalShow(true)}
                    disabled={donation.finished}
                  >
                    <FiPlus /> Contribuinte
                  </Button>
                  <Modal
                    show={modalShow}
                    onHide={resetData}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        <FiDollarSign /> Adicionar Contribuinte
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form inline onSubmit={groupToSearch}>
                        <Form.Label htmlFor="group_name" srOnly>
                          Name do Grupo
                        </Form.Label>
                        <Form.Control
                          className="mb-2 mr-sm-2"
                          id="group_name"
                          placeholder="Busque por um Grupo"
                          value={searchGroup}
                          onChange={(e) => setSearchGroup(e.target.value)}
                        />
                        <Button type="submit" className="mb-2">
                          <FiSearch />
                        </Button>
                      </Form>

                      {showTable ? (
                        loadingSearchGroup ? (
                          <div className="text-center">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        ) : (
                          <>
                            <BootstrapTable
                              data={searchedGroups}
                              selectRow={selectRowProp}
                            >
                              <TableHeaderColumn dataField="id" isKey hidden>
                                Group ID
                              </TableHeaderColumn>
                              <TableHeaderColumn dataField="group_name">
                                Nome do Grupo
                              </TableHeaderColumn>
                              <TableHeaderColumn dataField="representant_name">
                                Representante
                              </TableHeaderColumn>
                              <TableHeaderColumn dataField="contact_mail">
                                E-mail do Representante
                              </TableHeaderColumn>
                              <TableHeaderColumn dataField="city" width="100">
                                Cidade
                              </TableHeaderColumn>
                              <TableHeaderColumn dataField="uf" width="60">
                                UF
                              </TableHeaderColumn>
                            </BootstrapTable>

                            <div className="input-group mt-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text">R$</span>
                              </div>
                              <IntlCurrencyInput
                                className="form-control"
                                currency="BRL"
                                config={currencyConfig}
                                onChange={handleCurrencyInput}
                                value={dataToAdd.value}
                                readOnly={!dataToAdd.safe_box_group_id}
                              />
                            </div>
                          </>
                        )
                      ) : (
                        <small>* Faça a busca por um grupo</small>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={resetData}>
                        Cancelar
                      </Button>
                      <Button variant="primary" onClick={handleAddDonation}>
                        Salvar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <h5>Grupos que doaram</h5>

              <Table striped bordered responsive hover className="text-center">
                <thead>
                  <tr>
                    <th>Grupo</th>
                    <th>Representante</th>
                    <th>Valor</th>
                    <th>Data Doação</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ||
                    (actionLoading && (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    ))}
                  {safeBoxGroups.map((d) => (
                    <tr key={d.id}>
                      <td>{d.group_name}</td>
                      <td>{d.representant_name}</td>
                      <td>{d.valueCurrency ?? "-"}</td>
                      <td>{d.donation_at_formatted}</td>
                      <td>
                        <ButtonGroup aria-label="Basic example">
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => alert("Implementação em andamento!")}
                            disabled={donation.finished}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteDonation(d.donation_id)}
                            disabled={donation.finished}
                          >
                            <FaTrash />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default Donation;
