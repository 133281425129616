import { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import api from "../../services/api";
import { isEmptyObject } from "../../utils/checkObject";

export default function GlobalConfig() {
  const [modules, setModules] = useState([]);

  const [moduleToEdit, setModuleToEdit] = useState({});

  const [newModule, setNewModule] = useState({
    title: "",
    link: "",
    disabled: true,
  });

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get("/api/global-config")
        .then((resp) => {
          const data = resp.data.map((item) => ({
            ...item,
            disabled: !!item.disabled,
          }));

          setModules(data);

          setLoading(false);
        })
        .catch((error) => {
          toast.error(
            "❌ Não foi possível carregar os dados, tente novamente depois!",
            { autoClose: 8000 }
          );
          setLoading(false);
        });
    })();
  }, []);

  const addToEdit = useCallback(
    (id) => {
      const moduleFind = modules.find((mod) => mod.id === id);

      if (moduleFind) setModuleToEdit(moduleFind);
    },
    [modules]
  );

  const handleChangeEdit = useCallback(
    (field, value) => {
      const find = { ...moduleToEdit };

      find[field] = value;

      setModuleToEdit(find);
    },
    [moduleToEdit]
  );

  const cleanEdit = useCallback(() => {
    setModuleToEdit({});
  }, []);

  const modulesUpdate = useCallback(
    (dataId, options) => {
      setModules(
        modules.map((r) => {
          if (r.id === dataId) {
            r = {
              ...r,
              ...options,
            };
          }
          return r;
        })
      );
    },
    [modules]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const payload = {
          title: moduleToEdit.title,
          link: moduleToEdit.link,
          disabled: moduleToEdit.disabled,
        };

        const { data } = await api.put(
          `/api/global-config/${moduleToEdit.id}`,
          payload
        );

        modulesUpdate(moduleToEdit.id, data);

        cleanEdit();

        toast.success("Dados atualizados");
      } catch (error) {
        toast.error("Não foi possivel atualizar os dados");
      }
    },
    [
      cleanEdit,
      moduleToEdit.disabled,
      moduleToEdit.id,
      moduleToEdit.link,
      moduleToEdit.title,
      modulesUpdate,
    ]
  );

  const handleSubmitAdd = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const { data } = await api.post("/api/global-config", newModule);

        setModules([...modules, data]);

        setNewModule({ title: "", link: "", disabled: true });

        handleClose();

        toast.success("Dado adicionado com sucesso!");
      } catch (error) {
        toast.error("Não foi possivel excluir");
      }
    },
    [modules, newModule]
  );

  const handleDelete = useCallback(
    async (moduleId) => {
      try {
        await api.delete(`/api/global-config/${moduleId}`);

        const filteredModules = modules.filter((mod) => mod.id !== moduleId);

        setModules(filteredModules);

        toast.success("Excluído com sucesso!");
      } catch (error) {
        toast.error("Não foi possivel excluir");
      }
    },
    [modules]
  );

  return (
    <Container>
      <h1>Configurações Globais</h1>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Row>
          <Button variant="primary" onClick={handleShow} className="mt-5">
            Adicionar
          </Button>
          <Table striped bordered hover className="text-center mt-2">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Link</th>
                <th>Visível</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {modules.map((p, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{p.title}</td>
                  <td>
                    <Badge variant="primary">{p.link}</Badge>
                  </td>
                  <td>
                    {p.disabled ? (
                      <Badge variant="danger">Não</Badge>
                    ) : (
                      <Badge variant="success">Sim</Badge>
                    )}
                  </td>
                  <td>
                    <ButtonGroup size="sm">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`modules-edit-${index}`}>Editar</Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          onClick={() => addToEdit(p.id)}
                        >
                          <FaEdit />
                        </Button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`modules-delete-${index}`}>
                            Excluir
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(p.id)}
                        >
                          <FaTrash />
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      )}

      {!isEmptyObject(moduleToEdit) && (
        <Form onSubmit={handleSubmit}>
          <Form.Row className="align-items-center">
            <Col>
              <Form.Control
                placeholder="Nome"
                value={moduleToEdit.title}
                onChange={(e) => handleChangeEdit("title", e.target.value)}
              />
            </Col>

            <Col>
              <Form.Control
                placeholder="Link"
                value={moduleToEdit.link}
                onChange={(e) => handleChangeEdit("link", e.target.value)}
              />
            </Col>

            <Col>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Visivel"
                checked={!moduleToEdit.disabled}
                onChange={(e) =>
                  handleChangeEdit("disabled", !moduleToEdit.disabled)
                }
              />
            </Col>

            <Col>
              <Button type="submit">Salvar</Button>
            </Col>
            <Col>
              <Button type="button" variant="warning" onClick={cleanEdit}>
                Limpar
              </Button>
            </Col>
          </Form.Row>
        </Form>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Nova Página Pública</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitAdd}>
            <Form.Group controlId="title">
              <Form.Control
                name="title"
                placeholder="Nome"
                value={newModule.title}
                onChange={(e) =>
                  setNewModule({ ...newModule, title: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="link">
              <Form.Control
                name="link"
                placeholder="Link"
                value={newModule.link}
                onChange={(e) =>
                  setNewModule({ ...newModule, link: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="disabled">
              <Form.Check
                type="switch"
                name="disabled"
                id="custom-switch"
                label="Visivel"
                checked={!newModule.disabled}
                value={newModule.disabled}
                onChange={(e) =>
                  setNewModule({
                    ...newModule,
                    disabled: !newModule.disabled,
                  })
                }
              />
            </Form.Group>

            <Button type="submit">Salvar</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
