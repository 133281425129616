import React from "react";
import { Row } from "react-bootstrap";
import CardHome from "../../components/CardHome";

function Home() {
  return (
    <>
      <Row>
        <CardHome />
      </Row>
    </>
  );
}
export default Home;
