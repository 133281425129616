import React from "react";
import { Container } from "react-bootstrap";

import Logo from "../../assets/Logo_vertical_laranja.png";

function PublicHeader({ children }) {
  return (
    <>
      <Container fluid>
        <div className="text-center mt-5">
          <img src={Logo} alt="POM" height={150} />
        </div>

        <div className="mt-5">{children}</div>
      </Container>
    </>
  );
}

export default PublicHeader;
