import React from "react";
import { Container } from "react-bootstrap";

import SafeBoxTable from "../../components/SafeBoxGroups";

function SafeBox() {
  return (
    <Container>
      <SafeBoxTable url="/api/safe_box_groups" />
    </Container>
  );
}

export default SafeBox;
