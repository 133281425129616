import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";

import api from "../../services/api";
import { Link, useHistory } from "react-router-dom";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";

function SafeBoxGroups({ url }) {
  const history = useHistory();

  const [safeBoxGroups, setSafeBoxGroups] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  useEffect(() => {
    async function loadParishes() {
      setLoading(true);

      await api.get(`${url}?page=${page}`).then((resp) => {
        setSafeBoxGroups(resp.data.data);
        setTotal(resp.data.total);
        setLastPage(resp.data.last_page);
        setLoading(false);

        if (page === 1) {
          setIsFirstPage(true);
        } else {
          setIsFirstPage(false);
        }

        if (page === lastPage) {
          setIsLastPage(true);
        } else {
          setIsLastPage(false);
        }
      });
    }
    loadParishes();
  }, [page, lastPage, url]);

  function nextPage() {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  function handleGo(id) {
    history.push(`/safe-box-view/${id}`);
  }

  return (
    <>
      <div className="text-center mb-4">
        <h1>Grupos IAM</h1>
      </div>

      <Link to="/safe-box-create" className="btn btn-sm btn-primary mb-4">
        <FaPlus className="mr-1" />
        Novo Grupo
      </Link>

      <Table striped bordered responsive hover className="text-center">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome do Grupo</th>
            <th>Representante</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Diocese</th>
            <th>Paróquia</th>
            <th>Visualizar</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="8" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {safeBoxGroups.map((sf) => (
            <tr key={sf.id}>
              <td>{sf.id}</td>
              <td>{sf.group_name}</td>
              <td>{sf.representant_name}</td>
              <td>{sf.contact_mail}</td>
              <td>{sf.telephone}</td>
              <td>{sf.dioceses.name}</td>
              <td>{sf.parishes.name}</td>
              <td>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => handleGo(sf.id)}
                >
                  Visualizar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <Button variant="link" onClick={() => setPage(1)}>
          Primeiro
        </Button>
        <Button variant="primary" onClick={prevPage} disabled={isFirstPage}>
          <RiArrowLeftSLine /> Anterior
        </Button>
        <h5>Total: {total}</h5>
        <Button variant="primary" onClick={nextPage} disabled={isLastPage}>
          Próximo
          <RiArrowRightSLine />
        </Button>
        <Button variant="link" onClick={() => setPage(lastPage)}>
          Último
        </Button>
      </div>
    </>
  );
}

export default SafeBoxGroups;
