import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FaSyncAlt } from "react-icons/fa";
import PublicContent from "./Layout";
import { Card } from "./styles";

import { isEmptyObject } from "../../utils/checkObject";
import { toast } from "react-toastify";
import api from "../../services/api";

function Public() {
  const [modules, setModules] = useState([]);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get("/api/global-config")
        .then((resp) => {
          if (isEmptyObject(resp.data)) {
            setLoading(false);

            setTimeout(() => {
              history.push("/");
            }, 3500);

            toast.info(
              "ℹ Não há nenhuma Campanha aberta no momento, tente novamente depois!",
              { autoClose: 8000 }
            );
          }

          const data = resp.data.map((item) => ({
            ...item,
            disabled: !!item.disabled,
          }));

          setModules(data);

          setLoading(false);
        })
        .catch((error) => {
          toast.error(
            "❌ Não foi possível carregar os dados, tente novamente depois!",
            { autoClose: 8000 }
          );
          setLoading(false);
        });
    })();
  }, [history]);

  return (
    <PublicContent>
      <h2 className="text-center text-muted mb-5">
        Selecione uma opção para continuar
      </h2>

      <h5 className="text-center text-muted mb-5">
        Caso não apareça, clique aqui
        <Button
          size="sm"
          className="ml-2"
          onClick={() => document.location.reload(true)}
        >
          <FaSyncAlt />
        </Button>
      </h5>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : !modules.some((i) => i.disabled === false) ? (
        <div className="text-center text-muted">
          <h1>Não há nenhuma ação disponível</h1>
        </div>
      ) : (
        <Row className="d-flex justify-content-center">
          {modules.map(
            (item, index) =>
              !item.disabled && (
                <Col md={4} sm={12} key={index}>
                  <Link
                    to={item.link}
                    className={item.disabled ? "disabled-link" : ""}
                  >
                    <Card>
                      <h5>{item.title}</h5>
                    </Card>
                  </Link>
                </Col>
              )
          )}
        </Row>
      )}
    </PublicContent>
  );
}

export default Public;
