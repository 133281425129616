import React from 'react';

//libs
import { Form, Input } from '@rocketseat/unform';

//styles
import { Container } from './styles';


export default function Profile() {


    return (

        <Container>

            <h3>Página de Perfil</h3>
            <small>Dados de perfil</small>

            <hr />

            <Form initialData={{}} onSubmit={() => { }}>
                <Input name="name" type="text" placeholder="Nome completo" />
                <Input name="email" type="email" placeholder="Seu endereço de e-mail" />
                <button type="submit">Atualizar dados</button>
            </Form>
        </Container >
    );
}
