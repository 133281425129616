import { useCallback, useEffect, useState } from "react";
import { Container, Form, Col, Button, Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import GoBackButton from "../../../components/GoBackButton";
import api from "../../../services/api";
import { isEmptyObject } from "../../../utils/checkObject";
import PublicContent from "../Layout";

function MissionaryCampaignPublicGet() {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [champaingn, setChampaingn] = useState({});

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setSearchLoading(true);

      await api
        .get(`/api/missionary-campaign-solicitation/${code}?origin=public`)
        .then((resp) => {
          setSearchLoading(false);

          history.push(`/champaing-solicitation-view/${resp.data.code}`);
        })
        .catch((error) => {
          setSearchLoading(false);
          toast.error(
            "❌ Nenhuma solicitação encontrada! Verifique o código e tente novamente!"
          );
        });
    },
    [code, history]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get("/api/missionary-campaign/active")
        .then((resp) => {
          if (isEmptyObject(resp.data)) {
            toast.info(
              "ℹ Não há nenhuma Campanha aberta no momento, tente novamente depois!",
              { autoClose: 8000 }
            );
            setLoading(false);
            setTimeout(() => {
              history.goBack();
            }, 1000);
          }

          setChampaingn(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          toast.error(
            "❌ Não foi possível carregar os dados, tente novamente depois!",
            { autoClose: 8000 }
          );
          setLoading(false);
        });
    })();
  }, [history]);

  return (
    <PublicContent>
      <Container>
        <GoBackButton />

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <h1 className="text-center text-muted">{champaingn.description}</h1>

            <Form onSubmit={handleSubmit}>
              <Form.Row className="align-items-center mt-5">
                <Col className="my-2">
                  <Form.Control
                    placeholder="Digite o código da solicitação"
                    onChange={(e) => setCode(e.target.value.trim())}
                    value={code}
                  />
                </Col>
                <Col xs="auto" className="my-1">
                  <Button type="submit">
                    {searchLoading ? (
                      <>
                        {" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      <FaSearch />
                    )}
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </>
        )}
      </Container>
    </PublicContent>
  );
}

export default MissionaryCampaignPublicGet;
