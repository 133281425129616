import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";

import api from "../../services/api";
import { Link, useHistory } from "react-router-dom";
import formatUf from "../../utils/formatUf";

import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";

function ParishRegionTable({ url }) {
  const history = useHistory();
  const [parishRegions, setParishRegions] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  useEffect(() => {
    async function loadParishRegion() {
      setLoading(true);

      const { data } = await api.get(`${url}?page=${page}`);

      const parishRegionsData = data.data.map((item) => ({
        ...item,
        ufs: formatUf(item.states),
      }));

      setParishRegions(parishRegionsData);
      setLoading(false);

      setTotal(data.total);
      setLastPage(data.last_page);
      setLoading(false);

      if (page === 1) {
        setIsFirstPage(true);
      } else {
        setIsFirstPage(false);
      }

      if (page === lastPage) {
        setIsLastPage(true);
      } else {
        setIsLastPage(false);
      }
    }
    loadParishRegion();
  }, [page, lastPage, url]);

  function nextPage() {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  function handleGo(id) {
    history.push(`/parish-region-view/${id}`);
  }

  return (
    <>
      <div className="text-center mb-4">
        <h1>Regionais CNBB</h1>
      </div>

      <Link to="/parish-region-create" className="btn btn-sm btn-primary mb-4">
        <FaPlus className="mr-1" /> Nova Regional
      </Link>

      <Table striped bordered responsive hover className="text-center">
        <thead>
          <tr>
            <th>Regional</th>
            <th>UF's</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="8" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {parishRegions.map((pr) => (
            <tr key={pr.id}>
              <td>{pr.region_name}</td>
              <td>{pr.ufs}</td>

              <td>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => handleGo(pr.id)}
                >
                  Visualizar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <Button variant="link" onClick={() => setPage(1)}>
          Primeiro
        </Button>
        <Button variant="primary" onClick={prevPage} disabled={isFirstPage}>
          <RiArrowLeftSLine /> Anterior
        </Button>
        <h5>Total: {total}</h5>
        <Button variant="primary" onClick={nextPage} disabled={isLastPage}>
          Próximo <RiArrowRightSLine />
        </Button>
        <Button variant="link" onClick={() => setPage(lastPage)}>
          Último
        </Button>
      </div>
    </>
  );
}

export default ParishRegionTable;
