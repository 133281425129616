import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../services/api";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { LinkContainer } from "react-router-bootstrap";
import InputMask from "react-input-mask";

import {
  Container,
  Spinner,
  Jumbotron,
  Button,
  Row,
  Modal,
  Form,
  Col,
} from "react-bootstrap";
import axios from "axios";
import SafeBoxGroupsParish from "../../components/SafeBoxGroups/parishGroups";
import { removeEspecialCharacters } from "../../utils/removeMask";
import { FiSearch } from "react-icons/fi";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

function ParishView() {
  const history = useHistory();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [parish, setParish] = useState({});

  const [diocese, setDiocese] = useState({});

  //Modal States

  const [show, setShow] = useState(false);
  const handleShowEdit = () => setShow(true);

  const handleCloseEdit = () => {
    setEditParishName(parish.name);
    setEditParishZip(parish.zip_code);
    setEditParishAddress(parish.address);
    setEditParishComplement(parish.complement);
    setEditParishNumber(parish.number);
    setEditParishNeighborhood(parish.neighborhood);
    setEditParishCity(parish.city);
    setEditParishUf(parish.uf);
    setEditParishTel(parish.telephone);
    setEditParishEmail(parish.email);
    setShow(false);
  };

  const [editParishName, setEditParishName] = useState("");
  const [editParishZip, setEditParishZip] = useState("");
  const [editParishAddress, setEditParishAddress] = useState("");
  const [editParishComplement, setEditParishComplement] = useState("");
  const [editParishNumber, setEditParishNumber] = useState("");
  const [editParishNeighborhood, setEditParishNeighborhood] = useState("");
  const [editParishCity, setEditParishCity] = useState("");
  const [editParishUf, setEditParishUf] = useState("");
  const [editParishTel, setEditParishTel] = useState("");
  const [editParishEmail, setEditParishEmail] = useState("");

  const [submitParishButtonLoading, setSubmitParishButtonLoading] = useState(
    false
  );
  const [searchParishAddressLoading, setParishSearchAddressLoading] = useState(
    false
  );

  const [showDiocese, setShowDiocese] = useState(false);
  const handleShowEditDiocese = () => setShowDiocese(true);

  const [dioceseToSearch, setDioceseToSearch] = useState("");
  const [searchedDiocese, setSearchedDiocese] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [newDioceseId, setNewDioceseId] = useState("");

  const handleCloseEditDiocese = () => {
    setShowDiocese(false);
  };

  useEffect(() => {
    loadParish(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function loadParish(parishId) {
    setLoading(true);

    await api
      .get(`/api/parish/${parishId}`)
      .then((resp) => {
        setParish(resp.data);
        setDiocese(resp.data.dioceses);

        //edit data - Mirror
        setEditParishName(resp.data.name);
        setEditParishZip(resp.data.zip_code);
        setEditParishAddress(resp.data.address);
        setEditParishComplement(resp.data.complement);
        setEditParishNumber(resp.data.number);
        setEditParishNeighborhood(resp.data.neighborhood);
        setEditParishCity(resp.data.city);
        setEditParishUf(resp.data.uf);
        setEditParishTel(resp.data.telephone);
        setEditParishEmail(resp.data.email);

        setLoading(false);
      })
      .catch((error) => {
        toast.error("❌ Não foi possível encontrar a Paróquia");

        setLoading(false);

        history.push("/parish");
      });
  }

  async function handleParishSubmit(e) {
    e.preventDefault();
    setSubmitParishButtonLoading(true);

    await api
      .put(`/api/parish/${id}`, {
        name: editParishName,
        zip_code: removeEspecialCharacters(editParishZip),
        address: editParishAddress,
        complement: editParishComplement,
        number: editParishNumber,
        neighborhood: editParishNeighborhood,
        city: editParishCity,
        uf: editParishUf,
        telephone: removeEspecialCharacters(editParishTel),
        email: editParishEmail,
      })
      .then((resp) => {
        toast.success("✅ Dados alterados com sucesso!");
        setSubmitParishButtonLoading(false);
        setShow(false);
        loadParish(id);
      })
      .catch((error) => {
        toast.error("❌ Não foi possível alterar os dados!");
        handleCloseEdit();
        setSubmitParishButtonLoading(false);
      });
  }

  async function searchParishAddress() {
    setParishSearchAddressLoading(true);
    await axios
      .get(`https://viacep.com.br/ws/${editParishZip}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          toast.error("❌ CEP inválido!");
          setEditParishCity("");
          setEditParishUf("");
          setEditParishAddress("");
          setEditParishNeighborhood("");
          return;
        }
        toast.success("✅ CEP válido!");
        setEditParishCity(resp.data.localidade);
        setEditParishUf(resp.data.uf);
        setEditParishAddress(resp.data.logradouro);
        setEditParishNeighborhood(resp.data.bairro);
        setParishSearchAddressLoading(false);
      })
      .catch((error) => {
        toast.error("❌ CEP inválido!");
        setEditParishCity("");
        setEditParishUf("");
        setEditParishAddress("");
        setEditParishNeighborhood("");
      });
  }

  async function searchDiocese() {
    setSearchLoading(true);

    await api
      .get(`/api/diocese/search?query=${dioceseToSearch}`)
      .then((resp) => {
        setSearchedDiocese(resp.data);
        setSearchLoading(false);
        setShowTable(true);
      })
      .catch((error) => {
        setDioceseToSearch("");
        setSearchLoading(false);
        setShowTable(true);
      });
  }

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#bababa",
    columnWidth: "60px",
    onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    setNewDioceseId(row.id);
  }

  async function handleUpdateDioesesSubmit(e) {
    e.preventDefault();

    await api
      .put(`/api/parish/diocese/${id}`, {
        diocese_id: newDioceseId,
      })
      .then((resp) => {
        toast.success("✅ Dados alterados com sucesso!");
        handleCloseEditDiocese();
        loadParish(id);
      })
      .catch((err) => {
        toast.error("❌ Não foi possível alterar os dados!");
      });

    console.log(newDioceseId);
  }

  return (
    <Container>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Row>
            <LinkContainer to="/parish">
              <Button variant="link">Voltar</Button>
            </LinkContainer>
          </Row>

          <Jumbotron>
            <Container>
              <div className="display-4">{parish.name}</div>

              <div className="d-flex flex-column bd-highlight mb-3">
                <div className="bd-highlight">
                  <span>
                    Localidade:{" "}
                    <strong>
                      {parish.address}, {parish.neighborhood} Nº{parish.number},{" "}
                      {parish.city} - {parish.uf}
                    </strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    E-mail: <strong>{parish.email}</strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    Telefone: <strong>{parish.telephone}</strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    Diocese: <strong>{diocese.name}</strong>
                    <Button
                      size="sm"
                      className="ml-2"
                      onClick={handleShowEditDiocese}
                    >
                      Editar
                    </Button>
                  </span>
                </div>
              </div>
            </Container>
          </Jumbotron>

          <>
            <Button variant="secondary" onClick={handleShowEdit}>
              Editar Dados
            </Button>

            <Modal
              show={show}
              onHide={handleCloseEdit}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Editar Paróquia</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form
                  onSubmit={handleParishSubmit}
                  method="POST"
                  className="mt-4"
                >
                  <h5>Dados da Paróquia</h5>
                  <hr />
                  <div className="form-row">
                    <div className="form-group col-md-7">
                      <label htmlFor="newParish">Nome da Paróquia</label>
                      <input
                        type="text"
                        className="form-control"
                        name="newParish"
                        id="newParish"
                        value={editParishName}
                        onChange={(e) => setEditParishName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group col-md-5">
                      <label htmlFor="telephone">Telefone</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        placeholder="Ex: (99) 99999-9999"
                        type="tel"
                        className="form-control"
                        name="telephone"
                        id="telephone"
                        value={editParishTel}
                        onChange={(e) => setEditParishTel(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="contact_email">E-mail para Contato</label>
                      <input
                        type="email"
                        className="form-control"
                        name="contact_email"
                        id="contact_email"
                        value={editParishEmail}
                        onChange={(e) => setEditParishEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <h5>Dados de Endereço</h5>
                  <hr />
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="zipCode">
                        CEP{" "}
                        {searchParishAddressLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          ""
                        )}
                      </label>
                      <InputMask
                        mask="99999-999"
                        type="text"
                        className="form-control"
                        name="zipCode"
                        id="zipCode"
                        value={editParishZip}
                        onChange={(e) => setEditParishZip(e.target.value)}
                        onBlur={searchParishAddress}
                        required
                      />
                      <small className="text-primary">
                        *Ao digitar um CEP válido e retirar o foco do campo, os
                        campos em cinza serão preenchidos automaticamente.
                      </small>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="city">Cidade</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        id="city"
                        value={editParishCity}
                        onChange={(e) => setEditParishCity(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">UF</label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        id="state"
                        value={editParishUf}
                        maxlength="2"
                        onChange={(e) => setEditParishUf(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label htmlFor="address">Endereço</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        id="address"
                        value={editParishAddress}
                        onChange={(e) => setEditParishAddress(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="neighborhood">Bairro</label>
                      <input
                        type="text"
                        className="form-control"
                        name="neighborhood"
                        id="neighborhood"
                        value={editParishNeighborhood}
                        onChange={(e) =>
                          setEditParishNeighborhood(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-5">
                      <label htmlFor="complement">Complemento</label>
                      <input
                        type="text"
                        className="form-control"
                        name="complement"
                        id="complement"
                        value={editParishComplement}
                        onChange={(e) =>
                          setEditParishComplement(e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="number">Número</label>
                      <input
                        type="number"
                        className="form-control"
                        name="number"
                        id="number"
                        value={editParishNumber}
                        onChange={(e) => setEditParishNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleCloseEdit}>
                      Cancelar
                    </Button>
                    <Button variant="primary" type="submit">
                      {submitParishButtonLoading ? "Salvando..." : "Salvar"}
                    </Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal
              show={showDiocese}
              onHide={handleCloseEditDiocese}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Editar Diocese</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  onSubmit={handleUpdateDioesesSubmit}
                  method="POST"
                  className="mt-4"
                >
                  <h3>Diocese que a Paróquia pertence</h3>

                  <Form.Row className="align-items-center">
                    <Col xs="5" className="my-2">
                      <Form.Control
                        className="mb-2 mr-sm-2"
                        id="parishName"
                        placeholder="Busque a Diocese pelo nome"
                        onChange={(e) => setDioceseToSearch(e.target.value)}
                        value={dioceseToSearch}
                      />
                    </Col>
                    <Col xs="auto" className="my-1">
                      <Button className="mb-2" onClick={searchDiocese}>
                        {searchLoading ? (
                          <>
                            {" "}
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          <FiSearch />
                        )}
                      </Button>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    {showTable ? (
                      searchLoading ? (
                        <div className="text-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                        <>
                          <BootstrapTable
                            data={searchedDiocese}
                            striped
                            hover
                            selectRow={selectRowProp}
                          >
                            <TableHeaderColumn dataField="id" isKey hidden>
                              Diocese ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="name">
                              Nome da Diocese
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="city">
                              Cidade
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="uf" width="60">
                              UF
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="contact_mail">
                              E-mail de Contato
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </>
                      )
                    ) : (
                      <small>* Faça a busca por uma Diocese</small>
                    )}
                  </Form.Row>
                  <hr />

                  <div className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleCloseEdit}>
                      Cancelar
                    </Button>
                    <Button variant="primary" type="submit">
                      {submitParishButtonLoading ? "Salvando..." : "Salvar"}
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </>

          <SafeBoxGroupsParish url={`/api/parish/${id}/safe_box`} />
        </>
      )}
    </Container>
  );
}

export default ParishView;
