import React from "react";
import { Container } from "react-bootstrap";
import MissionaryCampaignTable from "../../components/MissionaryCampaignTable";

export default function index() {
  return (
    <Container>
      <MissionaryCampaignTable url="/api/missionary-campaign" />
    </Container>
  );
}
