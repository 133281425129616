import styled from "styled-components";
import { lighten } from "polished";

export const Card = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 20px;

  border-radius: 4px;
  border: 4px solid #fab561;
  background: ${lighten(0.37, "#f6931c")};
  color: #f6931c;
  transition: background 0.2s, color 0.2s;
  margin: 10px;

  &:hover {
    background: ${lighten(0.3, "#f6931c")};
    color: #f58b00;
  }

  h5 {
    font-size: 32px;
    text-align: center;
  }

  small {
    font-size: 14px;
    text-align: center;
    margin-top: 25px;
  }
`;
