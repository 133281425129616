import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Spinner,
  Button,
  Jumbotron,
  Container,
  Modal,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import api from "../../services/api";
import { removeEspecialCharacters } from "../../utils/removeMask";
import DonationGroupstable from "../../components/DonationTable/groupsDonation";

function SafeBoxView() {
  const { id } = useParams();
  const [group, setGroup] = useState({});
  const [diocese, setDiocese] = useState({});
  const [parish, setParish] = useState({});
  const [loading, setLoading] = useState(false);
  const [groupId] = useState(id);

  //Modal States

  const [show, setShow] = useState(false);
  const handleShowEdit = () => setShow(true);

  const [groupEditName, setGroupEditName] = useState("");
  const [groupEditRepresentantName, setGroupEditRepresentantName] = useState(
    ""
  );
  const [groupEditContactMail, setEditGroupContactMail] = useState("");
  const [groupEditTelephone, setEditGroupTelephone] = useState("");
  const [groupEditCity, setEditGroupCity] = useState("");
  const [groupEditUf, setEditGroupUf] = useState("");
  const [editGroupZip, setEditGroupZip] = useState("");

  useEffect(() => {
    loadGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadGroup = useCallback(async () => {
    setLoading(true);
    await api.get(`/api/safe_box_groups/${id}`).then((resp) => {
      setGroup(resp.data);
      setDiocese(resp.data.dioceses);
      setParish(resp.data.parishes);

      //mirror edit
      setGroupEditName(resp.data.group_name);
      setGroupEditRepresentantName(resp.data.representant_name);
      setEditGroupTelephone(resp.data.telephone);
      setEditGroupContactMail(resp.data.contact_mail);
      setEditGroupCity(resp.data.city);
      setEditGroupUf(resp.data.uf);

      setLoading(false);
    });
  }, [id]);

  const handleCloseEdit = () => {
    setGroupEditName(group.group_name);
    setGroupEditRepresentantName(group.representant_name);
    setEditGroupTelephone(group.telephone);
    setEditGroupContactMail(group.contact_mail);
    setEditGroupCity(group.city);
    setEditGroupUf(group.uf);
    setEditGroupZip("");
    setShow(false);
  };

  const [submitParishButtonLoading, setSubmitParishButtonLoading] = useState(
    false
  );
  const [searchParishAddressLoading, setParishSearchAddressLoading] = useState(
    false
  );

  async function searchGroupAddress() {
    setParishSearchAddressLoading(true);
    await axios
      .get(`https://viacep.com.br/ws/${editGroupZip}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          toast.error("❌ CEP inválido!");
          setEditGroupCity(group.city);
          setEditGroupUf(group.uf);
          setParishSearchAddressLoading(false);
          return;
        }
        toast.success("CEP válido!");
        setEditGroupCity(resp.data.localidade);
        setEditGroupUf(resp.data.uf);
        setParishSearchAddressLoading(false);
      })
      .catch((error) => {
        toast.error("❌ CEP inválido!");
        setEditGroupCity(group.city);
        setEditGroupUf(group.uf);
        setParishSearchAddressLoading(false);
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitParishButtonLoading(true);

    await api
      .put(`/api/safe_box_groups/${id}`, {
        group_name: groupEditName,
        representant_name: groupEditRepresentantName,
        contact_mail: groupEditContactMail,
        telephone: removeEspecialCharacters(groupEditTelephone),
        city: groupEditCity,
        uf: groupEditUf,
      })
      .then((resp) => {
        toast.success("✅ Dados salvos com sucesso!");
        loadGroup(id);
        handleCloseEdit();
        setSubmitParishButtonLoading(false);
      })
      .catch((error) => {
        toast.error("❌ Não foi possível salvar os dados, tente novamente!");

        setSubmitParishButtonLoading(false);
      });
  }

  return (
    <Container>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Row>
            <LinkContainer to="/safe-box">
              <Button variant="link">Voltar</Button>
            </LinkContainer>
          </Row>

          <Jumbotron>
            <Container>
              <div className="display-4">{group.group_name}</div>

              <div className="d-flex flex-column bd-highlight mb-3">
                <div className="bd-highlight">
                  <span>
                    Nome do Representante:{" "}
                    <strong>{group.representant_name}</strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    E-mail do Representante:{" "}
                    <strong>{group.contact_mail}</strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    Telefone: <strong>{group.telephone}</strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    Diocese: <strong>{diocese.name}</strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    Paróquia: <strong>{parish.name}</strong>
                  </span>
                </div>
                <div className="bd-highlight">
                  <span>
                    Cidade-UF:{" "}
                    <strong>
                      {group.city} - {group.uf}
                    </strong>
                  </span>
                </div>
              </div>
            </Container>
          </Jumbotron>

          <>
            <Button variant="secondary" onClick={handleShowEdit}>
              Editar Dados
            </Button>

            <Modal
              show={show}
              onHide={handleCloseEdit}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Editar Grupo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmit} method="POST">
                  <h5>Dados do Grupo</h5>
                  <hr />
                  <div className="form-row">
                    <div className="form-group col-md-7">
                      <label htmlFor="groupName">Nome do Grupo</label>
                      <input
                        type="text"
                        className="form-control"
                        name="groupName"
                        id="groupName"
                        value={groupEditName}
                        onChange={(e) => setGroupEditName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group col-md-5">
                      <label htmlFor="telephone">Telefone</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        placeholder="Ex: (99) 99999-9999"
                        type="tel"
                        className="form-control"
                        name="telephone"
                        id="telephone"
                        value={groupEditTelephone}
                        onChange={(e) => setEditGroupTelephone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="representantName">
                        Nome do Representante
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="representantName"
                        id="representantName"
                        value={groupEditRepresentantName}
                        onChange={(e) =>
                          setGroupEditRepresentantName(e.target.value)
                        }
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="contact_email">E-mail para Contato</label>
                      <input
                        type="email"
                        className="form-control"
                        name="contact_email"
                        id="contact_email"
                        value={groupEditContactMail}
                        onChange={(e) =>
                          setEditGroupContactMail(e.target.value)
                        }
                        required
                      />
                    </div>
                  </div>

                  <h5>Dados de Endereço</h5>
                  <hr />
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="zipCode">
                        CEP{" "}
                        {searchParishAddressLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          ""
                        )}
                      </label>
                      <InputMask
                        mask="99999-999"
                        type="text"
                        className="form-control"
                        name="zipCode"
                        id="zipCode"
                        value={editGroupZip}
                        onChange={(e) => setEditGroupZip(e.target.value)}
                        onBlur={searchGroupAddress}
                      />
                      <small className="text-primary">
                        *Ao digitar um CEP válido e retirar o foco do campo, os
                        campos em cinza serão preenchidos automaticamente.
                      </small>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="city">Cidade</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        id="city"
                        value={groupEditCity}
                        onChange={(e) => setEditGroupCity(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">UF</label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        id="state"
                        value={groupEditUf}
                        maxLength="2"
                        onChange={(e) => setEditGroupUf(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <hr />

                  <div className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleCloseEdit}>
                      Cancelar
                    </Button>
                    <Button variant="primary" type="submit">
                      {submitParishButtonLoading ? "Salvando..." : "Salvar"}
                    </Button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </>

          <DonationGroupstable
            url="/api/safe_box_groups/donations/"
            id={groupId}
          />
        </>
      )}
    </Container>
  );
}

export default SafeBoxView;
