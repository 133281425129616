import React from 'react';
import logo from '../../assets/img/logo_pom.png';

export default function Logo() {
    return (
        <>
            <img src={logo} height="80" alt="POM" />
        </>
    );
}
