import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useState } from "react";

import api from "../../services/api";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

function DonationCreate() {
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    await api
      .post("/api/donation", {
        title,
        description,
      })
      .then((resp) => {
        toast.success("Doação criada com sucesso!");
        history.push(`/donation-view/${resp.data.id}`);
      })
      .catch((error) => {
        toast.error("Não foi possível criar a doação, reveja os dados!");
      });
  }

  return (
    <Container>
      <h1>Nova Doação</h1>

      <Link to="/donation">Voltar</Link>

      <Form className="mt-4" onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Título da Doação</Form.Label>
          <Form.Control
            type="text"
            placeholder="Doação para a comunidade..."
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Descrição da Doação</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Doação para a comunidade..."
            alue={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Button type="submit">{loading ? "Salvando..." : "Salvar"}</Button>
      </Form>
    </Container>
  );
}
export default DonationCreate;
