import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

import api from "../../services/api";
import formatCurrency from "../../utils/formatCurrency";
import { formatDate } from "../../utils/formatDate";

function MissionaryCollectTable({ url }) {
  const history = useHistory();
  const [missionary, setMissionary] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  useEffect(() => {
    async function loadParishRegion() {
      setLoading(true);

      const { data } = await api.get(`${url}?page=${page}`);

      setMissionary(data.data);
      setLoading(false);

      setTotal(data.total);
      setLastPage(data.last_page);
      setLoading(false);

      if (page === 1) {
        setIsFirstPage(true);
      } else {
        setIsFirstPage(false);
      }

      if (page === lastPage) {
        setIsLastPage(true);
      } else {
        setIsLastPage(false);
      }
    }
    loadParishRegion();
  }, [page, lastPage, url]);

  function nextPage() {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  function handleGo(id) {
    history.push(`/missionary-collection-view/${id}`);
  }

  return (
    <>
      <div className="text-center mb-4">
        <h1>Coletas Missionárias</h1>
      </div>

      <Link
        to="/missionary-collection-create"
        className="btn btn-sm btn-primary mb-4"
      >
        <FaPlus className="mr-1" /> Nova Coleta
      </Link>

      <Table striped bordered responsive hover className="text-center">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Data Início</th>
            <th>Data Fim</th>
            <th>Valor Total</th>
            <th>Ano Base</th>
            <th>Finalizada</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="8" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {missionary.map((m) => (
            <tr key={m.id}>
              <td>{m.description}</td>
              <td>{formatDate(m.begin_dt)}</td>
              <td>{m.end_dt === null ? "----" : formatDate(m.end_dt)}</td>
              <td>
                {m.total_value === null ? (
                  "----"
                ) : (
                  <strong className="bg-success text-light p-1">
                    {formatCurrency(m.total_value)}
                  </strong>
                )}
              </td>
              <td>
                <span className="bg-secondary text-white p-1">
                  {m.base_year}
                </span>
              </td>
              <td>
                {m.finished ? (
                  <span className="bg-success text-white p-1">Sim</span>
                ) : (
                  <span className="bg-danger text-white p-1">Não</span>
                )}
              </td>

              <td>
                <Button variant="link" size="sm" onClick={() => handleGo(m.id)}>
                  Visualizar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <Button variant="link" onClick={() => setPage(1)}>
          Primeiro
        </Button>
        <Button variant="primary" onClick={prevPage} disabled={isFirstPage}>
          <RiArrowLeftSLine /> Anterior
        </Button>
        <h5>Total: {total}</h5>
        <Button variant="primary" onClick={nextPage} disabled={isLastPage}>
          Próximo <RiArrowRightSLine />
        </Button>
        <Button variant="link" onClick={() => setPage(lastPage)}>
          Último
        </Button>
      </div>
    </>
  );
}

export default MissionaryCollectTable;
