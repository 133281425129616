import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export default function GoBackButton() {
  const history = useHistory();

  return (
    <button className="btn btn-link" onClick={() => history.goBack()}>
      <FaArrowLeft className="me-2" />
      Voltar
    </button>
  );
}
