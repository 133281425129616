import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

//public pages
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";

import Public from "../pages/public";
import MissionaryCollectionPublic from "../pages/public/MissionaryCollection";
import MissionaryCampaignPublic from "../pages/public/MissionaryCampaign";
import MissionaryCampaignPublicView from "../pages/public/MissionaryCampaign/view";
import MissionaryCampaignPublicNew from "../pages/public/MissionaryCampaign/new";
import MissionaryCampaignPublicGet from "../pages/public/MissionaryCampaign/get";
import MissionaryCampaignPublicConfirm from "../pages/public/MissionaryCampaign/confirm";
import MissionaryCollectionPublicView from "../pages/public/MissionaryCollection/view";

export default function PublicRoutes() {
  return (
    <Switch>
      {/* Public Routes */}
      <Route exact path="/login" component={SignIn} />
      <Route exact path="/register" component={SignUp} />

      <Route exact path="/" component={Public} />

      <Route
        exact
        path="/missionary-collection"
        component={MissionaryCollectionPublic}
      />

      <Route
        exact
        path="/missionary-collection/:id"
        component={MissionaryCollectionPublicView}
      />

      <Route
        exact
        path="/missionary-champaing"
        component={MissionaryCampaignPublic}
      />

      <Route
        exact
        path="/missionary-champaing-new"
        component={MissionaryCampaignPublicNew}
      />

      <Route
        exact
        path="/champaing-solicitation-get"
        component={MissionaryCampaignPublicGet}
      />

      <Route
        exact
        path="/champaing-solicitation-view/:cod"
        component={MissionaryCampaignPublicView}
      />

      <Route
        exact
        path="/champaing-solicitation-confirm/:cod"
        component={MissionaryCampaignPublicConfirm}
      />

      <Redirect to="/login" />
    </Switch>
  );
}
