import React from "react";
import { Spinner } from "react-bootstrap";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import formatCurrency from "../../utils/formatCurrency";

function CardMoney({ classCss, loading, title, value }) {
  return (
    <div
      className={`card mb-3 bg-${classCss} text-light`}
      style={{ maxWidth: 540 }}
    >
      <div className="row no-gutters">
        <div className="col-md-4">
          <RiMoneyDollarCircleLine
            className="card-img"
            color="#fff"
            size={130}
          />
        </div>
        <div className="d-flex col-md-8">
          <div
            className="card-body"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              <>
                <h5 className="card-title m-0">
                  {title}: <strong>{formatCurrency(value)}</strong>
                </h5>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardMoney;
