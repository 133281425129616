export default function formatCurrency(value) {
  let valueParsed = parseFloat(value);
  return valueParsed.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

export function formatValue(value) {
  let valueParsed = parseInt(value);
  return valueParsed.toLocaleString("pt-br");
}
