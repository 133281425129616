import React, { useEffect, useState } from "react";
import { Button, Col, Spinner, Form, Container, Figure } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { FiSearch, FiSend, FiTrash2 } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import IntlCurrencyInput from "react-intl-currency-input";

import "./styles.css";

import PublicHeader from "../../../components/PublicHeader";
import api from "../../../services/api";
import bytesToMega from "../../../utils/formatBytesToMegaBytes";
import Success from "../../../components/Success";
import { useParams } from "react-router-dom";
import GoBackButton from "../../../components/GoBackButton";

function MissionaryCollectionPublicView() {
  //Search Diocese - States and Functions
  const { register, handleSubmit } = useForm();

  const { id } = useParams();

  const [dioceseToSearch, setDioceseToSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [searchedDiocese, setSearchedDiocese] = useState({});
  const [image, setImage] = useState({
    preview: "",
    raw: "",
    filename: "",
    size: "",
  });
  const [currency, setCurrency] = useState(0.0);

  const [dioceseId, setDioceseId] = useState("");

  const [bankAccounts, setBankAccounts] = useState([]);
  const [creditTypes, setCreditTypes] = useState([]);
  const [success, setSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    async function loadSelects() {
      Promise.all([api.get("/api/bank-account"), api.get("/api/credit-type")])
        .then((results) => {
          setBankAccounts(results[0].data);
          setCreditTypes(results[1].data);
        })
        .catch((error) => {
          return toast.error(
            "Não foi possível carregar os dados, tente novamente mais tarde"
          );
        });
    }

    loadSelects();
  }, []);

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        filename: e.target.files[0].name,
        size: e.target.files[0].size,
      });
    }
  };

  async function searchDiocese() {
    if (!dioceseToSearch) {
      return toast.error("Digite o nome de uma Diocese");
    }
    if (dioceseToSearch.length < 3) {
      return toast.error("Digite pelo menos 3 caracteres");
    }
    setSearchLoading(true);

    await api
      .get(`/api/diocese/search?query=${dioceseToSearch}`)
      .then((resp) => {
        setSearchedDiocese(resp.data);
        setSearchLoading(false);
        setShowTable(true);
      })
      .catch((error) => {
        setDioceseToSearch("");
        setSearchLoading(false);
        setShowTable(true);
      });
  }

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#bababa",
    columnWidth: "60px",
    onSelect: onRowSelect,
  };

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  function onRowSelect(row, isSelected, e) {
    setDioceseId(row.id);
  }

  const handleCurrencyInput = (event, value, maskedValue) => {
    event.preventDefault();
    setCurrency(value);
  };

  const onSubmit = async (data) => {
    if (!(data.receipt.length > 0)) {
      toast.error("Imagem do comprovante é obrigatória");
      return;
    }

    if (parseFloat(currency) <= 0) {
      toast.error("Valor precisa ser maior que zero");
      return;
    }

    const formData = new FormData();
    formData.append("missionary_collection_id", id);
    formData.append("diocese_id", Number(dioceseId));
    formData.append("bank_account", Number(data.bank_account));
    formData.append("credit_type", Number(data.credit_type));
    formData.append("value_currency", parseFloat(currency));
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("receipt_image", data.receipt[0]);

    setSubmitLoading(true);

    await api
      .post("/api/collection-missionary-contribuitions", formData)
      .then((resp) => {
        setSuccess(true);
        setSubmitLoading(false);
        return toast.success("✔️ Dados enviados com sucesso!");
      })
      .catch((error) => {
        setSubmitLoading(false);

        if (error.response.status === 409) {
          return toast.error(
            "⛔ Já existe um envio para essa Diocese neste ano!"
          );
        }
        return toast.error(
          "⚠️ Não foi possível enviar os dados, verifique e tente novamente!"
        );
      });
  };

  const resetData = () => {
    setSuccess(false);
    setDioceseToSearch("");
    setSearchLoading(false);
    setShowTable(false);
    setSearchedDiocese({});
    setImage({
      preview: "",
      raw: "",
      filename: "",
      size: "",
    });
    setCurrency(0.0);
    setDioceseId("");
  };

  return (
    <>
      <PublicHeader>
        <Container>
          {success ? (
            <>
              <div className="text-center">
                <Button variant="link" onClick={resetData}>
                  Voltar
                </Button>
              </div>

              <Success />
            </>
          ) : (
            <>
              {showTable && (
                <div className="text-center">
                  <Button variant="warning" onClick={resetData}>
                    <FaTimes className="mr-2" />
                    Limpar
                  </Button>
                </div>
              )}

              <GoBackButton />

              <Form.Row className="align-items-center justify-content-center">
                <Col xs="8" className="my-2">
                  <Form.Control
                    size="lg"
                    className="mb-2 mr-sm-2"
                    id="parishName"
                    placeholder="Busque a Diocese pelo nome"
                    onChange={(e) => setDioceseToSearch(e.target.value)}
                    value={dioceseToSearch}
                  />
                </Col>
                <Col xs="auto" className="my-1">
                  <Button className="mb-2" size="lg" onClick={searchDiocese}>
                    {searchLoading ? (
                      <>
                        {" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      <FiSearch />
                    )}
                  </Button>
                </Col>
              </Form.Row>

              <Form.Row className="align-items-center justify-content-center">
                {showTable ? (
                  searchLoading ? (
                    <div className="text-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <>
                      <BootstrapTable
                        data={searchedDiocese}
                        striped
                        hover
                        selectRow={selectRowProp}
                      >
                        <TableHeaderColumn dataField="id" isKey hidden>
                          Diocese ID
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="name">
                          Nome da Diocese
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="city">
                          Cidade
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="uf" width="60">
                          UF
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </>
                  )
                ) : (
                  <small>* Faça a busca por uma Diocese</small>
                )}
              </Form.Row>

              {showTable && (
                <div className="mt-5">
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                    method="POST"
                  >
                    <Form.Row>
                      <Col>
                        <Form.Group controlId="bankAccount">
                          <Form.Label>Conta para Crédito</Form.Label>
                          <Form.Control
                            as="select"
                            name="bank_account"
                            ref={register}
                            custom
                            required
                          >
                            {bankAccounts &&
                              bankAccounts.map((account) => (
                                <option value={account.id} key={account.id}>
                                  {account.bank_name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="creditType">
                          <Form.Label>Via de Crédito</Form.Label>
                          <Form.Control
                            as="select"
                            name="credit_type"
                            ref={register}
                            custom
                            required
                          >
                            {creditTypes &&
                              creditTypes.map((types) => (
                                <option value={types.id} key={types.id}>
                                  {types.description}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="currency">
                          <Form.Label>Valor</Form.Label>
                          <IntlCurrencyInput
                            className="form-control"
                            currency="BRL"
                            config={currencyConfig}
                            onChange={handleCurrencyInput}
                            value={currency}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Group controlId="name">
                          <Form.Label>Nome Completo</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Digite o seu nome"
                            ref={register}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="email">
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            name="email"
                            placeholder="Digite o seu e-mail"
                            ref={register}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-5">
                      <Container fluid>
                        <div className="text-center">
                          <label htmlFor="upload-button">
                            {image.preview ? (
                              <>
                                <Figure>
                                  <Figure.Image
                                    width={150}
                                    height={180}
                                    alt="dummy"
                                    src={image.preview}
                                    rounded
                                  />
                                  <Figure.Caption>
                                    {" "}
                                    <strong>Nome:</strong> {image.filename}
                                  </Figure.Caption>
                                  <Figure.Caption>
                                    <strong>Tamanho:</strong>{" "}
                                    {bytesToMega(image.size)}
                                  </Figure.Caption>
                                </Figure>
                              </>
                            ) : (
                              <>
                                <h5 className="text-center button-upload">
                                  Clique para inserir a imagem do seu
                                  comprovante
                                </h5>
                              </>
                            )}
                          </label>
                          {image.preview && (
                            <div className="text-center">
                              <button
                                className="clean-preview"
                                onClick={() =>
                                  setImage({
                                    preview: "",
                                    raw: "",
                                    filename: "",
                                    size: "",
                                  })
                                }
                              >
                                <FiTrash2 size={20} color="#f6261c" />
                              </button>
                            </div>
                          )}

                          <input
                            type="file"
                            id="upload-button"
                            style={{ display: "none" }}
                            onChange={handleChange}
                            ref={register}
                            name="receipt"
                            accept="image/png,image/jpg,image/jpeg"
                          />
                        </div>
                      </Container>
                    </Form.Row>

                    <Button type="submit" size="lg" className="mt-4 mb-4" block>
                      <FiSend size="20" className="mr-1" />
                      {submitLoading ? "Enviando ..." : "Enviar"}
                    </Button>
                  </Form>
                </div>
              )}
            </>
          )}
        </Container>
      </PublicHeader>
    </>
  );
}

export default MissionaryCollectionPublicView;
