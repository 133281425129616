import React from "react";
import { Container } from "react-bootstrap";
import MissionaryCollectTable from "../../components/MissionaryCollect";

export default function index() {
  return (
    <Container>
      <MissionaryCollectTable url={`/api/missionary-collection`} />
    </Container>
  );
}
