import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useEffect } from "react";

import api from "../../services/api";
import { Container, Form, Spinner, Button } from "react-bootstrap";
import { toast } from "react-toastify";

export default function UsersView() {
  const { id } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(Boolean);
  const [isApproved, setIsApproved] = useState(Boolean);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    loadUser(id);
  }, [id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function loadUser(userID) {
    setLoading(true);

    await api
      .get(`/api/user/${userID}`)
      .then((resp) => {
        setName(resp.data.name);
        setEmail(resp.data.email);
        setIsAdmin(resp.data.profile === 'admin' ? true : false);
        setIsApproved(resp.data.is_approved);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function handleUpdate() {
    setSubmitLoading(true);
    await api
      .put(`/api/user/${id}`, {
        name: name,
        email: email,
        profile: isAdmin === true ? 'admin' : 'user',
        is_approved: isApproved,
      })
      .then((resp) => {
        setSubmitLoading(false);
        toast.success("✅ Dados atualizados com sucesso!");
        loadUser(id);
      })
      .catch((error) => {
        setSubmitLoading(false);
        toast.error("❌ Não foi possível atualizar os dados!");
      });
  }

  return (
    <Container>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
          <>
            <h1>Usuário: {name}</h1>

            <Link to="/users">Voltar</Link>

            <Form className="w-50 mt-4">
              <Form.Group controlId="name">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="name">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="switch"
                  id="profile"
                  checked={isAdmin}
                  value={isAdmin}
                  onChange={() => setIsAdmin(!isAdmin)}
                  label="Admin"
                />

                <Form.Check
                  type="switch"
                  id="isApproved"
                  checked={isApproved}
                  value={isApproved}
                  onChange={() => setIsApproved(!isApproved)}
                  label="Aprovado"
                />
              </Form.Group>

              <Button variant="primary" type="button" onClick={handleUpdate}>
                {submitLoading ? "Salvando ..." : "Salvar"}
              </Button>
            </Form>
          </>
        )}
    </Container>
  );
}
