import React, { useState, useEffect } from "react";

import { useParams, Link, useHistory } from "react-router-dom";

//libs
import { LinkContainer } from "react-router-bootstrap";
import {
  Container,
  Button,
  Spinner,
  Row,
  Col,
  Jumbotron,
  Card,
  Modal,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { FaPlus, FaTimesCircle } from "react-icons/fa";
import InputMask from "react-input-mask";

//services
import api from "../../services/api";
import axios from "axios";
import { toast } from "react-toastify";

import { removeEspecialCharacters } from "../../utils/removeMask";

export default function ParishRegionView() {
  const history = useHistory();

  //Parish Region ID
  const { id } = useParams();

  //Parish Region Data
  const [parishRegion, setParishRegion] = useState({});

  //Parish Region Loading
  const [parishRegionLoading, setParishRegionLoading] = useState(false);

  //Parish Region Delete Button
  const [disabled, setDisabled] = useState(true);
  //Parish Region Delete Button Loading
  const [deleteLoading, setDeleteLoading] = useState(false);

  //  -------------------------------------

  //Parish Region Comprehensive States
  const [parishRegionStates, setParishRegionStates] = useState([{}]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  //states
  const [statesToSelect, setStatesToSelect] = useState([]);
  const [newState, setNewState] = useState("");
  //states table
  const [tableRemoveLoading, setTableRemoveLoading] = useState(false);

  //select states
  // eslint-disable-next-line
  const [statesAll, setStatesAll] = useState([]);

  //Parish Region Dioceses
  const [parishRegionDioceses, setParishRegionDioceses] = useState([{}]);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => {
    setShow2(false);
    setNewDioceseName("");
    setNewCnpj("");
    setNewBishop("");
    setNewTelephone("");
    setNewContactMail("");
    setNewZipCode("");
    setNewCity("");
    setNewDioceseState("");
    setNewAddress("");
    setNewNeighborhood("");
    setNewComplement("");
    setNewNumber("");
  };

  const handleShow2 = () => setShow2(true);
  const [submitButtonLoading2, setSubmitButtonLoading2] = useState(false);

  //New Diocese
  const [newDioceseName, setNewDioceseName] = useState("");
  const [newBishop, setNewBishop] = useState("");
  const [newCnpj, setNewCnpj] = useState("");
  const [newZipCode, setNewZipCode] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newComplement, setNewComplement] = useState("");
  const [newNumber, setNewNumber] = useState(0);
  const [newNeighborhood, setNewNeighborhood] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newDioceseState, setNewDioceseState] = useState("");
  const [newTelephone, setNewTelephone] = useState("");
  const [newContactMail, setNewContactMail] = useState("");

  const [searchAddressLoading, setSearchAddressLoading] = useState(false);

  const [tableRemoveDioceseLoading, setTableRemoveDioceseLoading] = useState(
    false
  );

  useEffect(() => {
    loadParishRegion(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    loadStatesToSelect();
    //eslint-disable-next-line
  }, [parishRegionStates]);

  useEffect(() => {
    parishRegionDioceses.length > 0 ? setDisabled(true) : setDisabled(false);
  }, [parishRegionDioceses]);

  //Parish Region Functions

  //Load Component General Data
  async function loadParishRegion(id) {
    setParishRegionLoading(true);

    try {
      const { data } = await api.get(`/api/parish_region/${id}`);
      setParishRegion(data);
      setParishRegionStates(data.states);
      setParishRegionDioceses(data.diocese);
    } catch (e) {
      history.push("/parish-region");
    }

    setParishRegionLoading(false);
  }

  //Delete Parish Region
  async function handleDeleteParishRegion(parishRegionId) {
    setDeleteLoading(true);

    await api.delete(`/api/parish_region/${parishRegionId}`);

    setDeleteLoading(false);

    history.push("/api/parish-region");
  }

  //End Parish Region Functions

  // ----------------------------------------

  //Parish Region States
  async function loadStatesToSelect() {
    const { data } = await api.get("/api/states");

    setStatesAll(data);

    const auxData = data.filter(
      (item) => !parishRegionStates.map((s) => s.id).includes(item.id)
    );

    setStatesToSelect(auxData);
  }

  async function handleAddNewState() {
    setSubmitButtonLoading(true);

    await api.post("/api/parish_region_states", {
      parish_region_id: id,
      states: [newState],
    });
    setSubmitButtonLoading(false);
    handleClose();
    setNewState("");
    loadParishRegion(id);
  }

  async function handleRemoveState(stateId) {
    setTableRemoveLoading(true);

    await api.put("/api/parish_region_states", {
      parish_region_id: id,
      states: [stateId],
    });
    setTableRemoveLoading(false);
    loadParishRegion(id);
  }
  //End Parish Region States

  // ----------------------------------------

  //Parish Region Dioceses
  async function handleAddNewDiocese(e) {
    e.preventDefault();

    setSubmitButtonLoading2(true);

    await api
      .post("/api/diocese?lang=pt-br", {
        name: newDioceseName,
        bishop: newBishop,
        cnpj: removeEspecialCharacters(newCnpj),
        zip_code: removeEspecialCharacters(newZipCode),
        address: newAddress,
        complement: newComplement,
        number: Number(newNumber),
        neighborhood: newNeighborhood,
        city: newCity,
        uf: newDioceseState,
        telephone: removeEspecialCharacters(newTelephone),
        contact_mail: newContactMail,
        parish_region_id: id,
      })
      .then((resp) => {
        toast.success("✅ Diocese adicionada com sucesso!");
        setSubmitButtonLoading2(false);
        handleClose2();
        loadParishRegion(id);
      })
      .catch((error) => {
        setSubmitButtonLoading2(false);
        toast.error(
          "❌ Não foi possível adicionar a Diocese. Verifique os dados novamente!"
        );
      });
  }

  async function handleRemoveDiocese(dioceseId) {
    setTableRemoveDioceseLoading(true);

    await api.delete(`/api/diocese/${dioceseId}`);

    setTableRemoveDioceseLoading(false);

    loadParishRegion(id);
  }

  async function searchAddress() {
    setSearchAddressLoading(true);
    await axios
      .get(`https://viacep.com.br/ws/${newZipCode}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          toast.error("❌ CEP inválido!");
          setNewCity("");
          setNewDioceseState("");
          setNewAddress("");
          setNewNeighborhood("");
          setSearchAddressLoading(false);
          return;
        }

        toast.success("✅ CEP válido!");
        setNewCity(resp.data.localidade);
        setNewDioceseState(resp.data.uf);
        setNewAddress(resp.data.logradouro);
        setNewNeighborhood(resp.data.bairro);
        setSearchAddressLoading(false);
      })
      .catch((error) => {
        toast.error("❌ CEP inválido!");
        setNewCity("");
        setNewDioceseState("");
        setNewAddress("");
        setNewNeighborhood("");
        setSearchAddressLoading(false);
      });
  }

  //End Parish Region Dioceses

  return (
    <Container>
      <Row>
        <LinkContainer to="/parish-region">
          <Button variant="link">Voltar</Button>
        </LinkContainer>
      </Row>

      {parishRegionLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
          <>
            <Jumbotron>
              <Container>
                <div className="display-4">
                  Regional: {parishRegion.region_name}
                </div>
              </Container>
            </Jumbotron>

            <div className="d-flex flex-column bd-highlight mb-3">
              <div className="bd-highlight">
                <OverlayTrigger overlay={<Tooltip>Atenção!!!</Tooltip>}>
                  <Button
                    variant="danger"
                    disabled={disabled}
                    onClick={() => handleDeleteParishRegion(id)}
                  >
                    {deleteLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                        "Excluir Região Paroquial"
                      )}
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="bd-highlight">
                <small className="text-muted">
                  *Para excluir uma Região Paroquial você deve excluir todas as
                  Dioceses vinculadas à ela primeiro.
              </small>
              </div>
            </div>
            <Row className="mt-4">
              <Col>
                <Card body>
                  <div className="d-flex justify-content-between">
                    <Card.Title>Estados Abrangentes</Card.Title>
                    <Button variant="primary" onClick={handleShow}>
                      <FaPlus />
                    </Button>
                    <>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Adicionar Estado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <select
                            className="custom-select mb-3"
                            name="newState"
                            onChange={(e) => setNewState(e.target.value)}
                          >
                            <option selected>Selecione um estado e salve</option>
                            {statesToSelect.map((s) => (
                              <option value={s.id} key={s.id}>
                                {s.name}
                              </option>
                            ))}
                          </select>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Fechar
                        </Button>
                          <Button variant="primary" onClick={handleAddNewState}>
                            {submitButtonLoading ? "Salvando..." : "Salvar"}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  </div>
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      className="mt-4 text-center"
                    >
                      <thead>
                        <tr>
                          <th>Estado</th>
                          <th>Sigla</th>
                          <th>
                            {tableRemoveLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                                "Ações"
                              )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parishRegionStates.map((state) => (
                          <tr key={state.id}>
                            <td>{state.name}</td>
                            <td>{state.uf}</td>
                            <td>
                              <>
                                <Button
                                  variant="link"
                                  size="sm"
                                  onClick={() => handleRemoveState(state.id)}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                </Card>
              </Col>
              <Col>
                <Card body>
                  <div className="d-flex justify-content-between">
                    <Card.Title>Dioceses</Card.Title>
                    <Button variant="primary" onClick={handleShow2}>
                      <FaPlus />
                    </Button>
                    <>
                      <Modal
                        show={show2}
                        onHide={handleClose2}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Adicionar Diocese</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form onSubmit={handleAddNewDiocese} method="POST">
                            <h5>Dados da Diocese</h5>
                            <hr />
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="newDiocese">
                                  Nome da Diocese
                              </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="newDiocese"
                                  id="newDiocese"
                                  value={newDioceseName}
                                  onChange={(e) =>
                                    setNewDioceseName(e.target.value)
                                  }
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="cnpj">CNPJ</label>
                                <InputMask
                                  mask="99.999.999/9999-99"
                                  placeholder="Ex: 99.999.999/9999-99"
                                  type="text"
                                  className="form-control"
                                  name="cnpj"
                                  id="cnpj"
                                  value={newCnpj}
                                  onChange={(e) => setNewCnpj(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="bishop">Nome do Bispo</label>
                              <input
                                type="text"
                                className="form-control"
                                name="newBishop"
                                id="bishop"
                                value={newBishop}
                                onChange={(e) => setNewBishop(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="telephone">Telefone</label>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  placeholder="Ex: (99) 99999-9999"
                                  type="tel"
                                  className="form-control"
                                  name="telephone"
                                  id="telephone"
                                  value={newTelephone}
                                  onChange={(e) =>
                                    setNewTelephone(e.target.value)
                                  }
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="contact_email">
                                  E-mail para Contato
                              </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="contact_email"
                                  id="contact_email"
                                  value={newContactMail}
                                  onChange={(e) =>
                                    setNewContactMail(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>

                            <h5>Dados de Endereço</h5>
                            <hr />
                            <div className="form-row">
                              <div className="form-group col-md-3">
                                <label htmlFor="zipCode">
                                  CEP{" "}
                                  {searchAddressLoading ? (
                                    <Spinner animation="border" size="sm" />
                                  ) : (
                                      ""
                                    )}
                                </label>
                                <InputMask
                                  mask="99999-999"
                                  type="text"
                                  className="form-control"
                                  name="zipCode"
                                  id="zipCode"
                                  value={newZipCode}
                                  onChange={(e) => setNewZipCode(e.target.value)}
                                  onBlur={searchAddress}
                                  required
                                />
                                <small className="text-primary">
                                  *Ao digitar um CEP válido e retirar o foco do
                                  campo, os campos em cinza serão preenchidos
                                  automaticamente.
                              </small>
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="city">Cidade</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="city"
                                  id="city"
                                  value={newCity}
                                  onChange={(e) => setNewCity(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="state">UF</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="state"
                                  id="state"
                                  value={newDioceseState}
                                  onChange={(e) =>
                                    setNewDioceseState(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="form-group col-md-8">
                                <label htmlFor="address">Endereço</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="address"
                                  id="address"
                                  value={newAddress}
                                  onChange={(e) => setNewAddress(e.target.value)}
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="neighborhood">Bairro</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="neighborhood"
                                  id="neighborhood"
                                  value={newNeighborhood}
                                  onChange={(e) =>
                                    setNewNeighborhood(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-5">
                                <label htmlFor="complement">Complemento</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="complement"
                                  id="complement"
                                  value={newComplement}
                                  onChange={(e) =>
                                    setNewComplement(e.target.value)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-2">
                                <label htmlFor="number">Número</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="number"
                                  id="number"
                                  value={newNumber}
                                  onChange={(e) => setNewNumber(e.target.value)}
                                />
                              </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                              <Button variant="secondary" onClick={handleClose2}>
                                Cancelar
                            </Button>
                              <Button variant="primary" type="submit">
                                {submitButtonLoading2 ? "Salvando..." : "Salvar"}
                              </Button>
                            </div>
                          </form>
                        </Modal.Body>
                      </Modal>
                    </>
                  </div>
                  <>
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      className="mt-4 text-center"
                    >
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>UF</th>
                          <th>
                            {" "}
                            {tableRemoveDioceseLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                                "Ações"
                              )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parishRegionDioceses &&
                          parishRegionDioceses.length > 0 ? (
                            parishRegionDioceses.map((diocese) => (
                              <tr key={diocese.id}>
                                <td>
                                  <Link to={`/diocese-view/${diocese.id}`}>
                                    {diocese.name}
                                  </Link>
                                </td>
                                <td>{diocese.uf}</td>
                                <td>
                                  <>
                                    <Button
                                      variant="link"
                                      size="sm"
                                      onClick={() =>
                                        handleRemoveDiocese(diocese.id)
                                      }
                                    >
                                      <FaTimesCircle />
                                    </Button>
                                  </>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr key="nothing_diocese">
                              <td colSpan="3">Nenhuma diocese</td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </>
                </Card>
              </Col>
            </Row>
          </>
        )}
    </Container>
  );
}
