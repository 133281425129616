import { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Col,
  Container,
  Jumbotron,
  Row,
  Spinner,
  Card,
  ProgressBar,
  Table,
  Figure,
  Button,
  Modal,
} from "react-bootstrap";
import {
  FaArrowLeft,
  FaCalendar,
  FaCheckCircle,
  FaDollarSign,
  FaTimes,
} from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Zoom from "react-medium-image-zoom";

import api from "../../../services/api";
import formatCurrency, { formatValue } from "../../../utils/formatCurrency";
import { formatBRDate } from "../../../utils/formatDate";
import { formatCnpj } from "../../../utils/formatDocument";
import { showMaskaredTelephone } from "../../../utils/showTelephoneNumber";
import PrevButton from "../../../components/GoBackButton";
import { isEmptyObject } from "../../../utils/checkObject";

function MissionaryCampaignPublicConfirm() {
  const { cod } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [solicitation, setSolicitation] = useState({});
  const [diocese, setDiocese] = useState({});
  const [champaingn, setChampaingn] = useState({});
  const [solicitationProducts, setSolicitationProducts] = useState([]);
  const [payment, setPayment] = useState({});
  const [total, setTotal] = useState(0.0);

  const [finishedLoading, setFinishedLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await api
        .get(`/api/missionary-campaign-solicitation/${cod}?origin=public`)
        .then((resp) => {
          const filter = {
            ...resp.data,
            finished: !!resp.data.finished,
          };

          const filteredProducts = resp.data.champaingn_solicitation_product.map(
            (item) => {
              return {
                ...item,
                name: item.champaingn_product.name,
                quantity: item.quantity,
                total: item.champaingn_product.price * item.quantity,
                isEdited: false,
                isDeleted: !!item.champaingn_product.deleted_at,
              };
            }
          );

          const reducered = filteredProducts.reduce(
            (prevVal, elem) => prevVal + elem.total,
            0
          );

          setSolicitation(filter);
          setDiocese(resp.data.diocese);
          setChampaingn(resp.data.missionary_campaign);
          setPayment(resp.data.champaingn_solicitation_payment || {});
          setSolicitationProducts(filteredProducts);
          setTotal(reducered);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            "❌ Não foi possível encontrar a solicitação, você será redirecionado!"
          );
          setTimeout(() => history.goBack(), 1000);
        });
    })();
  }, [cod, history]);

  const handleConfirmSolicitation = useCallback(async () => {
    setFinishedLoading(true);

    await api
      .put(
        `/api/missionary-campaign/solicitation/${solicitation.id}?action=finished`
      )
      .then((resp) => {
        setFinishedLoading(false);

        setSolicitation({ ...solicitation, finished: true });

        toast.success("✅ Solicitação finalizada com sucesso!");

        setTimeout(() => {
          history.push("/missionary-champaing");
        }, 2000);
      })
      .catch((errors) => {
        setFinishedLoading(false);
      });
  }, [history, solicitation]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner variant="primary" animation="border" />
        </div>
      ) : (
        <Container>
          <div className="mb-2">
            <PrevButton />
          </div>

          <ProgressBar now={100} label="Passo 3" />

          <Jumbotron className="h-100 mt-4">
            <Row>
              <Col>
                <div className="d-flex flex-column bd-highlight">
                  <div className="bd-highlight">
                    <strong>Nome da Diocese: </strong>
                    {diocese.name && diocese.name}
                  </div>
                  <div className="bd-highlight">
                    <strong>CNPJ: </strong>
                    {diocese.cnpj && formatCnpj(diocese.cnpj)}
                  </div>
                  <div className="bd-highlight">
                    <strong>Bispo: </strong>
                    {diocese.bishop}
                  </div>
                  <div className="bd-highlight">
                    <strong>Localidade: </strong>
                    {diocese.city && diocese.city} - {diocese.uf && diocese.uf}
                  </div>
                </div>

                <div className="d-flex flex-column bd-highlight mt-2">
                  <Row>
                    <Col>
                      <div className="bd-highlight">
                        <strong>Nome do Solicitante: </strong>
                        {solicitation.name}
                      </div>
                      <div className="bd-highlight">
                        <strong>E-mail do Solicitante: </strong>
                        {solicitation.email}
                      </div>
                      <div className="bd-highlight">
                        <strong>Telefone do Solicitante: </strong>
                        {solicitation.telephone &&
                          showMaskaredTelephone(solicitation.telephone)}
                      </div>
                      <div className="bd-highlight mt-4">
                        <h5 className="text-primary">
                          {champaingn.description}
                        </h5>
                      </div>
                      <div className="bd-highlight">
                        <strong>Cód da Solicitação: </strong>
                        {solicitation.code}
                      </div>
                      <div className="bd-highlight">
                        <strong>Data de Início: </strong>
                        {champaingn.begin_dt &&
                          formatBRDate(champaingn.begin_dt)}
                      </div>
                      {champaingn.end_dt && (
                        <div className="bd-highlight">
                          <strong>Data de Término: </strong>
                          {champaingn.end_dt && formatBRDate(champaingn.end_dt)}
                        </div>
                      )}
                      <div className="bd-highlight">
                        <strong>Ano Base: </strong>
                        <Badge variant="primary">{champaingn.base_year}</Badge>
                      </div>

                      <div className="bd-highlight">
                        <strong>Finalizada: </strong>
                        {solicitation.finished ? (
                          <Badge variant="success">Sim</Badge>
                        ) : (
                          <Badge variant="danger">Não</Badge>
                        )}
                      </div>
                    </Col>

                    <Col>
                      <>
                        <Card bg="primary" text="light" className="mb-3">
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="bd-highlight">
                                <FaCalendar size={30} />
                              </div>
                              <div className="bd-highlight">
                                <div className="d-flex flex-column bd-highlight text-center">
                                  Solicitado em
                                  {solicitation.created_at && (
                                    <div
                                      className="bd-highlight"
                                      style={{ fontSize: "1.5rem" }}
                                    >
                                      {formatBRDate(solicitation.created_at)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card
                          bg={total > 0.0 ? "success" : "secondary"}
                          text="light"
                        >
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="bd-highlight">
                                <FaDollarSign size={30} />
                              </div>
                              <div className="bd-highlight">
                                <div className="d-flex flex-column bd-highlight text-center">
                                  Valor total
                                  <div
                                    className="bd-highlight"
                                    style={{ fontSize: "1.5rem" }}
                                  >
                                    {loading ? (
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                      />
                                    ) : (
                                      formatCurrency(total)
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Jumbotron>

          <Row>
            <Col>
              <h3>Resumo - Materiais Solicitados</h3>

              <Table striped bordered hover className="text-center">
                <thead>
                  <tr className="bg-primary text-white">
                    <th>#</th>
                    <th>Material</th>
                    <th>Qtde</th>
                    <th>Total R$</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitationProducts.length === 0 && (
                    <tr>
                      <td colSpan="5">Não foram encontrados dados</td>
                    </tr>
                  )}
                  {solicitationProducts.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <Badge variant="primary">
                          {formatValue(item.quantity)}
                        </Badge>
                      </td>
                      <td>
                        {item.total > 0 ? (
                          <Badge variant="success">
                            {formatCurrency(item.total)}
                          </Badge>
                        ) : (
                          <Badge variant="secondary">
                            {formatCurrency(item.total)}
                          </Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            {!isEmptyObject(payment) && (
              <Col>
                <div className="text-center">
                  <h5 className="text-primary text-center">
                    Comprovante de Pagamento
                  </h5>
                  <Figure>
                    <Zoom>
                      <Figure.Image
                        width={230}
                        alt={payment.filename}
                        src={payment.receipt_payment}
                        rounded
                      />
                    </Zoom>
                  </Figure>
                </div>
              </Col>
            )}
          </Row>

          <div class="d-flex justify-content-between">
            <div className="bd-highlight">
              <Button
                variant="secondary"
                size="lg"
                block
                className="mt-4 mb-5"
                onClick={() => history.goBack()}
                disabled={solicitation.finished}
              >
                <FaArrowLeft className="mr-2" />
                Alterar Solicitação
              </Button>
            </div>
            <div className="bd-highlight">
              <Button
                variant="success"
                size="lg"
                block
                className="mt-4 mb-5"
                onClick={handleShow}
                disabled={solicitation.finished}
              >
                <FaCheckCircle className="mr-2" />
                Confirmar Solicitação
              </Button>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            size="lg"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmar Solicitação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {solicitation.finished ? (
                <div className="text-center pt-5 pb-5">
                  <h2>Solicitação confirmada</h2>
                  <FaCheckCircle size={100} color="#1cc88a" />
                </div>
              ) : (
                <div className="text-center pb-5 pt-5">
                  <h1 className="text-danger">Atenção!</h1>

                  <p className="mt-4">
                    Ao finalizar o seu pedido você não poderá mais fazer nenhuma
                    alteração!
                  </p>

                  {total > 0 && (
                    <p>
                      Os materiais que tiverem valores financeiros, você
                      receberá um link para pagamento no seu e-mail.
                    </p>
                  )}

                  <p>Clique em Confirmar para finalizar o seu pedido.</p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={solicitation.finished}
              >
                <FaTimes className="mr-2" />
                Fechar
              </Button>
              <Button
                variant="success"
                onClick={handleConfirmSolicitation}
                disabled={solicitation.finished}
              >
                {finishedLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : solicitation.finished ? (
                  "Confirmada"
                ) : (
                  <>
                    <FaCheckCircle className="mr-2" />
                    Confirmar
                  </>
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </>
  );
}

export default MissionaryCampaignPublicConfirm;
