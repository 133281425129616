import React, { useState, useEffect } from "react";

import PiChart from "../../components/PiChart";

import api from "../../services/api";

export default function Dashboard() {
  const [parishRegionValues, setParishRegionValues] = useState([]);
  const [parishRegionLabels, setParishRegionLabels] = useState([]);
  const [parishRegionColors, setParishRegionColors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getParishRegionStates() {
      setLoading(true);

      await api.get("/api/parish_region_states").then((resp) => {
        setParishRegionValues(resp.data.values);
        setParishRegionLabels(resp.data.labels);
        setParishRegionColors(resp.data.colors);
        setLoading(false);
      });
    }
    getParishRegionStates();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <PiChart
              labels={parishRegionLabels}
              values={parishRegionValues}
              colors={parishRegionColors}
              title="Estados por Regional"
              loading={loading}
            />
          </div>
          <div className="col">
            <PiChart
              labels={parishRegionLabels}
              values={parishRegionValues}
              colors={parishRegionColors}
              title="Estados por Regional 2"
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
