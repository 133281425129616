import React, { useState } from "react";
import { Container, Button, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";

import api from "../../services/api";
import axios from "axios";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import { removeEspecialCharacters } from "../../utils/removeMask";
import ToastError from "../../utils/toastError";
import { FaSave } from "react-icons/fa";

export default function DiocesesCreate() {
  const history = useHistory();

  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [parishRegionLoading, setParishRegionLoading] = useState(false);

  //New Diocese
  const [newDioceseName, setNewDioceseName] = useState("");
  const [newBishop, setNewBishop] = useState("");
  const [newCnpj, setNewCnpj] = useState("");
  const [newZipCode, setNewZipCode] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newComplement, setNewComplement] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [newNeighborhood, setNewNeighborhood] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newDioceseState, setNewDioceseState] = useState("");
  const [newTelephone, setNewTelephone] = useState("");
  const [newContactMail, setNewContactMail] = useState("");
  const [newParishRegionId, setNewParishRegionId] = useState("");

  //api
  const [parishRegions, setParishRegions] = useState([]);

  useEffect(() => {
    loadParishRegion();
  }, []);

  async function loadParishRegion() {
    setParishRegionLoading(true);

    const { data } = await api.get("/api/parish_region/simple");

    setParishRegions(data);

    setParishRegionLoading(false);
  }

  //Parish Region Dioceses
  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitButtonLoading(true);

    await api
      .post("/api/diocese?lang=pt-br", {
        name: newDioceseName,
        bishop: newBishop,
        cnpj: removeEspecialCharacters(newCnpj),
        zip_code: removeEspecialCharacters(newZipCode),
        address: newAddress,
        complement: newComplement,
        number: Number(newNumber),
        neighborhood: newNeighborhood,
        city: newCity,
        uf: newDioceseState,
        telephone: removeEspecialCharacters(newTelephone),
        contact_mail: newContactMail,
        parish_region_id: newParishRegionId,
      })
      .then((resp) => {
        toast.success("Diocese adicionada com sucesso!");
        setSubmitButtonLoading(false);
        setTimeout(() => {
          history.push(`/dioceseView/${resp.data.id}`);
        }, 3000);
      })
      .catch((error) => {
        ToastError(error.response.data.errors);
        setSubmitButtonLoading(false);
      });
  }

  async function searchAddress() {
    await axios
      .get(`https://viacep.com.br/ws/${newZipCode}/json/`)
      .then((resp) => {
        toast.success("CEP válido!");
        setNewCity(resp.data.localidade);
        setNewDioceseState(resp.data.uf);
        setNewAddress(resp.data.logradouro);
        setNewNeighborhood(resp.data.bairro);
      })
      .catch((error) => {
        toast.error("CEP inválido!");
        setNewCity("");
        setNewDioceseState("");
        setNewAddress("");
        setNewNeighborhood("");
      });
  }

  return (
    <Container>
      <h1>Nova Diocese</h1>

      <Link to="/dioceses">Voltar</Link>

      <form onSubmit={handleSubmit} method="POST" className="mt-4">
        <h5>Dados da Diocese</h5>
        <hr />
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="newDiocese">Nome da Diocese</label>
            <input
              type="text"
              className="form-control"
              name="newDiocese"
              id="newDiocese"
              value={newDioceseName}
              onChange={(e) => setNewDioceseName(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="cnpj">CNPJ</label>
            <InputMask
              mask="99.999.999/9999-99"
              placeholder="Ex: 99.999.999/9999-99"
              type="text"
              className="form-control"
              name="cnpj"
              id="cnpj"
              value={newCnpj}
              onChange={(e) => setNewCnpj(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-7">
            <label htmlFor="bishop">Nome do Bispo</label>
            <input
              type="text"
              className="form-control"
              name="newBishop"
              id="bishop"
              value={newBishop}
              onChange={(e) => setNewBishop(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-5">
            <label htmlFor="telephone">Telefone</label>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Ex: (99) 99999-9999"
              type="tel"
              className="form-control"
              name="telephone"
              id="telephone"
              value={newTelephone}
              onChange={(e) => setNewTelephone(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="contact_email">E-mail para Contato</label>
            <input
              type="email"
              className="form-control"
              name="contact_email"
              id="contact_email"
              value={newContactMail}
              onChange={(e) => setNewContactMail(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="regions">
              Regionais CNBB{" "}
              {parishRegionLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                ""
              )}
            </label>
            <select
              className="form-control"
              id="regions"
              name="parishRegionId"
              defaultValue={"DEFAULT"}
              onChange={(e) => setNewParishRegionId(e.target.value)}
            >
              <option value="DEFAULT" disabled>
                -- Selecione uma regional --
              </option>
              {parishRegions.map((pr) => (
                <option value={pr.id} key={pr.id}>
                  {pr.region_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <h5>Dados de Endereço</h5>
        <hr />
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="zipCode">CEP</label>
            <InputMask
              mask="99999-999"
              type="text"
              className="form-control"
              name="zipCode"
              id="zipCode"
              value={newZipCode}
              onChange={(e) => setNewZipCode(e.target.value)}
              onBlur={searchAddress}
              required
            />
            <small className="text-primary">
              *Ao digitar um CEP válido e retirar o foco do campo, os campos em
              cinza serão preenchidos automaticamente.
            </small>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="city">Cidade</label>
            <input
              type="text"
              className="form-control"
              name="city"
              id="city"
              value={newCity}
              onChange={(e) => setNewCity(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="state">UF</label>
            <input
              type="text"
              className="form-control"
              name="state"
              id="state"
              value={newDioceseState}
              onChange={(e) => setNewDioceseState(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-8">
            <label htmlFor="address">Endereço</label>
            <input
              type="text"
              className="form-control"
              name="address"
              id="address"
              value={newAddress}
              onChange={(e) => setNewAddress(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="neighborhood">Bairro</label>
            <input
              type="text"
              className="form-control"
              name="neighborhood"
              id="neighborhood"
              value={newNeighborhood}
              onChange={(e) => setNewNeighborhood(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-5">
            <label htmlFor="complement">Complemento</label>
            <input
              type="text"
              className="form-control"
              name="complement"
              id="complement"
              value={newComplement}
              onChange={(e) => setNewComplement(e.target.value)}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="number">Número</label>
            <input
              type="number"
              className="form-control"
              name="number"
              id="number"
              value={newNumber}
              onChange={(e) => setNewNumber(e.target.value)}
            />
          </div>
        </div>
        <Button variant="primary" type="submit">
          {submitButtonLoading ? (
            "Salvando..."
          ) : (
            <>
              <FaSave className="mr-2" /> Salvar{" "}
            </>
          )}
        </Button>
      </form>
    </Container>
  );
}
