import React from "react";

import { useReactAuth } from "../contexts/hooks/AuthContext";
import Loading from "../components/Loading";
import AuthLayout from "../pages/_layouts/auth";
import DefaultLayout from "../pages/_layouts/default";

import PrivateRoutes from "./app.routes";
import PublicRoutes from "./public.routes";

function Routes() {
  const { signed, loading } = useReactAuth();

  const Layout = signed ? DefaultLayout : AuthLayout;

  if (loading) {
    return <Loading />;
  }

  return signed ? (
    <Layout>
      <PrivateRoutes />
    </Layout>
  ) : (
    <Layout>
      <PublicRoutes />
    </Layout>
  );
}

export default Routes;
