import React from "react";
import { Container } from "react-bootstrap";

import UsersTable from "../../components/UsersTable";

export default function Users() {
  return (
    <>
      <Container>
        <UsersTable url={`/api/user`} />
      </Container>
    </>
  );
}
