import { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Pagination,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  FaEdit,
  FaEye,
  FaFileDownload,
  FaFileInvoice,
  FaInfoCircle,
  FaPlus,
  FaSearch,
  FaTicketAlt,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import formatCurrency from "../../utils/formatCurrency";
import { showMaskaredTelephone } from "../../utils/showTelephoneNumber";
import InputMask from "react-input-mask";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { toast } from "react-toastify";
import SubmitButtonApp from "../../components/SubmitButton";
import { removeEspecialCharacters } from "../../utils/removeMask";
import { formatDateAndHours, formatDate } from "../../utils/formatDate";

export default function MissionaryCampaignSolicitationsTable({
  campaingnId,
  url,
}) {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [showTotal, setShowTotal] = useState({
    total: 0,
    from: 0,
    to: 0,
    per_page: 10,
    totalPages: 0,
  });

  const [pages, setPages] = useState([]);
  const [limit, setLimit] = useState(10);
  const [selectTotalShow] = useState([5, 10, 15, 20, 30]);
  const [maxVisibleButtons] = useState(8);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [solicitationToDelete, setSolicitationToDelete] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const [dataToSearch, setDataToSearch] = useState("");
  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [invoiceToChangeStatus, setInvoiceToChangeStatus] = useState({});

  const [showChangeStatus, setShowChangeStatus] = useState(false);

  const handleCloseChangeStatus = () => {
    setShowChangeStatus(false);
    setInvoiceToChangeStatus({});
  };
  const handleShowChangeStatus = () => setShowChangeStatus(true);

  const [status] = useState([
    { value: "", label: "Todas" },
    { value: "pending", label: "Pendente" },
    { value: "paid", label: "Paga" },
    { value: "cancel", label: "Cancelada" },
    { value: "deleted", label: "Excluída" },
    { value: "expired", label: "Expirada" },
  ]);

  const [statusToChange] = useState([
    { value: "pending", label: "Pendente" },
    { value: "paid", label: "Paga" },
    { value: "cancel", label: "Cancelada" },
    { value: "deleted", label: "Excluída" },
    { value: "expired", label: "Expirada" },
  ]);

  function getStatusColor(status) {
    switch (status) {
      case "pending":
        return "warning";
      case "paid":
        return "success";
      case "cancel":
      case "deleted":
        return "danger";
      case "expired":
        return "secondary";
      default:
        return "secondary";
    }
  }

  function getStatus(status) {
    switch (status) {
      case "pending":
        return "pendente";
      case "paid":
        return "paga";
      case "cancel":
        return "cancelada";
      case "deleted":
        return "excluída";
      case "expired":
        return "expirada";
      default:
        return "pendente";
    }
  }

  useEffect(() => {
    let mounted = true;

    (async () => {
      setLoading(true);

      const resp = await api.get(
        `${url}?page=${page}&search=${search}&limit=${limit}&status=${selectedStatus}`
      );

      if (!mounted) return;

      const response = resp.data.data.map((d) => {
        return {
          ...d,
          trashed: d.deleted_at !== null ? true : false,
          status_color: getStatusColor(d.status),
          status_translate: getStatus(d.status),
        };
      });

      setData(response);

      setLastPage(resp.data.last_page);

      setShowTotal({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        per_page: resp.data.per_page,
        totalPages: resp.data.last_page,
      });

      const totalPages = resp.data.last_page;

      let maxLeft = page - Math.floor(maxVisibleButtons / 2);
      let maxRight = page + Math.floor(maxVisibleButtons / 2);

      if (maxLeft <= 1) {
        maxLeft = 1;
        maxRight = maxVisibleButtons;
      }

      if (maxRight > totalPages) {
        maxLeft = totalPages - (maxVisibleButtons - 1);
        maxRight = totalPages;
      }

      const arrayPages = [];

      for (let page = maxLeft; page <= maxRight; page++) {
        arrayPages.push(page);
      }

      const filteredArrayPages = arrayPages.filter((nr) => nr > 0);

      setPages(filteredArrayPages);

      page === 1 ? setIsFirstPage(true) : setIsFirstPage(false);

      page === lastPage || lastPage === null
        ? setIsLastPage(true)
        : setIsLastPage(false);

      setLoading(false);
    })();
    return () => {
      mounted = false;
    };
  }, [page, url, lastPage, limit, maxVisibleButtons, search, selectedStatus]);

  function nextPage() {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  function handleGo(id) {
    history.push(`/campaign-solicitation-view/${id}`);
  }

  const [dioceseToSearch, setDioceseToSearch] = useState("");
  const [searchedDioceses, setSearchedDioceses] = useState([]);
  const [diocese, setDiocese] = useState({
    id: "",
    name: "",
  });
  const [searchLoading, setSearchLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const searchDiocese = useCallback(async () => {
    setSearchLoading(true);

    await api
      .get(`/api/diocese/search?query=${dioceseToSearch}`)
      .then((resp) => {
        if (!resp.data) {
          setSearchedDioceses("");
          setSearchLoading(false);
          setShowTable(true);
          toast.warning("⁉ Nenhuma diocese encontrada");
          return;
        }

        setSearchedDioceses(resp.data);
        setSearchLoading(false);
        setShowTable(true);
        toast.success("✅ Congregações encontradas");
      })
      .catch((error) => {
        setSearchedDioceses("");
        setSearchLoading(false);
        setShowTable(true);
      });
  }, [dioceseToSearch]);

  const updateData = useCallback(
    (dataId, options) => {
      setData(
        data.map((d) => {
          if (d.id === dataId) {
            d = {
              ...d,
              ...options,
            };
          }
          return d;
        })
      );
    },
    [data]
  );

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#bababa",
    columnWidth: "60px",
    onSelect: onRowSelect,
  };

  function onRowSelect(row) {
    setDiocese({ id: row.id, name: row.name });
  }

  const resetSolicitationForm = useCallback(() => {
    setName("");
    setEmail("");
    setTelephone("");
    setShowTable(false);
    setDioceseToSearch("");
    setSearchedDioceses([]);
    setDiocese({ id: "", name: "" });
    handleClose();
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setSubmitLoading(true);

      const { id: dioceseId } = diocese;

      if (!dioceseId) {
        toast.error("❌ Informe uma Diocese");
        return;
      }

      await api
        .post("/api/missionary-campaign/solicitation", {
          name: name,
          email: email,
          telephone: removeEspecialCharacters(telephone),
          diocese_id: dioceseId,
          missionary_campaign_id: campaingnId,
        })
        .then((resp) => {
          setData([...data, resp.data]);
          setSubmitLoading(false);
          toast.success("✅ Solicitação criada com sucesso!");
          resetSolicitationForm();
        })
        .catch((errors) => {
          if (errors.response) {
            Object.values(errors.response.data.errors).forEach((item) => {
              toast.error(`❌ ${item}`, { autoClose: 8000 });
            });
            setSubmitLoading(false);
            return;
          }

          toast.error(
            "❌ Não foi possível criar uma solicitação, tente novamente!"
          );
          setSubmitLoading(false);
        });
    },
    [campaingnId, data, diocese, email, name, resetSolicitationForm, telephone]
  );

  const handleLimit = useCallback((value) => {
    setLimit(value);
    setPage(1);
  }, []);

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();

      setPage(1);

      setSearch(dataToSearch.trim());
    },
    [dataToSearch]
  );

  const handleClearSearch = useCallback(() => {
    setSelectedStatus("");
    setSearch("");
    setDataToSearch("");
  }, []);

  const handleOpenDelete = useCallback((id) => {
    setSolicitationToDelete(id);
    handleShowDelete(true);
  }, []);

  const handleDeleteSolicitation = useCallback(async () => {
    setDeleteLoading(true);

    await api
      .delete(`/api/missionary-campaign/solicitation/${solicitationToDelete}`)
      .then((resp) => {
        const filteredSolicitations = data.filter(
          (soli) => soli.id !== solicitationToDelete
        );

        setData(filteredSolicitations);

        setShowTotal({ ...showTotal, total: showTotal.total - 1 });

        toast.success("✅ Solicitação removida com sucesso!");

        setSolicitationToDelete(0);

        handleCloseDelete();

        setDeleteLoading(false);
      })
      .catch((error) => {
        toast.error(
          "❌ Não foi possível remover a solicitação, tente novamente!"
        );
        setDeleteLoading(false);
      });
  }, [data, showTotal, solicitationToDelete]);

  const handleGenerateLabel = useCallback(async (id) => {
    await api
      .get(`/api/missionary-campaign/solicitation/box-labels/${id}`, {
        responseType: "blob",
      })
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );
      });
  }, []);

  const handleDownloadInvoice = useCallback(async (invoiceId) => {
    await api
      .get(
        `/api/missionary-campaign/solicitation/invoice/download/${invoiceId}`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );
      });
  }, []);

  const addToChangeStatus = useCallback(({ invoiceId, status }) => {
    setInvoiceToChangeStatus({
      invoice_id: invoiceId,
      status: status,
      credit_date: new Date(),
    });
    handleShowChangeStatus();
  }, []);

  const handleChangeStatus = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const response = await api.put(
          `/api/missionary-campaign/invoice/${invoiceToChangeStatus.invoice_id}`,
          {
            status: invoiceToChangeStatus.status,
            credit_date: invoiceToChangeStatus.credit_date,
          }
        );

        updateData(response.data.champaingn_solicitation_id, {
          status: response.data.status,
          credit_date: response.data.credit_date,
          status_color: getStatusColor(response.data.status),
          status_translate: getStatus(response.data.status),
        });

        handleCloseChangeStatus();

        toast.success("Status alterado com sucesso!");
      } catch (error) {
        console.log(error);
        toast.error("Não foi possivel alterar");
      }
    },
    [
      invoiceToChangeStatus.credit_date,
      invoiceToChangeStatus.invoice_id,
      invoiceToChangeStatus.status,
      updateData,
    ]
  );

  const handleGenerateSecondView = useCallback(async (id) => {
    try {
      await api.post(`/api/missionary-campaign/invoice/new-invoice/${id}`);
      toast.success("Solicitação de 2º via gerada");
    } catch (error) {
      toast.error("Não foi possível solicitar, tente novamente mais tarde");
    }
  }, []);

  return (
    <>
      <div className="text-center mb-4">
        <h3>Solicitações de Materiais da Campanha</h3>
      </div>

      <Button variant="primary" size="sm" className="mb-4" onClick={handleShow}>
        <FaPlus className="mr-1" /> Nova Solicitação
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nova Solicitação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit} method="POST">
            <Form.Row>
              <Form.Group as={Col} controlId="name">
                <Form.Label>Nome do Solicitatante</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Nome do solicitatante"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="email">
                <Form.Label>E-mail do Solicitatante</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="E-mail do solicitatante"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="telephone">
                <Form.Label>Telefone do Solicitatante</Form.Label>
                <InputMask
                  type="tel"
                  name="telephone"
                  className="form-control"
                  mask="(99) 99999-9999"
                  placeholder="Telefone do Solicitatante"
                  required
                  onChange={(e) => setTelephone(e.target.value)}
                  value={telephone}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Col xs="10" className="my-2">
                <Form.Control
                  className="mb-2"
                  placeholder="Busque a Diocese"
                  onChange={(e) => setDioceseToSearch(e.target.value)}
                  value={dioceseToSearch}
                />
              </Col>
              <Col xs="auto" className="my-1">
                <Button className="mb-2" onClick={searchDiocese}>
                  {searchLoading ? (
                    <>
                      {" "}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : (
                    <FaSearch />
                  )}
                </Button>
              </Col>
            </Form.Row>

            <Form.Row className="mb-4">
              {showTable ? (
                searchLoading ? (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <>
                    <BootstrapTable
                      data={searchedDioceses}
                      striped
                      hover
                      selectRow={selectRowProp}
                    >
                      <TableHeaderColumn dataField="id" isKey hidden>
                        ID da Diocese
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="name">
                        Nome
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="city">
                        Cidade
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="uf" width="60">
                        UF
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </>
                )
              ) : (
                <small>* Faça a busca por uma Congregação</small>
              )}
            </Form.Row>

            <SubmitButtonApp
              color="primary"
              type="submit"
              loading={submitLoading}
            >
              Salvar
            </SubmitButtonApp>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Deseja realmente deletar a solicitação? </h5>

          <div className="d-flex flex-row bd-highlight justify-content-between mt-5">
            <div className="bd-highlight">
              <Button variant="secondary" onClick={handleCloseDelete}>
                Cancelar
              </Button>
            </div>
            <div className="bd-highlight">
              <Button variant="danger" onClick={handleDeleteSolicitation}>
                {deleteLoading ? "Excluindo ..." : "Sim, quero deletar"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Form inline onSubmit={handleSearch}>
        <FormControl
          type="text"
          size="sm"
          placeholder="Buscar por (Código, Nome, E-mail ou Número do Solicitante)"
          className="mr-2"
          style={{ width: "50%" }}
          onChange={(e) => setDataToSearch(e.target.value)}
          value={dataToSearch}
        />

        <Form.Control
          as="select"
          size="sm"
          className="mr-2"
          custom
          defaultValue={status[0]}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          {status.map((item, index) => (
            <option value={item.value} key={index}>
              {item.label}
            </option>
          ))}
        </Form.Control>

        <Button
          size="sm"
          variant="primary"
          className="mr-2"
          onClick={handleSearch}
          disabled={!!!dataToSearch}
        >
          <FaSearch />
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={handleClearSearch}
          disabled={!!!search}
        >
          <FaTimes />
        </Button>
      </Form>

      <Table striped bordered responsive hover className="text-center mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Diocese</th>
            <th>
              Nome <small>de quem solicitou</small>
            </th>
            <th>
              E-mail <small>de quem solicitou</small>
            </th>
            <th>
              Telefone <small>de quem solicitou</small>
            </th>
            <th>Total R$</th>
            <th>Data da Solicitação</th>
            <th>Finalizada</th>
            <th colSpan="3">Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="8" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          ) : (
            data.map((m, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{m.diocese_name}</td>

                <td>{m.name}</td>
                <td>{m.email}</td>
                <td>{showMaskaredTelephone(m.telephone)}</td>
                <td>
                  <Badge variant={m.total > 0 ? "success" : "secondary"}>
                    {formatCurrency(m.total)}
                  </Badge>
                </td>
                <td>{formatDateAndHours(m.created_at)}</td>
                <td>
                  {m.finished ? (
                    <span className="bg-success text-white p-1">Sim</span>
                  ) : (
                    <span className="bg-danger text-white p-1">Não</span>
                  )}
                </td>
                <td colSpan="3">
                  <div className="d-flex align-self-center">
                    {m.status !== "paid" ? (
                      <span className={`bg-${m.status_color} text-white p-1`}>
                        {m.status_translate}
                      </span>
                    ) : (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="popover-paid-invoice">
                            <small>
                              <span>Pago em: </span>
                              <strong>{formatDate(m.credit_date)}</strong>
                            </small>
                          </Tooltip>
                        }
                      >
                        <div>
                          <span
                            className={`bg-${m.status_color} text-white p-1`}
                          >
                            {m.status_translate}
                          </span>
                          <span style={{ cursor: "pointer" }} className="ml-1">
                            <FaInfoCircle />
                          </span>
                        </div>
                      </OverlayTrigger>
                    )}

                    <Button
                      size="sm"
                      className="ml-2"
                      onClick={() =>
                        addToChangeStatus({
                          invoiceId: m.invoice_id,
                          status: m.status,
                        })
                      }
                    >
                      <FaEdit />
                    </Button>
                  </div>
                </td>

                <td>
                  <ButtonGroup aria-label="Basic example">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-view`}>Visualizar</Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleGo(m.id)}
                      >
                        <FaEye />
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-ticket`}>Gerar Etiqueta</Tooltip>
                      }
                    >
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => {
                          handleGenerateLabel(m.id);
                        }}
                      >
                        <FaTicketAlt />
                      </Button>
                    </OverlayTrigger>

                    {m.invoice_id && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-download-invoice`}>
                              Baixar Boleto
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                              handleDownloadInvoice(m.invoice_id);
                            }}
                          >
                            <FaFileDownload />
                          </Button>
                        </OverlayTrigger>

                        {m.status !== "paid" && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-second-view-invoice`}>
                                <div>2º via</div>
                                <small>Hoje + 5 dias</small>
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="warning"
                              size="sm"
                              onClick={() => {
                                handleGenerateSecondView(m.id);
                              }}
                            >
                              <FaFileInvoice />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </>
                    )}

                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-view`}>Deletar</Tooltip>}
                    >
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleOpenDelete(m.id)}
                      >
                        <FaTrash />
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Container fluid className="p-0">
        <Row>
          <Col>
            <small className="mt-0">
              Mostrando <strong>{showTotal.from} </strong> até
              <strong> {showTotal.to}</strong> de{" "}
              <strong> {showTotal.total} </strong>
              Registros
            </small>
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={12}>
            <Form.Group controlId="showTotalPerPage">
              <Form.Control
                as="select"
                custom
                onChange={(e) => handleLimit(e.target.value)}
                disabled={
                  !!selectTotalShow.every((total) => total >= showTotal.total)
                }
                defaultValue={limit}
              >
                {selectTotalShow.map((total, index) => (
                  <option
                    value={total}
                    key={index}
                    disabled={total >= showTotal.total}
                  >
                    {total}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={10} sm={12}>
            <div className="d-flex justify-content-end">
              <Pagination>
                <Pagination.First
                  onClick={() => setPage(1)}
                  disabled={isFirstPage}
                />
                <Pagination.Prev onClick={prevPage} disabled={isFirstPage} />

                {pages[0] > 1 && <Pagination.Ellipsis disabled />}

                {pages.map((p, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(p)}
                    active={page === p}
                  >
                    {p}
                  </Pagination.Item>
                ))}

                {pages[maxVisibleButtons - 1] < showTotal.totalPages && (
                  <Pagination.Ellipsis disabled />
                )}

                <Pagination.Next onClick={nextPage} disabled={isLastPage} />
                <Pagination.Last
                  onClick={() => setPage(lastPage)}
                  disabled={isLastPage}
                />
              </Pagination>
            </div>
          </Col>
        </Row>

        <Modal
          show={showChangeStatus}
          centered
          onHide={handleCloseChangeStatus}
        >
          <Modal.Header closeButton>
            <Modal.Title>Status do Boleto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleChangeStatus}>
              <Form.Group controlId="changeStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  className="mr-2"
                  custom
                  defaultValue={invoiceToChangeStatus.status}
                  onChange={(e) =>
                    setInvoiceToChangeStatus({
                      ...invoiceToChangeStatus,
                      status: e.target.value,
                    })
                  }
                >
                  {statusToChange.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {invoiceToChangeStatus.status === "paid" && (
                <Form.Group controlId="creditDate">
                  <Form.Label>Data de pagamento</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Data de pagamento"
                    value={invoiceToChangeStatus.credit_date}
                    onChange={(e) =>
                      setInvoiceToChangeStatus({
                        ...invoiceToChangeStatus,
                        credit_date: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              )}

              <Button type="submit">Salvar</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
