import React from "react";

import { Link } from "react-router-dom";

import { Container } from "react-bootstrap";

import { CardContainer } from "./styles";

import ChartImg from "../../assets/img/home-img/doughnut.png";
import ParishRegionImg from "../../assets/img/home-img/parish_region.png";
import DicoeseImg from "../../assets/img/home-img/diocese.png";
import ParishImg from "../../assets/img/home-img/parish.png";
import DonationImg from "../../assets/img/home-img/donation.png";
import GroupsImg from "../../assets/img/home-img/safe_box_groups.png";
import MissionaryCollectionImg from "../../assets/img/home-img/missionary_collection.png";
import MissionaryCampaignImg from "../../assets/img/home-img/campaign.png";
import ReportsImg from "../../assets/img/home-img/reports.png";
import SettingsImg from "../../assets/img/home-img/settings.png";
import FinanceImg from "../../assets/img/home-img/finance.png";

function CardHome() {
  return (
    <>
      <Container>
        <CardContainer>
          <ul>
            <Link to="/missionary-collection">
              <li>
                <div>
                  <img src={MissionaryCollectionImg} alt="Coleta" />
                  <h5>Coleta Missionária</h5>
                </div>
              </li>
            </Link>

            <Link to="/missionary-campaign">
              <li>
                <div>
                  <img src={MissionaryCampaignImg} alt="Missionary Campaign" />
                  <h5>Campanha Missionária</h5>
                </div>
              </li>
            </Link>

            <Link to="/donation">
              <li>
                <div>
                  <img src={DonationImg} alt="Donations" />
                  <h5>Doações Cofrinho</h5>
                </div>
              </li>
            </Link>

            <Link to="/dashboard">
              <li>
                <div>
                  <img src={ChartImg} alt="Chart" />
                  <h5>Dashboard</h5>
                </div>
              </li>
            </Link>

            <Link to="/reports">
              <li>
                <div>
                  <img src={ReportsImg} alt="Reports" />
                  <h5>Relatórios</h5>
                </div>
              </li>
            </Link>

            <Link to="/global-settings">
              <li>
                <div>
                  <img src={SettingsImg} alt="Global Settings" />
                  <h5>Configurações Globais</h5>
                </div>
              </li>
            </Link>

            <Link to="/dioceses">
              <li>
                <div>
                  <img src={DicoeseImg} alt="Dioceses" />
                  <h5>Dioceses</h5>
                </div>
              </li>
            </Link>

            <Link to="/parish">
              <li>
                <div>
                  <img src={ParishImg} alt="Parish" />
                  <h5>Paróquias</h5>
                </div>
              </li>
            </Link>

            <Link to="/safe-box">
              <li>
                <div>
                  <img src={GroupsImg} alt="Groups" />
                  <h5>Grupos IAM</h5>
                </div>
              </li>
            </Link>

            <Link to="/parish-region">
              <li>
                <div>
                  <img src={ParishRegionImg} alt="Parish Region" />
                  <h5>Regionais</h5>
                </div>
              </li>
            </Link>

            <Link to="/finance">
              <li>
                <div>
                  <img src={FinanceImg} alt="Finance" />
                  <h5>Financeiro</h5>
                </div>
              </li>
            </Link>
          </ul>
        </CardContainer>
      </Container>
    </>
  );
}

export default CardHome;
