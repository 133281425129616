import React from "react";

import "./style.css";

import checkImage from "../../assets/img/check.svg";

function Success() {
  return (
    <>
      <div className="text-center">
        <img src={checkImage} alt="Check" className="img-fluid" width="350" />
      </div>
      <div className="text-center mt-4">
        <h2 className="text-check">Comprovante enviado com sucesso!</h2>
      </div>
      <div className="text-center mt-2">
        <span className="text-muted">
          Se os dados estiverem corretos a Carta e o Recibo chegarão para você.
        </span>
      </div>
      <div className="text-center mt-4">
        <span className="text-warning h4">Fique atento ao seu e-mail.</span>
      </div>
    </>
  );
}

export default Success;
