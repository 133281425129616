import { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  FaEdit,
  FaPlus,
  FaSave,
  FaTimes,
  FaTrash,
  FaTrashRestore,
} from "react-icons/fa";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import IntlCurrencyInput from "react-intl-currency-input";

import GoBackButton from "../../components/GoBackButton";
import formatCurrency from "../../utils/formatCurrency";

import api from "../../services/api";

export default function MissionaryCampaignSettings() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const [newProductName, setNewProductName] = useState("");
  const [newProductPrice, setNewProductPrice] = useState(0.0);
  const [addLoading, setAddLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEdited, setEdited] = useState(false);
  const [editedId, setEditedId] = useState("");

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const productUpdate = useCallback(
    (dataId, options) => {
      setProducts(
        products.map((r) => {
          if (r.id === dataId) {
            r = {
              ...r,
              ...options,
            };
          }
          return r;
        })
      );
    },
    [products]
  );

  const cleanNewProducts = useCallback(() => {
    setNewProductName("");
    setNewProductPrice(0.0);
    setEdited(false);
    setEditedId("");
  }, []);

  useEffect(() => {
    (async () => {
      Promise.all([await api.get("/api/missionary-campaign/product")])
        .then((results) => {
          setProducts(results[0].data);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          history.goBack();
        });
    })();
  }, [history]);

  const handleAddProduct = useCallback(
    async (e) => {
      e.preventDefault();

      setAddLoading(true);

      await api
        .post("/api/missionary-campaign/product", {
          name: newProductName.trim(),
          price: parseFloat(newProductPrice),
        })
        .then((resp) => {
          setAddLoading(false);

          setProducts([...products, resp.data]);

          toast.success("✅ Material adicionado!");

          cleanNewProducts();
        })
        .catch((errors) => {
          setAddLoading(false);

          const statusCode = errors.response.status;

          switch (statusCode) {
            case 409:
              toast.error(`❌ ${errors.response.data.error}`);
              break;
            case 422:
              Object.values(errors.response.data.errors).forEach((item) => {
                toast.error(`❌ ${item}`, { autoClose: 8000 });
              });
              break;
            default:
              toast.error(
                `❌ Não foi possível adicionar, verifique os dados e tente novamente!`
              );
              break;
          }
        });
    },
    [cleanNewProducts, newProductName, newProductPrice, products]
  );

  const handleEditProduct = useCallback(
    (productId) => {
      const product = products.find((item) => item.id === productId);

      setEdited(true);

      setEditedId(product.id);

      setNewProductName(product.name);
      setNewProductPrice(product.price);
    },
    [products]
  );

  const handleSaveProduct = useCallback(async () => {
    setSaveLoading(true);

    await api
      .put(`/api/missionary-campaign/product/${editedId}`, {
        name: newProductName.trim(),
        price: parseFloat(newProductPrice),
      })
      .then((resp) => {
        setSaveLoading(false);

        productUpdate(editedId, resp.data);

        toast.success("✅ Material alterado!");

        cleanNewProducts();
      })
      .catch((errors) => {
        setSaveLoading(false);

        const statusCode = errors.response.status;

        switch (statusCode) {
          case 409:
            toast.error(`❌ ${errors.response.data.error}`);
            break;
          case 422:
            Object.values(errors.response.data.errors).forEach((item) => {
              toast.error(`❌ ${item}`, { autoClose: 8000 });
            });
            break;
          default:
            toast.error(
              `❌ Não foi possível alterar, verifique os dados e tente novamente!`
            );
            break;
        }
      });
  }, [
    cleanNewProducts,
    editedId,
    newProductName,
    newProductPrice,
    productUpdate,
  ]);

  const handleDeleteProduct = useCallback(
    async (productId, action) => {
      switch (action) {
        case "delete":
          await api
            .delete(`/api/missionary-campaign/product/${productId}`)
            .then((resp) => {
              productUpdate(productId, resp.data);
              toast.success("✅ Material movido para a Lixeira");
            })
            .catch((errors) => {
              const statusCode = errors.response.status;

              switch (statusCode) {
                case 409:
                  toast.error(`❌ ${errors.response.data.error}`);
                  break;
                case 422:
                  Object.values(errors.response.data.errors).forEach((item) => {
                    toast.error(`❌ ${item}`, { autoClose: 8000 });
                  });
                  break;
                default:
                  toast.error(
                    `❌ Não foi possível adicionar, verifique os dados e tente novamente!`
                  );
                  break;
              }
            });
          break;
        case "restore":
          await api
            .put(`/api/missionary-campaign/product/${productId}?action=restore`)
            .then((resp) => {
              productUpdate(productId, resp.data);
              toast.success("✅ Material restaurado");
            })
            .catch((errors) => {
              const statusCode = errors.response.status;

              switch (statusCode) {
                case 409:
                  toast.error(`❌ ${errors.response.data.error}`);
                  break;
                case 422:
                  Object.values(errors.response.data.errors).forEach((item) => {
                    toast.error(`❌ ${item}`, { autoClose: 8000 });
                  });
                  break;
                default:
                  toast.error(
                    `❌ Não foi possível adicionar, verifique os dados e tente novamente!`
                  );
                  break;
              }
            });
          break;

        default:
          break;
      }
    },
    [productUpdate]
  );

  return (
    <Container fluid>
      <GoBackButton />
      <h1>Configurações da Campanha Missionária</h1>

      {loading && (
        <div className="text-center mt-4 mb-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <Row className="mt-5">
        <Col md={6} sm={12}>
          <h3 className="text-center">Materiais Campanha</h3>
          <Table striped bordered hover className="text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Preço</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {!products.length && (
                <tr>
                  <td colSpan="4">Nunhum registro encontrado</td>
                </tr>
              )}
              {products.map((p, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {p.name}{" "}
                    {!!p.deleted_at && <Badge variant="danger">lixeira</Badge>}
                  </td>
                  <td>{formatCurrency(p.price)}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-edit-${index}`}>Editar</Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          onClick={() => handleEditProduct(p.id)}
                        >
                          <FaEdit />
                        </Button>
                      </OverlayTrigger>

                      {!!p.deleted_at ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-restore-${index}`}>
                              Restaurar
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="warning"
                            onClick={() => handleDeleteProduct(p.id, "restore")}
                          >
                            <FaTrashRestore />
                          </Button>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-d-${index}`}>Excluir</Tooltip>
                          }
                        >
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteProduct(p.id, "delete")}
                          >
                            <FaTrash />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Form onSubmit={handleAddProduct}>
            <Form.Row className="align-items-center">
              <Col>
                <Form.Control
                  placeholder="Nome do Material"
                  onChange={(e) => setNewProductName(e.target.value)}
                  value={newProductName}
                />
              </Col>

              <Col>
                <IntlCurrencyInput
                  currency="BRL"
                  config={currencyConfig}
                  onChange={(e, value) => setNewProductPrice(value)}
                  value={newProductPrice}
                  className="form-control"
                />
              </Col>
            </Form.Row>

            <Form.Row className="mt-3">
              {isEdited && (
                <Col>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id={`tooltip-edit`}>Salvar</Tooltip>}
                  >
                    <Button variant="success" onClick={handleSaveProduct} block>
                      {saveLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span>{" "}
                        </>
                      ) : (
                        <FaSave />
                      )}
                    </Button>
                  </OverlayTrigger>
                </Col>
              )}

              <Col>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id={`tooltip-add`}>Adicionar</Tooltip>}
                >
                  <Button
                    variant="primary"
                    type="submit"
                    block
                    disabled={isEdited}
                  >
                    {addLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>{" "}
                      </>
                    ) : (
                      <FaPlus />
                    )}
                  </Button>
                </OverlayTrigger>
              </Col>
              <Col>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id={`tooltip-clean`}>Limpar</Tooltip>}
                >
                  <Button variant="danger" onClick={cleanNewProducts} block>
                    <FaTimes />
                  </Button>
                </OverlayTrigger>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
