import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Spinner,
  Button,
  Image,
  Form,
  FormControl,
  Container,
  Row,
  Col,
  Pagination,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import {
  FaCheckCircle,
  FaSearch,
  FaTimes,
  FaFileInvoice,
  FaEnvelope,
  FaEye,
  FaFileExcel,
} from "react-icons/fa";
import { toast } from "react-toastify";

import api from "../../services/api";
import formatCurrency from "../../utils/formatCurrency";

import CardMoney from "../CardMoney";

import { isEmptyObject } from "../../utils/checkObject";

import "react-medium-image-zoom/dist/styles.css";

function MissionaryCollectContribTable({ url, id, finished }) {
  const [data, setData] = useState([]);
  const [totalValue, setTotalValue] = useState([]);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingValue, setLoadingValue] = useState(false);

  const [verifyLoading, setVerifyLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  const [showTotal, setShowTotal] = useState({
    total: 0,
    from: 0,
    to: 0,
    per_page: 10,
    totalPages: 0,
  });

  const [contribView, setContribView] = useState({});
  const [contribValue, setContribValue] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [loadingView, setLoadingView] = useState(false);

  const [pages, setPages] = useState([]);
  const [limit, setLimit] = useState(10);
  const [selectTotalShow] = useState([5, 10, 15, 20, 30]);
  const [maxVisibleButtons] = useState(8);

  const [dataToSearch, setDataToSearch] = useState("");
  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [status] = useState([
    { value: "", label: "Todas" },
    { value: "pending", label: "Não Verificado" },
    { value: "verified", label: "Verificado" },
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setContribView({});
    setContribValue(0);
    setShowEdit(false);
  };

  useEffect(() => {
    let mounted = true;

    (async () => {
      setLoading(true);

      const resp = await api.get(
        `${url}?page=${page}&search=${search}&limit=${limit}&status=${selectedStatus}`
      );

      if (!mounted) return;

      const response = resp.data.data.map((d) => {
        return {
          ...d,
          trashed: d.deleted_at !== null ? true : false,
        };
      });

      setData(response);

      setLastPage(resp.data.last_page);

      setShowTotal({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        per_page: resp.data.per_page,
        totalPages: resp.data.last_page,
      });

      const totalPages = resp.data.last_page;

      let maxLeft = page - Math.floor(maxVisibleButtons / 2);
      let maxRight = page + Math.floor(maxVisibleButtons / 2);

      if (maxLeft <= 1) {
        maxLeft = 1;
        maxRight = maxVisibleButtons;
      }

      if (maxRight > totalPages) {
        maxLeft = totalPages - (maxVisibleButtons - 1);
        maxRight = totalPages;
      }

      const arrayPages = [];

      for (let page = maxLeft; page <= maxRight; page++) {
        arrayPages.push(page);
      }

      const filteredArrayPages = arrayPages.filter((nr) => nr > 0);

      setPages(filteredArrayPages);

      page === 1 ? setIsFirstPage(true) : setIsFirstPage(false);

      page === lastPage || lastPage === null
        ? setIsLastPage(true)
        : setIsLastPage(false);

      setLoading(false);
    })();
    return () => {
      mounted = false;
    };
  }, [page, url, lastPage, limit, maxVisibleButtons, search, selectedStatus]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      const { data } = await api.get(
        `/api/collection-missionary-contribuitions/missionary-collections/value-to-receipt/${id}`
      );

      if (!mounted) return;

      setTotalValue(data);

      setLoadingValue(false);
    })();
    return () => {
      mounted = false;
    };
  }, [id]);

  function nextPage() {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  const updateData = useCallback(
    (dataId, options) => {
      setData(
        data.map((d) => {
          if (d.id === dataId) {
            d = {
              ...d,
              ...options,
            };
          }
          return d;
        })
      );
    },
    [data]
  );

  async function verify(contribuitionId, dioceseId) {
    setVerifyLoading(true);

    try {
      await api.put(
        `/api/collection-missionary-contribuitions/verify/${contribuitionId}`
      );

      const responseContribs = await api.get(
        `/api/collection-missionary-contribuitions/${id}?dioceseId=${dioceseId}`,
        { headers: { "Content-Type": "application/json" } }
      );

      setContribView(responseContribs.data);

      setVerifyLoading(false);

      setLoadingValue(true);

      toast.success("Verificado com sucesso!");

      const { data } = await api.get(
        `/api/collection-missionary-contribuitions/missionary-collections/value-to-receipt/${id}`
      );

      setTotalValue(data);

      setLoadingValue(false);
    } catch (error) {
      setVerifyLoading(false);
      setLoadingValue(false);
      toast.error("Ops! Verifique e tente novamente");
    }
  }

  const handleLimit = useCallback((value) => {
    setLimit(value);
    setPage(1);
  }, []);

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();

      setPage(1);

      setSearch(dataToSearch.trim());
    },
    [dataToSearch]
  );

  const handleClearSearch = useCallback(() => {
    setSelectedStatus("");
    setSearch("");
    setDataToSearch("");
  }, []);

  const handleDownload = useCallback((url, name) => {
    if (!url) return;

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}`); //or any other extension
    link.setAttribute("target", "_blank"); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const handleDelete = useCallback(
    async (contribId) => {
      setLoading(true);
      try {
        await api.delete(
          `/api/collection-missionary-contribuitions/${contribId}`
        );

        const resp = await api.get(
          `${url}?page=${page}&search=${search}&limit=${limit}&status=${selectedStatus}`
        );

        const response = resp.data.data.map((d) => {
          return {
            ...d,
            trashed: d.deleted_at !== null ? true : false,
          };
        });

        setData(response);

        setLastPage(resp.data.last_page);

        setShowTotal({
          total: resp.data.total,
          from: resp.data.from,
          to: resp.data.to,
          per_page: resp.data.per_page,
          totalPages: resp.data.last_page,
        });

        const totalPages = resp.data.last_page;

        let maxLeft = page - Math.floor(maxVisibleButtons / 2);
        let maxRight = page + Math.floor(maxVisibleButtons / 2);

        if (maxLeft <= 1) {
          maxLeft = 1;
          maxRight = maxVisibleButtons;
        }

        if (maxRight > totalPages) {
          maxLeft = totalPages - (maxVisibleButtons - 1);
          maxRight = totalPages;
        }

        const arrayPages = [];

        for (let page = maxLeft; page <= maxRight; page++) {
          arrayPages.push(page);
        }

        const filteredArrayPages = arrayPages.filter((nr) => nr > 0);

        setPages(filteredArrayPages);

        page === 1 ? setIsFirstPage(true) : setIsFirstPage(false);

        page === lastPage || lastPage === null
          ? setIsLastPage(true)
          : setIsLastPage(false);

        const responseValue = await api.get(
          `/api/collection-missionary-contribuitions/missionary-collections/value-to-receipt/${id}`
        );

        setTotalValue(responseValue.data);

        setContribView(
          contribView.filter((contrib) => contrib.id !== contribId)
        );

        toast.success("Contribuição deletada com sucesso");

        setLoading(false);
      } catch (error) {
        toast.error("Não foi possível remover");
        setLoading(false);
      }
    },
    [
      contribView,
      id,
      lastPage,
      limit,
      maxVisibleButtons,
      page,
      search,
      selectedStatus,
      url,
    ]
  );

  const handleAddToView = useCallback(
    async (dioceseId) => {
      setLoadingView(true);

      try {
        const {
          data,
        } = await api.get(
          `/api/collection-missionary-contribuitions/${id}?dioceseId=${dioceseId}`,
          { headers: { "Content-Type": "application/json" } }
        );

        setContribView(data);

        setLoadingView(false);

        setShow(true);
      } catch (error) {
        setContribView({});

        setLoadingView(false);

        setShow(false);
      }
    },
    [id]
  );

  const handleUpdate = useCallback(
    async (solicitationId, dioceseId) => {
      setLoadingView(true);

      try {
        await api.put(
          `/api/collection-missionary-contribuitions/${solicitationId}`,
          { value: Number(contribValue) }
        );

        const {
          data,
        } = await api.get(
          `/api/collection-missionary-contribuitions/${id}?dioceseId=${dioceseId}`,
          { headers: { "Content-Type": "application/json" } }
        );

        setContribView(data);

        setContribValue(data.value);

        updateData(solicitationId, {
          value: contribValue,
        });

        setShowEdit(false);
        setLoadingView(false);
      } catch (error) {
        setContribView({});
        setContribValue(0);
        setLoadingView(false);
      }
    },
    [contribValue, id, updateData]
  );

  const handleDownloadLaunchesReport = useCallback(async () => {
    await api
      .get(`/api/collection-missionary-contribuitions/report/launches/${id}`, {
        responseType: "blob",
      })
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );
      });
  }, [id]);

  return (
    <>
      <div className="card-deck">
        {totalValue.map((data) => (
          <CardMoney
            key={data.status}
            loading={loadingValue}
            classCss={data.css_class}
            value={data.value}
            title={data.title}
          />
        ))}
      </div>

      <hr />

      <h2 className="text-center mb-5">Lista de Contribuições</h2>

      <Form inline onSubmit={handleSearch}>
        <FormControl
          type="text"
          size="sm"
          placeholder="Buscar por (Nome da Diocese)"
          className="mr-2"
          style={{ width: "50%" }}
          onChange={(e) => setDataToSearch(e.target.value)}
          value={dataToSearch}
        />

        <Form.Control
          as="select"
          size="sm"
          className="mr-2"
          custom
          defaultValue={status[0]}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          {status.map((item, index) => (
            <option value={item.value} key={index}>
              {item.label}
            </option>
          ))}
        </Form.Control>

        <Button
          size="sm"
          variant="primary"
          className="mr-2"
          onClick={handleSearch}
          disabled={!!!dataToSearch}
        >
          <FaSearch />
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          className="mr-2"
          onClick={handleClearSearch}
          disabled={!!!search}
        >
          <FaTimes />
        </Button>

        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-exportCollectLaunches`}>
              Exportar para Excel
            </Tooltip>
          }
        >
          <Button
            size="sm"
            variant="success"
            onClick={handleDownloadLaunchesReport}
          >
            <FaFileExcel />
          </Button>
        </OverlayTrigger>
      </Form>

      <Table striped bordered responsive hover className="text-center mt-4">
        <thead>
          <tr>
            <th>Diocese</th>
            <th>Valor(es) Somado(s)</th>
            <th>Qtde</th>
            <th>Status Último</th>
            <th className="col-1">
              {verifyLoading ? "Salvando ..." : "Ações"}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="10" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {data.map((m, index) => (
            <tr key={m.id}>
              <td>{m.diocese_name}</td>

              <td>
                <strong>{formatCurrency(m.sum_value)}</strong>
              </td>

              <td>{m.count_value} Envio(os)</td>

              <td>
                {m.verified ? (
                  <span className="bg-success text-white p-1">Verificado</span>
                ) : (
                  <span className="bg-danger text-white p-1">
                    Não Verificado
                  </span>
                )}
              </td>
              <td>
                <ButtonGroup aria-label="Basic example">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-view-${index}`}>Visualizar</Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleAddToView(m.diocese_id)}
                    >
                      <FaEye />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Container fluid className="p-0">
        <Row>
          <Col>
            <small className="mt-0">
              Mostrando <strong>{showTotal.from} </strong> até
              <strong> {showTotal.to}</strong> de{" "}
              <strong> {showTotal.total} </strong>
              Registros
            </small>
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={12}>
            <Form.Group controlId="showTotalPerPage">
              <Form.Control
                as="select"
                custom
                onChange={(e) => handleLimit(e.target.value)}
                disabled={
                  !!selectTotalShow.every((total) => total >= showTotal.total)
                }
                defaultValue={limit}
              >
                {selectTotalShow.map((total, index) => (
                  <option
                    value={total}
                    key={index}
                    disabled={total >= showTotal.total}
                  >
                    {total}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={10} sm={12}>
            <div className="d-flex justify-content-end">
              <Pagination>
                <Pagination.First
                  onClick={() => setPage(1)}
                  disabled={isFirstPage}
                />
                <Pagination.Prev onClick={prevPage} disabled={isFirstPage} />

                {pages[0] > 1 && <Pagination.Ellipsis disabled />}

                {pages.map((p, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(p)}
                    active={page === p}
                  >
                    {p}
                  </Pagination.Item>
                ))}

                {pages[maxVisibleButtons - 1] < showTotal.totalPages && (
                  <Pagination.Ellipsis disabled />
                )}

                <Pagination.Next onClick={nextPage} disabled={isLastPage} />
                <Pagination.Last
                  onClick={() => setPage(lastPage)}
                  disabled={isLastPage}
                />
              </Pagination>
            </div>
          </Col>
        </Row>

        {!isEmptyObject(contribView) && (
          <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Visualizar Contribuição</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                {loadingView ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  contribView.map((contribView, index) => (
                    <div key={index}>
                      <h3>Solicitação {index + 1}</h3>
                      <Row>
                        <Col>
                          <h6>
                            <strong>Diocese: </strong>
                            {contribView.dioceses.name}
                          </h6>
                        </Col>
                        <Col>
                          <strong>Nome do Solicitatante: </strong>
                          <h6>{contribView.sended_receipt_person_name}</h6>
                        </Col>
                        <Col>
                          <strong>E-mail do Solicitatante: </strong>
                          <h6>{contribView.sended_receipt_person_email}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>
                            <strong>Valor: </strong>

                            {showEdit ? (
                              <>
                                <FormControl
                                  value={contribValue}
                                  onChange={(e) =>
                                    setContribValue(e.target.value)
                                  }
                                  type="number"
                                  size="sm"
                                />
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    handleUpdate(
                                      contribView.id,
                                      contribView.diocese_id
                                    )
                                  }
                                >
                                  Salvar
                                </Button>
                              </>
                            ) : (
                              <span className="bg-success text-white p-1">
                                {formatCurrency(contribView.value)}
                              </span>
                            )}

                            {!contribView.verified &&
                              (!showEdit ? (
                                <Button
                                  size="sm"
                                  className="ml-1"
                                  onClick={() => {
                                    setShowEdit(!showEdit);
                                    setContribValue(contribView.value);
                                  }}
                                >
                                  Editar
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  className="ml-1"
                                  onClick={() => {
                                    setShowEdit(false);
                                    setContribValue(0);
                                  }}
                                  variant="danger"
                                >
                                  Cancelar
                                </Button>
                              ))}
                          </h6>
                        </Col>
                        <Col>
                          <h6>
                            <strong>Status: </strong>
                            {contribView.verified ? (
                              <span className="bg-success text-white p-1">
                                Verificado
                              </span>
                            ) : (
                              <span className="bg-danger text-white p-1">
                                Não Verificado
                              </span>
                            )}
                          </h6>
                        </Col>
                        <Col>
                          <h6>
                            <strong>Criado em: </strong>
                            <span>
                              {new Intl.DateTimeFormat("pt-BR").format(
                                new Date(contribView.created_at)
                              )}
                            </span>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>
                            <strong>Conta Bancária: </strong>
                            <span>
                              {`Ag: ${contribView.bank_accounts.agency} 
          | CC: ${contribView.bank_accounts.account_number} 
          | Banco: ${contribView.bank_accounts.bank_name} - ${contribView.bank_accounts.bank_number}`}
                            </span>
                          </h6>
                        </Col>
                        <Col>
                          <h6>
                            <strong>Tipo de Crédito: </strong>
                            <span>{contribView.credit_types.description}</span>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>
                            <strong>Recibo de Crédito: </strong>
                          </h6>
                          <Zoom>
                            <Image
                              src={contribView.url}
                              alt={contribView.receipt_filename}
                              rounded
                              height="50"
                              width="50"
                            />
                          </Zoom>
                        </Col>

                        {contribView.verified ? (
                          <>
                            {!isEmptyObject(contribView.letter) && (
                              <Col>
                                <h6>
                                  <strong>Carta de Agradecimento: </strong>
                                </h6>
                                <a
                                  href={contribView.letter.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {contribView.letter.filename}
                                </a>
                              </Col>
                            )}

                            {!isEmptyObject(contribView.receipt) && (
                              <Col>
                                <h6>
                                  <strong>Recibo de Contribuição: </strong>
                                </h6>
                                <a
                                  href={contribView.receipt.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {contribView.receipt.filename}
                                </a>
                              </Col>
                            )}
                          </>
                        ) : null}
                      </Row>
                      <Row>
                        <Col>
                          <ButtonGroup aria-label="Basic example">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-verify-${index}`}>
                                  Verificar
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="success"
                                size="sm"
                                onClick={() =>
                                  verify(contribView.id, contribView.diocese_id)
                                }
                                disabled={contribView.verified || finished}
                              >
                                <FaCheckCircle />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id={`tooltip-dowload-receipt-${index}`}
                                >
                                  Baixar Recibo
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={() =>
                                  handleDownload(
                                    contribView.receipt.url,
                                    contribView.receipt.filename
                                  )
                                }
                                disabled={!contribView.verified}
                              >
                                <FaFileInvoice />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-dowload-letter-${index}`}>
                                  Baixar Carta
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() =>
                                  handleDownload(
                                    contribView.letter.url,
                                    contribView.letter.filename
                                  )
                                }
                                disabled={!contribView.verified}
                              >
                                <FaEnvelope />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-delete-receipt-${index}`}>
                                  Remover
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleDelete(contribView.id)}
                                disabled={finished}
                              >
                                <FaTimes />
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))
                )}
              </Container>
            </Modal.Body>
          </Modal>
        )}
      </Container>
    </>
  );
}

export default MissionaryCollectContribTable;
