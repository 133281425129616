import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Spinner,
  Button,
  Container,
  Row,
  Col,
  Pagination,
  Form,
  FormControl,
  ButtonGroup,
} from "react-bootstrap";

import api from "../../services/api";
import formatCurrency from "../../utils/formatCurrency";
import { Link, useHistory } from "react-router-dom";
import { FaEye, FaFileAlt, FaPlus, FaSearch, FaTimes, FaTrash } from "react-icons/fa";

import { formatDate } from "../../utils/formatDate";
import { toast } from "react-toastify";

function DonationTable({ url }) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [dataToSearch, setDataToSearch] = useState("");
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  const [showTotal, setShowTotal] = useState({
    total: 0,
    from: 0,
    to: 0,
    per_page: 10,
    totalPages: 0,
  });

  const [pages, setPages] = useState([]);
  const [limit, setLimit] = useState(10);
  const [selectTotalShow] = useState([5, 10, 15, 20, 30]);
  const [maxVisibleButtons] = useState(8);

  const fields = [
    { value: "title", description: "Título" },
    { value: "total_value", description: "Valor Total" },
  ];

  const status = [
    { value: "finished", description: "Finalizadas" },
    { value: "unfinished", description: "Não Finalizadas" },
  ];

  const [year, setYear] = useState(new Date().getFullYear());
  const [currentField, setCurrentField] = useState(fields[0].value);
  const [currentStatus, setCurrentStatus] = useState(null);

  const [loadingFinished, setLoadingFinished] = useState(false);


  async function getData() {
    setLoading(true);

    await api
      .get(
        `${url}?page=${page}&search=${search}&limit=${limit}&year=${year}&field=${currentField}&status=${currentStatus}`
      )
      .then((resp) => {
        const response = resp.data.data.map((d) => {
          return {
            ...d,
            trashed: d.deleted_at !== null ? true : false,
          };
        });

        setData(response);

        setLastPage(resp.data.last_page);

        setShowTotal({
          total: resp.data.total,
          from: resp.data.from,
          to: resp.data.to,
          per_page: resp.data.per_page,
          totalPages: resp.data.last_page,
        });

        const totalPages = resp.data.last_page;

        let maxLeft = page - Math.floor(maxVisibleButtons / 2);
        let maxRight = page + Math.floor(maxVisibleButtons / 2);

        if (maxLeft <= 1) {
          maxLeft = 1;
          maxRight = maxVisibleButtons;
        }

        if (maxRight > totalPages) {
          maxLeft = totalPages - (maxVisibleButtons - 1);
          maxRight = totalPages;
        }

        const arrayPages = [];

        for (let page = maxLeft; page <= maxRight; page++) {
          arrayPages.push(page);
        }

        const filteredArrayPages = arrayPages.filter((nr) => nr > 0);

        setPages(filteredArrayPages);

        page === 1 ? setIsFirstPage(true) : setIsFirstPage(false);

        page === lastPage || lastPage === null
          ? setIsLastPage(true)
          : setIsLastPage(false);

        setLoading(false);
      });
  }

  useEffect(() => {
    (async () => {
      getData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    url,
    lastPage,
    search,
    limit,
    maxVisibleButtons,
    year,
    currentField,
    currentStatus,
  ]);

  const handleSearch = useCallback(() => {
    setPage(1);

    setSearch(dataToSearch.trim());
  }, [dataToSearch]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    setDataToSearch("");
  }, []);

  const nextPage = useCallback(() => {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }, [lastPage, page]);

  const prevPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const handleLimit = useCallback((value) => {
    setLimit(value);
    setPage(1);
  }, []);

  function handleGo(id) {
    history.push(`/donation-view/${id}`);
  }

  const handleDelete = useCallback(async (id) => {
    setLoading(true);
    await api
      .delete(`/api/donation/${id}`)
      .then((resp) => {
        getData();
        toast.success("✅ Doação excluída!");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("❌ Não foi possível excluir a doação!");
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleFineshedDonation(donationId) {
    setLoadingFinished(true);
    await api
      .post(`/api/donation/secondary/${donationId}`)
      .then((resp) => {
        toast.info("O recibo foi enviado para o(s) e-mail(s) cadastrados!");
        setLoadingFinished(false);
      });
  }

  return (
    <>
      <div className="text-center mb-4">
        <h1>Doações Cofrinho</h1>
      </div>

      <Link to="/donation-create" className="btn btn-sm btn-primary mb-4">
        <FaPlus className="mr-1" />
        Nova Doação
      </Link>

      <Form inline>
        <FormControl
          type="text"
          size="sm"
          placeholder="Buscar por ..."
          className="mr-2"
          style={{ width: "50%" }}
          onChange={(e) => setDataToSearch(e.target.value)}
          value={dataToSearch}
        />
        <Form.Control
          as="select"
          size="sm"
          custom
          onChange={(e) => setCurrentField(e.target.value)}
          defaultValue={limit}
        >
          {fields.map((field, index) => (
            <option value={field.value} key={field.value}>
              {field.description}
            </option>
          ))}
        </Form.Control>
        <FormControl
          type="number"
          size="sm"
          className="mr-2 ml-2"
          onChange={(e) => setYear(e.target.value)}
          value={year}
          min={1900}
          max={2099}
          step={1}
        />
        <Form.Control
          as="select"
          size="sm"
          custom
          onChange={(e) => setCurrentStatus(e.target.value)}
          defaultValue={currentStatus}
        >
          {status.map((field, index) => (
            <option value={field.value} key={field.value}>
              {field.description}
            </option>
          ))}
        </Form.Control>
        <Button
          size="sm"
          variant="primary"
          className="mr-2 ml-2"
          onClick={handleSearch}
          disabled={!!!dataToSearch}
        >
          <FaSearch />
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={handleClearSearch}
          disabled={!!!search}
        >
          <FaTimes />
        </Button>
      </Form>

      <small className="text-muted lh-1">*Buscar pelo título ou valor</small>

      <Table striped bordered responsive hover className="text-center">
        <thead>
          <tr>
            <th>Título</th>
            <th>Valor Total</th>
            <th>Finalizada</th>
            <th>Data de Criação</th>
            <th>Visualizar</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="5" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {data.map((d) => (
            <tr key={d.id}>
              <td>{d.title}</td>
              <td>
                {d.total_value
                  ? formatCurrency(parseFloat(d.total_value))
                  : "-"}
              </td>
              <td>
                {d.finished ? (
                  <div className="bg-success text-white">Finalizado</div>
                ) : (
                  <div className="bg-danger text-white">Não finalizado</div>
                )}
              </td>
              <td>{formatDate(d.created_at)}</td>
              <td>
                <ButtonGroup aria-label="Basic example">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      handleFineshedDonation(d.id);
                    }}
                  >
                    <FaFileAlt />
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleGo(d.id)}
                  >
                    <FaEye />
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      handleDelete(d.id);
                    }}
                  >
                    <FaTrash />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Container fluid className="p-0">
        <Row>
          <Col>
            <small className="mt-0">
              Mostrando <strong>{showTotal.from} </strong> até
              <strong> {showTotal.to}</strong> de{" "}
              <strong> {showTotal.total} </strong>
              Registros
            </small>
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={12}>
            <Form.Group controlId="showTotalPerPage">
              <Form.Control
                as="select"
                custom
                onChange={(e) => handleLimit(e.target.value)}
                disabled={
                  !!selectTotalShow.every((total) => total >= showTotal.total)
                }
                defaultValue={limit}
              >
                {selectTotalShow.map((total, index) => (
                  <option
                    value={total}
                    key={index}
                    disabled={total >= showTotal.total}
                  >
                    {total}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={10} sm={12}>
            <div className="d-flex justify-content-end">
              <Pagination>
                <Pagination.First
                  onClick={() => setPage(1)}
                  disabled={isFirstPage}
                />
                <Pagination.Prev onClick={prevPage} disabled={isFirstPage} />

                {pages[0] > 1 && <Pagination.Ellipsis disabled />}

                {pages.map((p, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(p)}
                    active={page === p}
                  >
                    {p}
                  </Pagination.Item>
                ))}

                {pages[maxVisibleButtons - 1] < showTotal.totalPages && (
                  <Pagination.Ellipsis disabled />
                )}

                <Pagination.Next onClick={nextPage} disabled={isLastPage} />
                <Pagination.Last
                  onClick={() => setPage(lastPage)}
                  disabled={isLastPage}
                />
              </Pagination>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DonationTable;
