import PublicHeader from "../../../components/PublicHeader";

function PublicContent({ children }) {
  return (
    <>
      <PublicHeader>{children}</PublicHeader>
    </>
  );
}

export default PublicContent;
