import React, { useState, useEffect, useCallback } from "react";

import { Link, useHistory } from "react-router-dom";
import {
  FaEye,
  FaPlus,
  FaSearch,
  FaTimes,
  FaTrash,
  FaTrashRestore,
} from "react-icons/fa";
import { toast } from "react-toastify";

import api from "../../services/api";

import Loading from "../Loading";
import {
  Button,
  Spinner,
  Table,
  Badge,
  Form,
  FormControl,
  ButtonGroup,
  Container,
  Row,
  Col,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

function DioceseTable({ url }) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [dataToSearch, setDataToSearch] = useState("");
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  const [actionLoading, setActionLoading] = useState(false);

  const [showTotal, setShowTotal] = useState({
    total: 0,
    from: 0,
    to: 0,
    per_page: 10,
    totalPages: 0,
  });

  const [pages, setPages] = useState([]);
  const [limit, setLimit] = useState(10);
  const [selectTotalShow] = useState([5, 10, 15, 20, 30]);
  const [maxVisibleButtons] = useState(8);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await api
        .get(`${url}?page=${page}&search=${search}&limit=${limit}`)
        .then((resp) => {
          const response = resp.data.data.map((d) => {
            return {
              ...d,
              trashed: d.deleted_at !== null ? true : false,
            };
          });

          setData(response);

          setLastPage(resp.data.last_page);

          setShowTotal({
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
            per_page: resp.data.per_page,
            totalPages: resp.data.last_page,
          });

          const totalPages = resp.data.last_page;

          let maxLeft = page - Math.floor(maxVisibleButtons / 2);
          let maxRight = page + Math.floor(maxVisibleButtons / 2);

          if (maxLeft <= 1) {
            maxLeft = 1;
            maxRight = maxVisibleButtons;
          }

          if (maxRight > totalPages) {
            maxLeft = totalPages - (maxVisibleButtons - 1);
            maxRight = totalPages;
          }

          const arrayPages = [];

          for (let page = maxLeft; page <= maxRight; page++) {
            arrayPages.push(page);
          }

          const filteredArrayPages = arrayPages.filter((nr) => nr > 0);

          setPages(filteredArrayPages);

          page === 1 ? setIsFirstPage(true) : setIsFirstPage(false);

          page === lastPage || lastPage === null
            ? setIsLastPage(true)
            : setIsLastPage(false);

          setLoading(false);
        });
    })();
  }, [page, url, lastPage, search, limit, maxVisibleButtons]);

  const handleSearch = useCallback(() => {
    setPage(1);

    setSearch(dataToSearch.trim());
  }, [dataToSearch]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    setDataToSearch("");
  }, []);

  const nextPage = useCallback(() => {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }, [lastPage, page]);

  const prevPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const updateData = useCallback(
    (dataId, options) => {
      setData(
        data.map((d) => {
          if (d.id === dataId) {
            d = {
              ...d,
              ...options,
            };
          }
          return d;
        })
      );
    },
    [data]
  );

  const handleRestore = useCallback(
    async (id) => {
      setActionLoading(true);
      await api
        .put(`/api/diocese/restore/${id}`)
        .then((resp) => {
          updateData(id, {
            trashed: false,
          });
          toast.success("✅ Diocese restaurada com sucesso!");
          setActionLoading(false);
        })
        .catch((error) => {
          toast.error("❌ Não foi possível restaurar a diocese!");
          setActionLoading(false);
        });
    },
    [updateData]
  );

  const handleDelete = useCallback(
    async (id) => {
      setActionLoading(true);
      await api
        .delete(`/api/diocese/${id}`)
        .then((resp) => {
          updateData(id, {
            trashed: true,
          });
          toast.success("✅ Diocese movida para a lixeira!");
          setActionLoading(false);
        })
        .catch((error) => {
          toast.error("❌ Não foi possível mover a diocese para a Lixeira!");
          setActionLoading(false);
        });
    },
    [updateData]
  );

  const handleLimit = useCallback((value) => {
    setLimit(value);
    setPage(1);
  }, []);

  if (actionLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="text-center mb-4">
        <h1>Dioceses</h1>
      </div>

      <Link
        to="/diocese-create"
        className="btn btn-sm text-white btn-primary mb-3"
      >
        <FaPlus className="mr-2" />
        Nova Diocese
      </Link>

      <div className="d-flex flex-column bd-highlight mb-3">
        <div className="bd-highlight">
          <small className="text-muted lh-1">
            *Dioceses com
            <Badge variant="warning" className="mr-1 ml-1">
              lixeira
            </Badge>
            estão na lixeira, mas podem ser restaurados
          </small>
        </div>
      </div>

      <Form inline>
        <FormControl
          type="text"
          size="sm"
          placeholder="Buscar por ..."
          className="mr-2"
          style={{ width: "50%" }}
          onChange={(e) => setDataToSearch(e.target.value)}
          value={dataToSearch}
        />
        <Button
          size="sm"
          variant="primary"
          className="mr-2"
          onClick={handleSearch}
          disabled={!!!dataToSearch}
        >
          <FaSearch />
        </Button>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={handleClearSearch}
          disabled={!!!search}
        >
          <FaTimes />
        </Button>
      </Form>

      <small className="text-muted lh-1">*Buscar pelo nome ou cidade</small>

      <Table striped bordered responsive hover className="text-center mt-2">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Bispo</th>
            <th>Cidade</th>
            <th>Estado</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="5" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {data.map((dio, index) => (
            <tr key={index}>
              <td>
                {dio.name}
                {dio.trashed && (
                  <Badge variant="warning" className="mr-1 ml-1">
                    lixeira
                  </Badge>
                )}
              </td>
              <td>{dio.bishop}</td>
              <td>{dio.city}</td>
              <td>{dio.uf}</td>

              <td>
                <ButtonGroup aria-label="Basic example">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-view-${index}`}>Visualizar</Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => history.push(`/diocese-view/${dio.id}`)}
                    >
                      <FaEye />
                    </Button>
                  </OverlayTrigger>

                  {dio.trashed ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-restore-${index}`}>
                          Restaurar
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleRestore(dio.id)}
                      >
                        <FaTrashRestore />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-delete-${index}`}>
                          Deletar
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(dio.id)}
                      >
                        <FaTrash />
                      </Button>
                    </OverlayTrigger>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Container fluid className="p-0">
        <Row>
          <Col>
            <small className="mt-0">
              Mostrando <strong>{showTotal.from} </strong> até
              <strong> {showTotal.to}</strong> de{" "}
              <strong> {showTotal.total} </strong>
              Registros
            </small>
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={12}>
            <Form.Group controlId="showTotalPerPage">
              <Form.Control
                as="select"
                custom
                onChange={(e) => handleLimit(e.target.value)}
                disabled={
                  !!selectTotalShow.every((total) => total >= showTotal.total)
                }
                defaultValue={limit}
              >
                {selectTotalShow.map((total, index) => (
                  <option
                    value={total}
                    key={index}
                    disabled={total >= showTotal.total}
                  >
                    {total}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={10} sm={12}>
            <div className="d-flex justify-content-end">
              <Pagination>
                <Pagination.First
                  onClick={() => setPage(1)}
                  disabled={isFirstPage}
                />
                <Pagination.Prev onClick={prevPage} disabled={isFirstPage} />

                {pages[0] > 1 && <Pagination.Ellipsis disabled />}

                {pages.map((p, index) => (
                  <Pagination.Item
                    key={index}
                    onClick={() => setPage(p)}
                    active={page === p}
                  >
                    {p}
                  </Pagination.Item>
                ))}

                {pages[maxVisibleButtons - 1] < showTotal.totalPages && (
                  <Pagination.Ellipsis disabled />
                )}

                <Pagination.Next onClick={nextPage} disabled={isLastPage} />
                <Pagination.Last
                  onClick={() => setPage(lastPage)}
                  disabled={isLastPage}
                />
              </Pagination>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DioceseTable;
