import { useCallback, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { toast } from "react-toastify";

import GoBackButton from "../../components/GoBackButton";
import Loading from "../../components/Loading";
import api from "../../services/api";

export function MissionaryCollectionReport() {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [reports] = useState([
    { type: "general", name: "Relatório Geral", disabled: false },
  ]);

  const handleDownload = useCallback(async (type) => {
    setDownloadLoading(true);
    await api
      .get(`/api/collection-missionary-contribuitions/report/general`, {
        responseType: "blob",
      })
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloadLoading(false);

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );

        setDownloadLoading(false);

        console.log(errors);
      });
  }, []);

  if (downloadLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <GoBackButton color="primary" isButton={false} />
      <div className="text-center">
        <h1>Relatórios Coleta Missionária</h1>
      </div>

      <Row className="mt-5">
        <Table striped bordered hover responsive className="text-center mt-2">
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((item, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                <td style={{ verticalAlign: "middle", fontSize: "1.2rem" }}>
                  <strong>
                    <u>{item.name}</u>
                  </strong>
                </td>

                <td style={{ verticalAlign: "middle" }}>
                  <ButtonGroup aria-label={`Action General ${index}`}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-excel-report-${index}`}>
                          Clique para baixar
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          handleDownload(item.type);
                        }}
                        disabled={item.disabled}
                      >
                        <FaDownload size={16} className="mr-2" />
                        Baixar
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}
