import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Col, Spinner, Container } from "react-bootstrap";

import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import axios from "axios";

import { removeEspecialCharacters } from "../../utils/removeMask";
import { FiSearch } from "react-icons/fi";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import api from "../../services/api";

function SafeBoxCreate() {
  const history = useHistory();

  const [groupName, setGroupName] = useState("");
  const [representantName, setRepresentantName] = useState("");
  const [representantEmail, setRepresentantEmail] = useState("");
  const [representantTel, setRepresentantTel] = useState("");
  const [groupCity, setGroupCity] = useState("");
  const [groupUf, setGroupUf] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [parishId, setParishId] = useState("");
  const [dioceseId, setDioceseId] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);

  //search states
  const [searchLoading, setSearchLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [parishToSearch, setParishToSearch] = useState("");
  const [searchedParish, setSearchedParish] = useState({});

  async function searchAddress() {
    if (!zipCode) {
      toast.warning("‼ Informe um CEP!");
      return;
    }

    await axios
      .get(`https://viacep.com.br/ws/${zipCode}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          toast.error("❌ CEP inválido!");
          setGroupCity("");
          setGroupUf("");
          return;
        }
        toast.success("✅ CEP válido!");
        setGroupCity(resp.data.localidade);
        setGroupUf(resp.data.uf);
      })
      .catch((error) => {
        toast.error("❌ CEP inválido!");
        setGroupCity("");
        setGroupUf("");
      });
  }

  async function searchParish() {
    setSearchLoading(true);

    await api
      .get(`/api/parish/search?query=${parishToSearch}`)
      .then((resp) => {
        const response = resp.data.map((p) => ({
          ...p,
          diocese_name: p.dioceses.name,
        }));
        setSearchedParish(response);
        setSearchLoading(false);
        setShowTable(true);
      })
      .catch((error) => {
        setParishToSearch("");
        setSearchLoading(false);
        setShowTable(true);
      });
  }

  const selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#bababa",
    columnWidth: "60px",
    onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    setParishId(row.id);
    setDioceseId(row.diocese_id);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSaveLoading(true);

    await api
      .post("/api/safe_box_groups", {
        group_name: groupName,
        representant_name: representantName,
        contact_mail: representantEmail,
        telephone: removeEspecialCharacters(representantTel),
        city: groupCity,
        uf: groupUf,
        diocese_id: dioceseId,
        parish_id: parishId,
      })
      .then((resp) => {
        toast.success("Grupo criado com sucesso!");
        setSaveLoading(false);
        history.push(`/safe-box-view/${resp.data.id}`);
      })
      .catch((error) => {
        toast.error("Não foi possível criar o grupo, verifique os dados");
        setSaveLoading(false);
      });
  }

  return (
    <Container>
      <h1>Novo Grupo</h1>
      <Link to="/safe-box">Voltar</Link>

      <h3 className="mt-4">Dados</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group controlId="groupName" as={Col}>
            <Form.Label>Nome do Grupo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome do Grupo"
              required
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
            />
          </Form.Group>

          <Form.Group controlId="representantName" as={Col}>
            <Form.Label>Nome do Representante</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome do Representante"
              required
              onChange={(e) => setRepresentantName(e.target.value)}
              value={representantName}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group controlId="representantEmail" as={Col}>
            <Form.Label>E-mail do Representante</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mail do Representante"
              required
              onChange={(e) => setRepresentantEmail(e.target.value)}
              value={representantEmail}
            />
            <Form.Text>
              * Qualquer contato ou recibo será enviado para esse e-mail.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="representantTel" as={Col}>
            <Form.Label>Telefone do Representante</Form.Label>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Ex: (99) 99999-9999"
              type="tel"
              className="form-control"
              name="telephone"
              id="telephone"
              onChange={(e) => setRepresentantTel(e.target.value)}
              value={representantTel}
            />
          </Form.Group>
        </Form.Row>

        <div className="mt-3">
          <hr />
        </div>

        <h3 className="">Endereço</h3>

        <Form.Group controlId="representantCep">
          <Form.Label>CEP</Form.Label>
          <InputMask
            mask="99999-999"
            type="text"
            className="form-control w-50"
            name="zipCode"
            id="zipCode"
            required
            onChange={(e) =>
              setZipCode(removeEspecialCharacters(e.target.value))
            }
            value={zipCode}
            onBlur={searchAddress}
          />
          <Form.Text>
            * Informe um CEP para buscarmos a Cidade e o Estado
          </Form.Text>
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="groupCity">
            <Form.Label>Cidade</Form.Label>
            <Form.Control type="text" value={groupCity} readOnly required />
          </Form.Group>

          <Form.Group as={Col} controlId="groupUf">
            <Form.Label>UF</Form.Label>
            <Form.Control type="text" value={groupUf} readOnly required />
          </Form.Group>
        </Form.Row>

        <div className="mt-3">
          <hr />
        </div>

        <h3>Paróquia que o grupo pertence</h3>

        <Form.Row className="align-items-center">
          <Col xs="5" className="my-2">
            <Form.Control
              className="mb-2 mr-sm-2"
              id="parishName"
              placeholder="Busque a Paróquia pelo nome"
              onChange={(e) => setParishToSearch(e.target.value)}
              value={parishToSearch}
            />
          </Col>
          <Col xs="auto" className="my-1">
            <Button className="mb-2" onClick={searchParish}>
              {searchLoading ? (
                <>
                  {" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                <FiSearch />
              )}
            </Button>
          </Col>
        </Form.Row>

        <Form.Row>
          {showTable ? (
            searchLoading ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                <BootstrapTable
                  data={searchedParish}
                  striped
                  hover
                  selectRow={selectRowProp}
                >
                  <TableHeaderColumn dataField="id" isKey hidden>
                    Paróquia ID
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="name">
                    Nome da Paróquia
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="city">Cidade</TableHeaderColumn>
                  <TableHeaderColumn dataField="uf" width="60">
                    UF
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="diocese_id" hidden>
                    Diocese ID
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="diocese_name">
                    Diocese
                  </TableHeaderColumn>
                </BootstrapTable>
              </>
            )
          ) : (
            <small>* Faça a busca por uma Paróquia</small>
          )}
        </Form.Row>

        <Button variant="primary" className="mt-4" size="lg" type="submit">
          {saveLoading ? "Salvando ..." : "Salvar"}
        </Button>
      </Form>
    </Container>
  );
}

export default SafeBoxCreate;
