import React, { useCallback, useState } from "react";

//libs
import * as Yup from "yup";
import { Form, Input } from "@rocketseat/unform";
import { Link, useHistory } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";

//styles
import { Wrapper, Content } from "../_layouts/auth/authStyle";

//assets
import Logo from "../../utils/logo/Logo";
import LogoSeja from "../../assets/img/sejatech.png";
import { SubmitButton } from "../../styles/utils";

import api from "../../services/api";

const schema = Yup.object().shape({
  name: Yup.string().required("*O campo nome completo é obrigatório"),
  email: Yup.string()
    .email("*E-mail inválido")
    .required("*O campo e-mail é obrigatório"),
  password: Yup.string()
    .min(8, "*Senha de no mínimo 8 caracteres")
    .required("*O campo senha é obrigatório"),
});

export default function SignUp() {
  const history = useHistory();


  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async ({ name, email, password }) => {
    setLoading(true);

    await api
      .post("/api/auth/register", {
        name,
        email,
        password,
      })
      .then((resp) => {
        setLoading(false);
        toast.success("✅ Usuário registrado!");
        history.push("/login");
      })
      .catch((err) => {
        toast.error("❌ Não foi possível registrar o usuário!");
        setLoading(false);
      });
  }, [history]);

  function toggleShowPass() {
    setShowPassword(!showPassword);
  }

  return (
    <Wrapper>
      <Content>
        <Logo />
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="name" type="text" placeholder="Nome e sobrenome" />
          <Input
            name="email"
            type="email"
            placeholder="Seu endereço de e-mail"
          />
          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Sua senha secreta"
          />

          <small
            onClick={toggleShowPass}
            style={{ marginTop: 10, textAlign: "left", cursor: "pointer" }}
          >
            {showPassword ? "Esconder" : "Mostrar"} senha
          </small>

          <SubmitButton loading={loading ? 1 : 0}>
            {loading ? <FaSpinner color="#fff" size={14} /> : "Criar conta"}
          </SubmitButton>

          <Link to="/login">Já tenho login</Link>

          <small>
            Desenvolvido por{" "}
            <a
              href="https://sejatech.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={LogoSeja} alt="SejaTech" />
            </a>
          </small>
        </Form>
      </Content>
    </Wrapper>
  );
}
