import React from "react";
import { Container } from "react-bootstrap";
import ParishTable from "../../components/ParishTable";

function Parish() {
  return (
    <Container fluid>
      <ParishTable url="/api/parish" />
    </Container>
  );
}

export default Parish;
