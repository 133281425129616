import { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Jumbotron,
  Row,
  Spinner,
  Table,
  Modal,
  Form,
  Card,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  FaBan,
  FaCalendar,
  FaCheckCircle,
  FaDollarSign,
  FaEdit,
  FaInfoCircle,
  FaPlus,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import SubmitButtonApp from "../../components/SubmitButton";
import api from "../../services/api";
import formatCurrency, { formatValue } from "../../utils/formatCurrency";
import { formatBRDate } from "../../utils/formatDate";
import { formatCnpj } from "../../utils/formatDocument";
import { showMaskaredTelephone } from "../../utils/showTelephoneNumber";
import PrevButton from "../../components/GoBackButton";
import { isEmptyObject } from "../../utils/checkObject";

function ChampaingnSolicitationView() {
  const { id } = useParams();

  const history = useHistory();

  const { register, handleSubmit, reset } = useForm();

  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState([]);

  const [solicitation, setSolicitation] = useState({});

  const [boxes, setBoxes] = useState([]);
  const [boxIndicators, setBoxIndicators] = useState({
    total_count: 0,
    weight_total: 0,
  });

  const [diocese, setDiocese] = useState({});
  const [champaingn, setChampaingn] = useState({});
  const [solicitationProducts, setSolicitationProducts] = useState([]);
  const [total, setTotal] = useState(0.0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showNewBox, setShowNewBox] = useState(false);

  const handleCloseNewBox = () => setShowNewBox(false);
  const handleShowNewBox = () => setShowNewBox(true);

  const [newBoxWeight, setNewBoxWeight] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);

  const [editQuantity, setEditQuantity] = useState(0);
  const [editWeight, setEditWeight] = useState("");

  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      Promise.all([
        await api.get("/api/missionary-campaign/product"),
        await api.get("/api/credit-type"),
        await api.get(`/api/missionary-campaign-solicitation/${id}`),
        await api.get(`/api/bank-account`),
        await api.get(`/api/missionary-campaign/solicitation/box/${id}`),
      ])
        .then((results) => {
          setProducts(results[0].data);

          const filter = {
            ...results[2].data,
            finished: !!results[2].data.finished,
          };

          const filteredProducts = results[2].data.champaingn_solicitation_product.map(
            (item) => {
              return {
                ...item,
                name: item.champaingn_product.name,
                quantity: item.quantity,
                total: item.champaingn_product.price * item.quantity,
                isEdited: false,
                isDeleted: !!item.champaingn_product.deleted_at,
              };
            }
          );

          const reducered = filteredProducts.reduce(
            (prevVal, elem) => prevVal + elem.total,
            0
          );

          let indicators = 0;

          const boxesMapped = results[4].data.map((item) => {
            indicators += item.weight;

            return {
              ...item,
              isEdited: false,
            };
          });

          setSolicitation(filter);
          setDiocese(results[2].data.diocese);
          setChampaingn(results[2].data.missionary_campaign);
          setSolicitationProducts(filteredProducts);
          setBoxes(boxesMapped);
          setBoxIndicators({
            total_count: results[4].data.length,
            weight_total: indicators,
          });
          setTotal(reducered);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            "❌ Não foi possível encontrar a solicitação, você será redirecionado!"
          );
          setTimeout(() => history.goBack(), 1000);
        });
    })();
  }, [history, id]);

  const solicitationProductUpdate = useCallback(
    (dataId, options) => {
      setSolicitationProducts(
        solicitationProducts.map((r) => {
          if (r.id === dataId) {
            r = {
              ...r,
              ...options,
            };
          }
          return r;
        })
      );
    },
    [solicitationProducts]
  );

  const onSubmit = useCallback(
    async ({ champaingn_product_id, quantity }) => {
      setSubmitLoading(true);
      await api
        .post(`/api/missionary-campaign/solicitation-product`, {
          champaingn_solicitation_id: solicitation.id,
          champaingn_product_id,
          quantity,
        })
        .then((resp) => {
          const filteredProducts = {
            ...resp.data,
            name: resp.data.champaingn_product.name,
            quantity: resp.data.quantity,
            total: resp.data.champaingn_product.price * resp.data.quantity,
          };
          // console.log(filteredProducts);
          setSolicitationProducts([...solicitationProducts, filteredProducts]);
          setTotal(total + filteredProducts.total);
          toast.success("✅ Produto adicionado com sucesso!");
          setSubmitLoading(false);
          handleClose();
          reset();
        })
        .catch((error) => {
          setSubmitLoading(false);
        });
    },
    [reset, solicitation.id, solicitationProducts, total]
  );

  const addProductToEditProduct = useCallback(
    async (productId) => {
      setEditQuantity(0);

      const product = solicitationProducts.find(
        (item) => item.id === productId
      );

      solicitationProductUpdate(productId, { isEdited: !product.isEdited });
    },
    [solicitationProductUpdate, solicitationProducts]
  );

  const handleDeleteProduct = useCallback(
    async (productId) => {
      await api
        .delete(`/api/missionary-campaign/solicitation-product/${productId}`)
        .then((resp) => {
          const productFiltered = solicitationProducts.filter(
            (item) => item.id !== productId
          );

          const reducered = productFiltered.reduce(
            (prevVal, elem) => prevVal + elem.total,
            0
          );

          setSolicitationProducts(productFiltered);

          setTotal(reducered);

          toast.success("✅ Material removido com sucesso!");
        })
        .catch((error) => {
          toast.error("❌ Não foi possível remover o material");
        });
    },
    [solicitationProducts]
  );

  const handleEditProduct = useCallback(
    async (productId) => {
      const product = solicitationProducts.find(
        (item) => item.id === productId
      );

      await api
        .put(`/api/missionary-campaign/solicitation-product/${productId}`, {
          quantity: parseInt(editQuantity),
        })
        .then((resp) => {
          solicitationProductUpdate(productId, {
            quantity: resp.data.quantity,
            isEdited: !product.isEdited,
            name: resp.data.champaingn_product.name,
            total: resp.data.champaingn_product.price * resp.data.quantity,
          });

          setTotal(resp.data.total);
          setEditQuantity(0);

          toast.success("✅ Produto alterado com sucesso!");
        });
    },
    [editQuantity, solicitationProductUpdate, solicitationProducts]
  );

  const handleConfirmSolicitation = useCallback(async () => {
    setFinishedLoading(true);

    await api
      .put(
        `/api/missionary-campaign/solicitation/${solicitation.id}?action=finished`
      )
      .then((resp) => {
        setFinishedLoading(false);

        setSolicitation({ ...solicitation, finished: true });

        toast.success("✅ Solicitação finalizada com sucesso!");
      })
      .catch((errors) => {
        setFinishedLoading(false);
      });
  }, [solicitation]);

  const handleUnconfirmSolicitation = useCallback(async () => {
    setFinishedLoading(true);

    await api
      .put(
        `/api/missionary-campaign/solicitation/${solicitation.id}?action=unfinished`
      )
      .then((resp) => {
        setFinishedLoading(false);

        setSolicitation({ ...solicitation, finished: false });

        toast.success(
          "✅ Retirada de confirmação, você pode editar novamente!"
        );
      })
      .catch((errors) => {
        setFinishedLoading(false);
      });
  }, [solicitation]);

  const boxUpdate = useCallback(
    (dataId, options) => {
      setBoxes(
        boxes.map((r) => {
          if (r.id === dataId) {
            r = {
              ...r,
              ...options,
            };
          }
          return r;
        })
      );
    },
    [boxes]
  );

  const handleAddBox = useCallback(
    async (e) => {
      e.preventDefault();

      let formatted = newBoxWeight.replace(",", ".");

      if (isNaN(parseFloat(formatted))) {
        return toast.error("❌ Número não válido");
      }

      const { data } = await api.post(
        "/api/missionary-campaign/solicitation/box",
        {
          weight: parseFloat(formatted),
          champaingn_solicitation_id: id,
        }
      );

      setBoxes([...boxes, { ...data, isEdited: false }]);

      let responseBoxes = [...boxes, data];

      let indicators = 0;

      responseBoxes.map((item) => {
        return (indicators += item.weight);
      });

      setBoxIndicators({
        total_count: responseBoxes.length,
        weight_total: indicators,
      });

      setNewBoxWeight("");

      return toast.success("✅ Caixa adicionada!");
    },
    [boxes, id, newBoxWeight]
  );

  const handleDeleteBox = useCallback(
    async (id) => {
      await api
        .delete(`/api/missionary-campaign/solicitation/box/${id}`)
        .then((resp) => {
          const boxeFiltered = boxes.filter((item) => item.id !== id);

          let indicators = 0;

          boxeFiltered.map((item) => {
            return (indicators += item.weight);
          });

          setBoxes(boxeFiltered);

          setBoxIndicators({
            total_count: boxeFiltered.length,
            weight_total: indicators,
          });

          return toast.success("✅ Caixa removida!");
        })
        .catch((error) => {
          return toast.error("❌ Nao foi possível remover a Caixa!");
        });
    },
    [boxes]
  );

  const addBoxToEdit = useCallback(
    async (boxId) => {
      setEditWeight("");

      const box = boxes.find((item) => item.id === boxId);

      boxUpdate(boxId, { isEdited: !box.isEdited });
    },
    [boxUpdate, boxes]
  );

  const handleEditBox = useCallback(
    async (boxId) => {
      let formatted = editWeight.replace(",", ".");

      if (isNaN(parseFloat(formatted))) {
        return toast.error("❌ Número não válido");
      }

      const box = boxes.find((item) => item.id === boxId);

      await api
        .put(`/api/missionary-campaign/solicitation/box/${boxId}`, {
          weight: parseFloat(formatted),
        })
        .then((resp) => {
          boxUpdate(boxId, {
            ...resp.data,
            isEdited: !box.isEdited,
          });

          setEditWeight("");

          toast.success("✅ Caixa alterada com sucesso!");
        });
    },
    [boxUpdate, boxes, editWeight]
  );

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner variant="primary" animation="border" />
        </div>
      ) : (
        <Container fluid>
          <PrevButton />
          <Jumbotron className="h-100">
            <Row>
              <Col>
                <div className="d-flex flex-column bd-highlight">
                  <div className="bd-highlight">
                    <strong>Nome da Diocese: </strong>
                    {diocese.name && diocese.name}
                  </div>
                  <div className="bd-highlight">
                    <strong>CNPJ: </strong>
                    {diocese.cnpj && formatCnpj(diocese.cnpj)}
                  </div>
                  <div className="bd-highlight">
                    <strong>Bispo: </strong>
                    {diocese.bishop}
                  </div>
                  <div className="bd-highlight">
                    <strong>Localidade: </strong>
                    {diocese.city && diocese.city} - {diocese.uf && diocese.uf}
                  </div>
                </div>

                <div className="d-flex flex-column bd-highlight mt-2">
                  <Row>
                    <Col>
                      <div className="bd-highlight">
                        <strong>Nome do Solicitante: </strong>
                        {solicitation.name}
                      </div>
                      <div className="bd-highlight">
                        <strong>E-mail do Solicitante: </strong>
                        {solicitation.email}
                      </div>
                      <div className="bd-highlight">
                        <strong>Telefone do Solicitante: </strong>
                        {solicitation.telephone &&
                          showMaskaredTelephone(solicitation.telephone)}
                      </div>
                      <div className="bd-highlight mt-4">
                        <h5 className="text-primary">
                          {champaingn.description}
                        </h5>
                      </div>
                      <div className="bd-highlight">
                        <strong>Cód da Solicitação: </strong>
                        {solicitation.code}
                      </div>
                      <div className="bd-highlight">
                        <strong>Data de Início: </strong>
                        {champaingn.begin_dt &&
                          formatBRDate(champaingn.begin_dt)}
                      </div>
                      {champaingn.end_dt && (
                        <div className="bd-highlight">
                          <strong>Data de Término: </strong>
                          {champaingn.end_dt && formatBRDate(champaingn.end_dt)}
                        </div>
                      )}
                      <div className="bd-highlight">
                        <strong>Ano Base: </strong>
                        <Badge variant="primary">{champaingn.base_year}</Badge>
                      </div>

                      <div className="bd-highlight mt-4">
                        <strong>Finalizada: </strong>
                        {solicitation.finished ? (
                          <Badge variant="success">Sim</Badge>
                        ) : (
                          <Badge variant="danger">Não</Badge>
                        )}
                      </div>
                    </Col>

                    <Col>
                      <>
                        <Card bg="primary" text="light" className="mb-3">
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="bd-highlight">
                                <FaCalendar size={30} />
                              </div>
                              <div className="bd-highlight">
                                <div className="d-flex flex-column bd-highlight text-center">
                                  Solicitado em
                                  {solicitation.created_at && (
                                    <div
                                      className="bd-highlight"
                                      style={{ fontSize: "1.5rem" }}
                                    >
                                      {formatBRDate(solicitation.created_at)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card
                          bg={total > 0.0 ? "success" : "secondary"}
                          text="light"
                        >
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="bd-highlight">
                                <FaDollarSign size={30} />
                              </div>
                              <div className="bd-highlight">
                                <div className="d-flex flex-column bd-highlight text-center">
                                  Valor total
                                  <div
                                    className="bd-highlight"
                                    style={{ fontSize: "1.5rem" }}
                                  >
                                    {loading ? (
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                      />
                                    ) : (
                                      formatCurrency(total)
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Jumbotron>
          <hr />
          <Container fluid>
            <Row>
              <Col>
                <div className="d-flex flex-column bd-highlight mb-3">
                  <div className="bd-highlight">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={handleShow}
                      disabled={solicitation.finished}
                    >
                      <FaPlus className="mr-2" />
                      Adicionar Material
                    </Button>
                  </div>
                  <div className="bd-highlight text-muted">
                    <small>Clique para adicionar materiais</small>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex flex-column bd-highlight mb-3">
                  <div className="bd-highlight">
                    {solicitation.finished ? (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleUnconfirmSolicitation}
                        disabled={!solicitation.finished}
                      >
                        {finishedLoading ? (
                          "Aguarde ..."
                        ) : (
                          <>
                            Retirar a confirmação
                            <FaBan className="ml-2" />
                          </>
                        )}
                      </Button>
                    ) : (
                      !isEmptyObject(solicitationProducts) && (
                        <Button
                          variant="success"
                          className="mt-2"
                          onClick={handleConfirmSolicitation}
                          disabled={solicitation.finished}
                        >
                          {finishedLoading ? (
                            "Finalizando ..."
                          ) : (
                            <>
                              Confirmar a solicitação
                              <FaCheckCircle className="ml-2" />
                            </>
                          )}
                        </Button>
                      )
                    )}
                  </div>
                  <div className="bd-highlight text-muted">
                    <small>
                      Clique para retirar ou confirmar a solicitação
                    </small>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Adicionar Produto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form onSubmit={handleSubmit(onSubmit)} method="POST">
                        <Form.Row>
                          <Col>
                            <Form.Group controlId="champaingn_product_id">
                              <Form.Label>Produto</Form.Label>
                              <Form.Control
                                as="select"
                                name="champaingn_product_id"
                                custom
                                ref={register}
                                required
                              >
                                {products.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.name} ({formatCurrency(item.price)})
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="quantity">
                              <Form.Label>Quantidade</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Quantidade"
                                name="quantity"
                                defaultValue="1"
                                min="1"
                                ref={register}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <SubmitButtonApp
                          color="primary"
                          loading={submitLoading}
                        >
                          Salvar
                        </SubmitButtonApp>
                      </Form>
                    </Modal.Body>
                  </Modal>

                  <h3>Materiais Solicitados</h3>
                  <Table striped bordered hover className="text-center">
                    <thead>
                      <tr className="bg-primary text-white">
                        <th>#</th>
                        <th>Material</th>
                        <th>Qtde</th>
                        <th>Total R$</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {solicitationProducts.length === 0 && (
                        <tr>
                          <td colSpan="5">Não foram encontrados dados</td>
                        </tr>
                      )}
                      {solicitationProducts.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <strong>
                              {item.isDeleted ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id={`tooltip-${index}`}>
                                      Produto inativo, por favor exclua
                                    </Tooltip>
                                  }
                                >
                                  <div className="text-danger">
                                    {item.name}
                                    <FaInfoCircle className="ml-1" />
                                  </div>
                                </OverlayTrigger>
                              ) : (
                                item.name
                              )}
                            </strong>
                          </td>
                          <td>
                            {item.isEdited ? (
                              <Form.Control
                                type="number"
                                size="sm"
                                defaultValue={item.quantity}
                                onChange={(e) =>
                                  setEditQuantity(e.target.value)
                                }
                              />
                            ) : (
                              <Badge variant="primary">
                                {formatValue(item.quantity)}
                              </Badge>
                            )}
                          </td>
                          <td>
                            {item.total > 0 ? (
                              <Badge variant="success">
                                {formatCurrency(item.total)}
                              </Badge>
                            ) : (
                              <Badge variant="secondary">
                                {formatCurrency(item.total)}
                              </Badge>
                            )}
                          </td>
                          <td>
                            <ButtonGroup aria-label="Action Buttons">
                              {item.isEdited && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-save-${index}`}>
                                      Salvar
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={() => {
                                      handleEditProduct(item.id);
                                    }}
                                    disabled={solicitation.finished}
                                  >
                                    <FaSave />
                                  </Button>
                                </OverlayTrigger>
                              )}
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-edit-${index}`}>
                                    Editar
                                  </Tooltip>
                                }
                              >
                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={() =>
                                    addProductToEditProduct(item.id)
                                  }
                                  disabled={solicitation.finished}
                                >
                                  <FaEdit />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-delete-${index}`}>
                                    Excluir
                                  </Tooltip>
                                }
                              >
                                <Button
                                  size="sm"
                                  variant="danger"
                                  onClick={() => handleDeleteProduct(item.id)}
                                  disabled={solicitation.finished}
                                >
                                  <FaTrash />
                                </Button>
                              </OverlayTrigger>
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              </Col>
              <Col md={6}>
                <div className="d-flex justify-content-between">
                  <div className="bd-highlight">
                    <h3>Quantidade de Caixas</h3>
                  </div>
                  <div className="bd-highlight">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={handleShowNewBox}
                    >
                      <FaPlus className="mr-2" />
                      Adicionar Caixa
                    </Button>

                    <Modal
                      show={showNewBox}
                      onHide={handleCloseNewBox}
                      centered
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Adicionar Caixa</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleAddBox} method="POST">
                          <Form.Row>
                            <Col>
                              <Form.Group controlId="weight_box">
                                <Form.Label>Peso da Caixa</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  value={newBoxWeight}
                                  placeholder="234,34"
                                  onChange={(e) =>
                                    setNewBoxWeight(e.target.value)
                                  }
                                />
                                <Form.Text className="text-muted">
                                  Exemplo: 234,34
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Button variant="primary" type="submit">
                            Salvar
                          </Button>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
                <Table striped bordered hover className="text-center">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th>#</th>
                      <th>Peso</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boxes.length === 0 && (
                      <tr>
                        <td colSpan="3">Não foram encontrados dados</td>
                      </tr>
                    )}
                    {boxes.map((item, index) => (
                      <tr key={index}>
                        <td>Caixa {index + 1}</td>
                        <td>
                          {item.isEdited ? (
                            <Form.Control
                              type="text"
                              size="sm"
                              defaultValue={new Intl.NumberFormat(
                                "pt-BR"
                              ).format(item.weight)}
                              onChange={(e) => setEditWeight(e.target.value)}
                            />
                          ) : (
                            <Badge variant="primary">
                              {new Intl.NumberFormat("pt-BR").format(
                                item.weight
                              )}{" "}
                              Kg
                            </Badge>
                          )}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Action Buttons">
                            {item.isEdited && (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-save-box-${index}`}>
                                    Salvar
                                  </Tooltip>
                                }
                              >
                                <Button
                                  size="sm"
                                  variant="success"
                                  onClick={() => {
                                    handleEditBox(item.id);
                                  }}
                                >
                                  <FaSave />
                                </Button>
                              </OverlayTrigger>
                            )}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-edit-box-${index}`}>
                                  Editar
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                variant="primary"
                                onClick={() => {
                                  addBoxToEdit(item.id);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-delete-box-${index}`}>
                                  Excluir
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => {
                                  handleDeleteBox(item.id);
                                }}
                              >
                                <FaTrash />
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="bg-primary text-white">
                    <tr>
                      <td>Total: {boxIndicators.total_count} Caixa(s)</td>
                      <td colSpan="2">
                        Total :{" "}
                        {new Intl.NumberFormat("pt-BR").format(
                          boxIndicators.weight_total
                        )}{" "}
                        Kg
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </>
  );
}

export default ChampaingnSolicitationView;
