import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PublicContent from "../Layout";
import { Card } from "../styles";
import GoBackButton from "../../../components/GoBackButton";

function MissionaryCampaignPublic() {
  return (
    <PublicContent>
      <Container>
        <GoBackButton />
        <h2 className="text-center text-muted mb-5">
          Selecione o que você deseja
        </h2>
        <Row>
          <Col md={6} sm={12}>
            <Link to="/champaing-solicitation-get">
              <Card>
                <h5>Já tenho um Código</h5>
                <small>
                  Selecione esta opção caso você já tenha feito uma solicitação
                  e deseja Visualizar, Informar pagamento ou Fazer alguma
                  alteração no seu pedido.
                </small>
              </Card>
            </Link>
          </Col>
          <Col md={6} sm={12}>
            <Link to="/missionary-champaing-new">
              <Card>
                <h5>Nova Solicitação</h5>
                <small>
                  Selecione esta opção para fazer a sua primeira solicitação.
                </small>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </PublicContent>
  );
}

export default MissionaryCampaignPublic;
