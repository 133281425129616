import styled from 'styled-components';

import { darken } from 'polished';

export const Container = styled.div`

    max-width: 600px;
    margin: 50px auto;

    
    h3 {
        color: #F6931C;
        font-size: 34px;
    }

    small {
        color: #909090;
        font-size: 14px;
    }


    form {
        display: flex;
        flex-direction: column;

        input {
            background: #f9f9fb;
            border: 1px solid rgba(0,0,0,0.06);
            border-radius: 4px;
            height: 44px;
            padding: 0 15px;
            color: #78797b;
            margin: 8px 0;

            &::placeholder{
                color: #909193;
            }
        }

         span {
            color: #ff97a6;
            font-size: 11px;
            align-self: flex-start;
            margin: 0 0 5px;
        }

         button {
            margin: 5px 0 0;
            height: 44px;
            background: #F6931C;
            color: #fff;
            border: 0;
            border-radius: 4px;
            font-size: 16px;
            transition: background 0.2s;

            &:hover{
                background: ${darken(0.03, '#F6931C')};
            }
        }
    }


`;