import React from 'react';

//libs
import { Form, Input } from '@rocketseat/unform';

import { Container } from '../styles';

export default function ProfileAuth() {


    function handleSubmit(data) {
        console.log(data);
    }


    return (

        <Container>

            <h3>Página de Segurança</h3>
            <small>Dados de segurança</small>

            <hr />

            <Form initialData={{}} onSubmit={handleSubmit}>
                <Input name="oldPassword" type="password" placeholder="Sua senha atual" />
                <Input name="password" type="password" placeholder="Nova senha" />
                <Input name="confirmPassword" type="password" placeholder="Confirme sua nova senha" />

                <button type="submit">Atualizar dados</button>
            </Form>
        </Container>
    );
}
