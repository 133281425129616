import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import {
  Container,
  Spinner,
  Jumbotron,
  Button,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { LinkContainer } from "react-router-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { FiEdit, FiPlus, FiSave, FiUserPlus } from "react-icons/fi";
import { FaTruckLoading } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import api from "../../services/api";
import { removeEspecialCharacters } from "../../utils/removeMask";

export default function DioceseView() {
  const history = useHistory();

  const { id } = useParams();
  const { register, handleSubmit } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset,
    setValue,
  } = useForm();

  const [parishRegion, setParishRegion] = useState({});
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  //new Parish
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => {
    setShow2(false);
    setNewParish("");
    setNewParishTelephone("");
    setNewParishContactMail("");
    setNewParishZipCode("");
    setNewParishCity("");
    setNewParishState("");
    setNewParishAddress("");
    setNewParishNeighborhood("");
    setNewParishComplement("");
    setNewParishNumber("");
  };

  const handleShow2 = () => setShow2(true);

  //New Diocese
  const [dioceseName, setDioceseName] = useState("");
  const [bishop, setBishop] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [complement, setComplement] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [dioceseState, setDioceseState] = useState("");
  const [telephone, setTelephone] = useState("");
  const [contactMail, setContactMail] = useState("");
  const [contacts, setContacts] = useState([{}]);
  const [contactTypes, setContactsTypes] = useState([]);

  const [zipCodeDelivery, setZipCodeDelivery] = useState("");

  const [searchAddressLoading, setSearchAddressLoading] = useState(false);
  const [
    searchAddressDeliveryLoading,
    setSearchAddressDeliveryLoading,
  ] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [submitContactsLoading, setSubmitContactLoading] = useState(false);

  //pagination
  const [parish, setParish] = useState([]);

  const options = {
    onRowClick: function (row) {
      history.push(`/parish-view/${row.id}`);
    },
    noDataText: "Nenhum registro encontrado",
  };

  //New Parish
  const [newParish, setNewParish] = useState("");
  const [newParishTelephone, setNewParishTelephone] = useState("");
  const [newParishContactMail, setNewParishContactMail] = useState("");
  const [newParishZipCode, setNewParishZipCode] = useState("");
  const [newParishCity, setNewParishCity] = useState("");
  const [newParishState, setNewParishState] = useState("");
  const [newParishAddress, setNewParishAddress] = useState("");
  const [newParishNeighborhood, setNewParishNeighborhood] = useState("");
  const [newParishComplement, setNewParishComplement] = useState("");
  const [newParishNumber, setNewParishNumber] = useState("");

  const [deliveryAddress, setDeliveryAddress] = useState({
    zip_code_delivery: "",
    public_place_delivery: "",
    complement_delivery: "",
    neighborhood_delivery: "",
    city_delivery: "",
    uf_delivery: "",
    number_delivery: 0,
    qtd_parish: 0,
  });

  const [submitParishButtonLoading, setSubmitParishButtonLoading] = useState(
    false
  );
  const [searchParishAddressLoading, setParishSearchAddressLoading] = useState(
    false
  );

  //new contact

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => {
    setShow3(false);
  };

  const handleShow3 = () => setShow3(true);

  //Delivery address
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => {
    setShow4(false);
  };

  const handleShow4 = () => {
    setShow4(true);
  };

  const [deliverySubmit, setDeliverySubmit] = useState(false);

  useEffect(() => {
    loadDiocese(id);
    loadContactsTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    loadContacts(id);
  }, [id]);

  async function loadDiocese(dioceseId) {
    setLoading(true);

    await api
      .get(`/api/diocese/${dioceseId}`)
      .then((resp) => {
        setDioceseName(resp.data.name);
        setBishop(resp.data.bishop);
        setCnpj(resp.data.cnpj);
        setZipCode(resp.data.zip_code);
        setAddress(resp.data.address);
        setComplement(resp.data.complement);
        setNumber(resp.data.number);
        setNeighborhood(resp.data.neighborhood);
        setCity(resp.data.city);
        setDioceseState(resp.data.uf);
        setTelephone(resp.data.telephone);
        setContactMail(resp.data.contact_mail);

        setParishRegion(resp.data.parish_region);

        setDeliveryAddress({
          zip_code_delivery: resp.data.zip_code_delivery,
          public_place_delivery: resp.data.public_place_delivery,
          complement_delivery: resp.data.complement_delivery,
          neighborhood_delivery: resp.data.neighborhood_delivery,
          city_delivery: resp.data.city_delivery,
          uf_delivery: resp.data.uf_delivery,
          number_delivery: resp.data.number_delivery,
          qtd_parish: resp.data.qtd_parish,
        });

        setZipCodeDelivery(resp.data.zip_code_delivery);

        reset({
          public_place_delivery: resp.data.public_place_delivery,
          complement_delivery: resp.data.complement_delivery,
          neighborhood_delivery: resp.data.neighborhood_delivery,
          city_delivery: resp.data.city_delivery,
          uf_delivery: resp.data.uf_delivery,
          number_delivery: resp.data.number_delivery,
          qtd_parish: resp.data.qtd_parish,
        });

        const responseParish = resp.data.parish.map((p) => {
          return {
            ...p,
            uuid: uuidv4(),
          };
        });

        setParish(responseParish);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Não foi possível encontrar a Diocese");
        history.push("/dioceses");
      });
  }

  async function handleEditDiocese(e) {
    e.preventDefault();

    setSubmitButtonLoading(true);

    await api
      .put(`/api/diocese/${id}`, {
        name: dioceseName,
        bishop: bishop,
        zip_code: removeEspecialCharacters(zipCode),
        cnpj: removeEspecialCharacters(cnpj),
        address: address,
        complement: complement,
        number: Number(number),
        neighborhood: neighborhood,
        city: city,
        uf: dioceseState,
        telephone: removeEspecialCharacters(telephone),
        contact_mail: contactMail,
        parish_region_id: parishRegion.id,
      })
      .then((resp) => {
        toast.success("Diocese atualizada com sucesso!");
        setSubmitButtonLoading(false);
        handleClose();
        loadDiocese(id);
      })
      .catch((error) => {
        toast.error(
          "Não foi possível atualizar a Diocese. Verifique os dados e tente novamente!"
        );
        setSubmitButtonLoading(false);
      });
  }

  async function handleParishSubmit(e) {
    e.preventDefault();

    setSubmitParishButtonLoading(true);

    await api
      .post("/api/parish", {
        name: newParish,
        zip_code: removeEspecialCharacters(newParishZipCode),
        address: newParishAddress,
        complement: newParishComplement,
        number: newParishNumber,
        neighborhood: newParishNeighborhood,
        city: newParishCity,
        uf: newParishState,
        telephone: removeEspecialCharacters(newParishTelephone),
        email: newParishContactMail,
        diocese_id: id,
      })
      .then((resp) => {
        setSubmitParishButtonLoading(false);
        toast.success("Paróquia adicionada com sucesso!");
        handleClose2();
        loadDiocese(id);
      })
      .catch((error) => {
        setSubmitParishButtonLoading(false);
        toast.error(
          "Não foi possível adicionar a Paróquia! Verifique os dados e tente novamente."
        );
      });
  }

  async function searchAddress() {
    setSearchAddressLoading(true);
    await axios
      .get(`https://viacep.com.br/ws/${zipCode}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          toast.error("CEP inválido!");
          setCity("");
          setDioceseState("");
          setAddress("");
          setNeighborhood("");
          setSearchAddressLoading(false);
          return;
        }

        toast.success("CEP válido!");
        setCity(resp.data.localidade);
        setDioceseState(resp.data.uf);
        setAddress(resp.data.logradouro);
        setNeighborhood(resp.data.bairro);
        setSearchAddressLoading(false);
      })
      .catch((error) => {
        toast.error("CEP inválido!");
        setCity("");
        setDioceseState("");
        setAddress("");
        setNeighborhood("");
        setSearchAddressLoading(false);
      });
  }

  async function searchAddressDelivery() {
    setSearchAddressDeliveryLoading(true);
    await axios
      .get(`https://viacep.com.br/ws/${zipCodeDelivery}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          toast.error("CEP inválido!");
          setSearchAddressDeliveryLoading(false);
          return;
        }

        setValue("public_place_delivery", resp.data.logradouro);
        setValue("neighborhood_delivery", resp.data.bairro);
        setValue("city_delivery", resp.data.localidade);
        setValue("uf_delivery", resp.data.uf);

        setSearchAddressDeliveryLoading(false);
      })
      .catch((error) => {
        toast.error("CEP inválido!");
        setSearchAddressDeliveryLoading(false);
      });
  }

  async function searchParishAddress() {
    setParishSearchAddressLoading(true);
    await axios
      .get(`https://viacep.com.br/ws/${newParishZipCode}/json/`)
      .then((resp) => {
        toast.success("CEP válido!");
        setNewParishCity(resp.data.localidade);
        setNewParishState(resp.data.uf);
        setNewParishAddress(resp.data.logradouro);
        setNewParishNeighborhood(resp.data.bairro);
        setParishSearchAddressLoading(false);
      })
      .catch((error) => {
        toast.error("CEP inválido!");
        setNewParishCity("");
        setNewParishState("");
        setNewParishAddress("");
        setNewParishNeighborhood("");
        setParishSearchAddressLoading(false);
      });
  }

  async function onAfterSaveCell(row) {
    await api
      .put(`/api/diocese-contact/${row.id}`, {
        name: row.name,
        email: row.email,
        telephone: row.telephone,
      })
      .then((resp) => {
        loadContacts(id);
        loadDiocese(id);
        return toast.success("✅ Contato editado com sucesso!");
      })
      .catch((error) => {
        return toast.error(
          "❗ Não foi possível editar o contato! Tente novamente."
        );
      });
  }

  const cellEditProp = {
    mode: "click",
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  async function loadContacts(id) {
    await api.get(`/api/diocese-contact/${id}`).then((resp) => {
      const contactsResp = resp.data.map((contact) => {
        const response = {
          ...contact,
          description: contact.contact_type.description,
          uuid: uuidv4(),
        };

        delete response.contact_type;

        return response;
      });

      setContacts(contactsResp);
    });
  }

  async function loadContactsTypes() {
    await api.get("/api/contact-types").then((resp) => {
      setContactsTypes(resp.data);
    });
  }

  const handleAddContact = async (data) => {
    const { name, contact_telephone_mask, email, contact_type_id } = data;

    const telephone = removeEspecialCharacters(contact_telephone_mask);

    setSubmitContactLoading(true);

    await api
      .post("/api/diocese-contact", {
        name,
        email,
        telephone,
        contact_type_id,
        diocese_id: id,
      })
      .then((resp) => {
        loadContacts(id);
        loadDiocese(id);
        setShow3(false);
        setSubmitContactLoading(false);
        return toast.success("✅ Contato adicionado com sucesso!");
      })
      .catch((error) => {
        setSubmitContactLoading(false);

        if (error.response.status === 409) {
          return toast.warn("⛔ Já existe um contato desse tipo!");
        }

        return toast.error(
          "❗ Não foi possível adicionar o contato! Tente novamente."
        );
      });
  };

  function telValidator(value) {
    const rule = /^[0-9]+$/;

    const valueLength = value.length;

    if (valueLength > 11 || valueLength < 10) {
      toast.error("❌ Máximo de 11 e Mínimo de 10 caracteres!");
      return false;
    }
    if (value.match(rule)) {
      return true;
    } else {
      toast.error("❌ Permitido somente números!");
      return false;
    }
  }

  function isEmailAddress(str) {
    var pattern = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    const test = pattern.test(str);

    if (test) {
      return true;
    } else {
      toast.error("❌ E-mail inválido!");
      return false;
    }
  }

  // const concatDeliveryAddress = useCallback(() => {
  //   const {
  //     public_place_delivery,
  //     city_delivery,
  //     uf_delivery,
  //     number_delivery,
  //     zip_code_delivery,
  //   } = deliveryAddress;

  //   if (!public_place_delivery || !city_delivery || !uf_delivery) {
  //     return "Não Informado";
  //   }

  //   return `${public_place_delivery}, Nº ${
  //     number_delivery ? number_delivery : "S/N"
  //   }, ${city_delivery} - ${uf_delivery}, ${zip_code_delivery}`;
  // }, [deliveryAddress]);

  const handleSubmitDeliveryAddress = useCallback(
    async ({
      public_place_delivery,
      complement_delivery,
      neighborhood_delivery,
      city_delivery,
      uf_delivery,
      number_delivery,
      qtd_parish,
    }) => {
      setDeliverySubmit(true);

      await api
        .put(`/api/diocese/${id}?type=delivery_address`, {
          zip_code_delivery: removeEspecialCharacters(zipCodeDelivery),
          public_place_delivery,
          complement_delivery,
          neighborhood_delivery,
          city_delivery,
          uf_delivery,
          number_delivery,
          qtd_parish,
        })
        .then((resp) => {
          const {
            zip_code_delivery,
            public_place_delivery,
            complement_delivery,
            neighborhood_delivery,
            city_delivery,
            uf_delivery,
            number_delivery,
            qtd_parish,
          } = resp.data;

          setDeliveryAddress({
            zip_code_delivery,
            public_place_delivery,
            complement_delivery,
            neighborhood_delivery,
            city_delivery,
            uf_delivery,
            number_delivery,
            qtd_parish,
          });

          setZipCodeDelivery(zip_code_delivery);

          reset({
            public_place_delivery,
            complement_delivery,
            neighborhood_delivery,
            city_delivery,
            uf_delivery,
            number_delivery,
            qtd_parish,
          });

          toast.success("✅ Dados alterados com sucesso!");
          setDeliverySubmit(false);
        })
        .catch((error) => {
          toast.error(
            "❌ Ops, algo de errado aconteceu! Verifique os dados e tente novamente!"
          );
          setDeliverySubmit(false);
        });
    },
    [id, reset, zipCodeDelivery]
  );

  return (
    <Container>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Row>
            <LinkContainer to="/dioceses">
              <Button variant="link">Voltar</Button>
            </LinkContainer>
          </Row>

          <Row>
            <Col md="8">
              <Jumbotron>
                <Container>
                  <div className="display-4">{dioceseName}</div>

                  <div className="d-flex flex-column bd-highlight mb-3">
                    <div className="bd-highlight">
                      <span>
                        Localidade: <strong>{dioceseState}</strong>
                      </span>
                    </div>
                    <div className="bd-highlight">
                      <span>
                        Regional: <strong>{parishRegion.region_name}</strong>
                      </span>
                    </div>

                    <div className="bd-highlight">
                      <span>
                        Quantidade Paróquias:{" "}
                        <strong>{deliveryAddress.qtd_parish}</strong>
                      </span>
                    </div>
                  </div>
                </Container>
              </Jumbotron>
            </Col>

            <Col md="4">
              <div className="d-flex flex-column bd-highlight justify-content-between">
                <div className="bd-highlight">
                  <Button variant="success" block onClick={handleShow}>
                    <FiEdit className="mr-2" /> Editar Dados
                  </Button>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Editar {dioceseName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form onSubmit={handleEditDiocese} method="POST">
                        <h5>Dados da Diocese</h5>
                        <hr />
                        <div className="form-row">
                          <div className="form-group col-md-7">
                            <label htmlFor="newDiocese">Nome da Diocese</label>
                            <input
                              type="text"
                              className="form-control"
                              name="newDiocese"
                              id="newDiocese"
                              value={dioceseName}
                              onChange={(e) => setDioceseName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-5">
                            <label htmlFor="cnpj">CNPJ</label>
                            <InputMask
                              mask="99.999.999/9999-99"
                              placeholder="Ex: 99.999.999/9999-99"
                              type="text"
                              className="form-control"
                              name="cnpj"
                              id="cnpj"
                              value={cnpj}
                              onChange={(e) => setCnpj(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="bishop">Nome do Bispo</label>
                          <input
                            type="text"
                            className="form-control"
                            name="newBishop"
                            id="bishop"
                            value={bishop}
                            onChange={(e) => setBishop(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="telephone">Telefone</label>
                            <InputMask
                              mask="(99) 99999-9999"
                              placeholder="Ex: (99) 99999-9999"
                              type="tel"
                              className="form-control"
                              name="telephone"
                              id="telephone"
                              value={telephone}
                              onChange={(e) => setTelephone(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_email">
                              E-mail para Contato
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="contact_email"
                              id="contact_email"
                              value={contactMail}
                              onChange={(e) => setContactMail(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <h5>Dados de Endereço</h5>
                        <hr />
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <label htmlFor="zipCode">
                              CEP{" "}
                              {searchAddressLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </label>
                            <InputMask
                              mask="99999-999"
                              type="text"
                              className="form-control"
                              name="zipCode"
                              id="zipCode"
                              value={zipCode}
                              onChange={(e) => setZipCode(e.target.value)}
                              onBlur={searchAddress}
                              required
                            />
                            <small className="text-primary">
                              *Ao digitar um CEP válido e retirar o foco do
                              campo, os campos em cinza serão preenchidos
                              automaticamente.
                            </small>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="city">Cidade</label>
                            <input
                              type="text"
                              className="form-control"
                              name="city"
                              id="city"
                              value={city || ""}
                              onChange={(e) => setCity(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="state">UF</label>
                            <input
                              type="text"
                              className="form-control"
                              name="state"
                              id="state"
                              value={dioceseState}
                              onChange={(e) => setDioceseState(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-8">
                            <label htmlFor="address">Endereço</label>
                            <input
                              type="text"
                              className="form-control"
                              name="address"
                              id="address"
                              onChange={(e) => setAddress(e.target.value)}
                              value={address || ""}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="neighborhood">Bairro</label>
                            <input
                              type="text"
                              className="form-control"
                              name="neighborhood"
                              id="neighborhood"
                              value={neighborhood || ""}
                              onChange={(e) => setNeighborhood(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-5">
                            <label htmlFor="complement">Complemento</label>
                            <input
                              type="text"
                              className="form-control"
                              name="complement"
                              id="complement"
                              value={complement || ""}
                              onChange={(e) => setComplement(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label htmlFor="number">Número</label>
                            <input
                              type="number"
                              className="form-control"
                              name="number"
                              id="number"
                              value={number}
                              onChange={(e) => setNumber(e.target.value)}
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="d-flex justify-content-between">
                          <Button variant="secondary" onClick={handleClose}>
                            <TiCancel className="mr-2" /> Cancelar
                          </Button>
                          <Button variant="primary" type="submit">
                            <FiSave className="mr-2" />{" "}
                            {submitButtonLoading ? "Salvando..." : "Salvar"}
                          </Button>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                </div>

                <div className="bd-highlight pt-4">
                  <Button variant="dark" block onClick={handleShow2}>
                    <FiPlus className="mr-2" /> Nova Paróquia
                  </Button>

                  <Modal
                    show={show2}
                    onHide={handleClose2}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Vincular Paróquia</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form onSubmit={handleParishSubmit} method="POST">
                        <h5>Dados da Paróquia</h5>
                        <hr />
                        <div className="form-row">
                          <div className="form-group col-md-7">
                            <label htmlFor="newParish">Nome da Paróquia</label>
                            <input
                              type="text"
                              className="form-control"
                              name="newParish"
                              id="newParish"
                              value={newParish}
                              onChange={(e) => setNewParish(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-5">
                            <label htmlFor="telephone">Telefone</label>
                            <InputMask
                              mask="(99) 99999-9999"
                              placeholder="Ex: (99) 99999-9999"
                              type="tel"
                              className="form-control"
                              name="telephone"
                              id="telephone"
                              value={newParishTelephone}
                              onChange={(e) =>
                                setNewParishTelephone(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_email">
                              E-mail para Contato
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="contact_email"
                              id="contact_email"
                              value={newParishContactMail}
                              onChange={(e) =>
                                setNewParishContactMail(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>

                        <h5>Dados de Endereço</h5>
                        <hr />
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <label htmlFor="zipCode">
                              CEP{" "}
                              {searchParishAddressLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </label>
                            <InputMask
                              mask="99999-999"
                              type="text"
                              className="form-control"
                              name="zipCode"
                              id="zipCode"
                              value={newParishZipCode}
                              onChange={(e) =>
                                setNewParishZipCode(e.target.value)
                              }
                              onBlur={searchParishAddress}
                              required
                            />
                            <small className="text-primary">
                              *Ao digitar um CEP válido e retirar o foco do
                              campo, os campos em cinza serão preenchidos
                              automaticamente.
                            </small>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="city">Cidade</label>
                            <input
                              type="text"
                              className="form-control"
                              name="city"
                              id="city"
                              value={newParishCity}
                              onChange={(e) => setNewParishCity(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="state">UF</label>
                            <input
                              type="text"
                              className="form-control"
                              name="state"
                              id="state"
                              value={newParishState}
                              maxLength="2"
                              onChange={(e) =>
                                setNewParishState(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-8">
                            <label htmlFor="address">Endereço</label>
                            <input
                              type="text"
                              className="form-control"
                              name="address"
                              id="address"
                              value={newParishAddress}
                              onChange={(e) =>
                                setNewParishAddress(e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="neighborhood">Bairro</label>
                            <input
                              type="text"
                              className="form-control"
                              name="neighborhood"
                              id="neighborhood"
                              value={newParishNeighborhood}
                              onChange={(e) =>
                                setNewParishNeighborhood(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-5">
                            <label htmlFor="complement">Complemento</label>
                            <input
                              type="text"
                              className="form-control"
                              name="complement"
                              id="complement"
                              value={newParishComplement}
                              onChange={(e) =>
                                setNewParishComplement(e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label htmlFor="number">Número</label>
                            <input
                              type="number"
                              className="form-control"
                              name="number"
                              id="number"
                              value={newParishNumber}
                              onChange={(e) =>
                                setNewParishNumber(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="d-flex justify-content-between">
                          <Button variant="secondary" onClick={handleClose2}>
                            <TiCancel className="mr-2" /> Cancelar
                          </Button>
                          <Button variant="primary" type="submit">
                            <FiSave className="mr-2" />
                            {submitParishButtonLoading
                              ? "Salvando..."
                              : "Salvar"}
                          </Button>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                </div>

                <div className="bd-highlight pt-4">
                  <Button variant="dark" block onClick={handleShow3}>
                    <FiUserPlus className="mr-2" /> Adicionar Contato
                  </Button>

                  <Modal
                    show={show3}
                    onHide={handleClose3}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Adicionar Novo Contato</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form
                        onSubmit={handleSubmit(handleAddContact)}
                        method="POST"
                      >
                        <div className="form-row">
                          <div className="form-group col-md-7">
                            <label htmlFor="newParish">Nome</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              ref={register}
                              required
                            />
                          </div>
                          <div className="form-group col-md-5">
                            <label htmlFor="contact_telephone_mask">
                              Telefone
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              placeholder="Ex: (99) 99999-9999"
                              type="tel"
                              className="form-control"
                              name="contact_telephone_mask"
                              id="contact_telephone_mask"
                              inputRef={register}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_email">E-mail</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              ref={register}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="contact_email">Tipo</label>
                            <select
                              className="form-control"
                              id="contact_type_id"
                              name="contact_type_id"
                              ref={register}
                            >
                              {contactTypes.map((ct, index) => (
                                <option value={ct.id} key={index}>
                                  {ct.description}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <Button variant="secondary" onClick={handleClose3}>
                            <TiCancel className="mr-2" /> Cancelar
                          </Button>
                          <Button variant="primary" type="submit">
                            <FiSave className="mr-2" />
                            {submitContactsLoading ? "Salvando ..." : "Salvar"}
                          </Button>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                </div>

                <div className="bd-highlight pt-4">
                  <Button variant="dark" block onClick={handleShow4}>
                    <FaTruckLoading className="mr-2" /> End. Entrega
                  </Button>

                  <Modal
                    show={show4}
                    onHide={handleClose4}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Editar / Adicionar Endereço de Entrega de Materiais
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form
                        onSubmit={handleSubmit2(handleSubmitDeliveryAddress)}
                        method="POST"
                      >
                        <h5>Dados de Endereço</h5>
                        <hr />
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <label htmlFor="ZipCodeDelivery">
                              CEP{" "}
                              {searchAddressDeliveryLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                ""
                              )}
                            </label>
                            <InputMask
                              mask="99999-999"
                              type="text"
                              className="form-control"
                              name="ZipCodeDelivery"
                              id="ZipCodeDelivery"
                              value={zipCodeDelivery || ""}
                              onChange={(e) =>
                                setZipCodeDelivery(e.target.value)
                              }
                              onBlur={searchAddressDelivery}
                              required
                            />
                            <small className="text-primary">
                              *Ao digitar um CEP válido e retirar o foco do
                              campo, os campos em cinza serão preenchidos
                              automaticamente.
                            </small>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="city_delivery">Cidade</label>
                            <input
                              type="text"
                              className="form-control"
                              name="city_delivery"
                              id="city_delivery"
                              ref={register2}
                              required
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="uf_delivery">UF</label>
                            <input
                              type="text"
                              className="form-control"
                              name="uf_delivery"
                              id="uf_delivery"
                              ref={register2}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-8">
                            <label htmlFor="public_place_delivery">
                              Endereço
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="public_place_delivery"
                              id="public_place_delivery"
                              ref={register2}
                              required
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="neighborhood_delivery">
                              Bairro
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="neighborhood_delivery"
                              id="neighborhood_delivery"
                              ref={register2}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-5">
                            <label htmlFor="complement_delivery">
                              Complemento
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="complement_delivery"
                              id="complement_delivery"
                              ref={register2}
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label htmlFor="number_delivery">Número</label>
                            <input
                              type="number"
                              className="form-control"
                              name="number_delivery"
                              id="number_delivery"
                              ref={register2}
                              required
                            />
                          </div>
                        </div>

                        <h5>Paróquias</h5>
                        <hr />
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label htmlFor="qtd_parish">
                              Quantidade de Paróquias
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="qtd_parish"
                              id="qtd_parish"
                              ref={register2}
                              required
                            />
                          </div>
                        </div>
                        <Button variant="primary" type="submit">
                          {deliverySubmit ? "Salvando..." : "Salvar"}
                        </Button>
                      </form>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md="6">
              <>
                <h5>Paróquias Vinculadas</h5>
                <BootstrapTable
                  data={parish}
                  striped
                  hover
                  pagination
                  version="4"
                  search
                  options={options}
                >
                  <TableHeaderColumn isKey={true} dataField="uuid" hidden>
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="name">Nome</TableHeaderColumn>
                  <TableHeaderColumn dataField="city">Cidade</TableHeaderColumn>
                  <TableHeaderColumn dataField="uf">Estado</TableHeaderColumn>
                </BootstrapTable>
              </>
            </Col>

            <Col md="6">
              <h5>Lista de Contatos</h5>

              <BootstrapTable
                data={contacts}
                striped
                hover
                pagination
                version="4"
                search
                cellEdit={cellEditProp}
                keyField="uuid"
                options={{ noDataText: "Nenhum registro encontrado" }}
              >
                <TableHeaderColumn dataField="uuid" hidden>
                  ID
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="name"
                  editable={{ type: "input" }}
                >
                  Nome
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="email"
                  editable={{ validator: isEmailAddress }}
                >
                  E-mail
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="telephone"
                  editable={{ validator: telValidator }}
                >
                  Tel
                </TableHeaderColumn>

                <TableHeaderColumn dataField="description" editable={false}>
                  Tipo
                </TableHeaderColumn>
              </BootstrapTable>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
