import styled from "styled-components";

import { lighten } from "polished";

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    list-style: none;

    @media only screen and (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    @media only screen and (max-width: 810px) {
      grid-gap: 10px;
    }

    @media only screen and (max-width: 555px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
    }

    @media only screen and (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
    }

    a {
      text-decoration: none;
      cursor: pointer;
      background: transparent;
      border: 2.5px dashed #666;
      border-radius: 6px;
      transition: border 0.1s;

      &:hover {
        border: 2.5px solid #fab561;
        background: ${lighten(0.4, "#f6931c")};
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
      }

      li {
        width: 100%;
        padding: 20px 30px;

        div {
          display: flex;
          align-items: center;

          @media only screen and (max-width: 950px) {
            padding: 5px 20px;
          }

          @media only screen and (max-width: 810px) {
            padding: 3px 17px;
          }

          @media only screen and (max-width: 560px) {
            padding: 2px 15px;
          }

          @media only screen and (max-width: 320px) {
            padding: 2px 0;
          }

          img {
            height: 64px;
            width: 64px;
          }

          h5 {
            color: #666;
            margin-left: 20px;
            text-align: center;
            font-size: 25px;
            font-weight: 500;
          }
        }
      }
    }
  }
`;
