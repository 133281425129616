import React from "react";
import { Container } from "react-bootstrap";

import ParishRegionTable from "../../components/ParishRegion";

export default function ParishRegion() {
  return (
    <Container>
      <ParishRegionTable url="/api/parish_region" />
    </Container>
  );
}
