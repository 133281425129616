import { useCallback, useState } from "react";
import { Col, Container, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { formatAndValidDateISO, formatDateISO } from "../../utils/formatDate";
import { removeEspecialCharacters } from "../../utils/removeMask";
import api from "../../services/api";
import SubmitButtonApp from "../../components/SubmitButton";
import PrevButton from "../../components/GoBackButton";

function MissionaryCampaignCreate() {
  const history = useHistory();
  const [description, setDescription] = useState("");
  const [beginDate, setBeginDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const formattedDate = formatDateISO(beginDate);
      const formattedDueDate = formatDateISO(dueDate);

      setSubmitLoading(true);

      await api
        .post("/api/missionary-campaign", {
          description,
          begin_dt: formattedDate,
          due_date: formattedDueDate,
        })
        .then((resp) => {
          setSubmitLoading(false);
          toast.success("✅ Campanha criada com sucesso!");

          setTimeout(() => {
            history.push(`/missionary-campaign-view/${resp.data.id}`);
          }, 1000);
        })
        .catch((errors) => {
          setSubmitLoading(false);

          const statusCode = errors.response.status;

          switch (statusCode) {
            case 409:
              toast.error(`❌ ${errors.response.data.error}`);
              break;
            case 422:
              Object.values(errors.response.data.errors).forEach((item) => {
                toast.error(`❌ ${item}`, { autoClose: 8000 });
              });
              break;
            default:
              break;
          }
        });
    },
    [beginDate, description, dueDate, history]
  );

  const handleValidDate = useCallback(async (value) => {
    if (!removeEspecialCharacters(value)) {
      return;
    }
    const { isDate } = formatAndValidDateISO(value);

    if (!isDate) {
      setDisabled(true);
      toast.error("❌ Data inválida!");
      return;
    }

    setDisabled(false);
  }, []);

  return (
    <>
      <Container>
        <PrevButton />
        <h1>Adicionar Campanha Missionária</h1>
        <Form onSubmit={handleSubmit} method="POST" className="mt-4">
          <Form.Row>
            <Col>
              <Form.Group controlId="description">
                <Form.Label>Título da Campanha</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: Camapanha Missionária 2021"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="begin_dt">
                <Form.Label>Data de Inicio</Form.Label>
                <InputMask
                  type="text"
                  className="form-control"
                  name="begin_dt"
                  mask="99/99/9999"
                  placeholder="DD/MM/YYYY"
                  onBlur={() => handleValidDate(beginDate)}
                  onChange={(e) => setBeginDate(e.target.value)}
                  value={beginDate}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="due_date">
                <Form.Label>Data de vencimento boletos</Form.Label>
                <InputMask
                  type="text"
                  className="form-control"
                  name="due_date"
                  mask="99/99/9999"
                  placeholder="DD/MM/YYYY"
                  onBlur={() => handleValidDate(dueDate)}
                  onChange={(e) => setDueDate(e.target.value)}
                  value={dueDate}
                  required
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <SubmitButtonApp
            loading={submitLoading}
            disabled={disabled}
            color="primary"
          >
            Salvar
          </SubmitButtonApp>
        </Form>
      </Container>
    </>
  );
}

export default MissionaryCampaignCreate;
