import React from "react";
import { Spinner } from "react-bootstrap";
import { Pie } from "react-chartjs-2";

function PiChart({ labels, values, colors, title, loading }) {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };
  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">
          <h4 className="text-center">{title}</h4>
        </div>
        <div className="card-body">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" size="lg" />
            </div>
          ) : (
              <Pie data={data} width={100} height={70} />
            )}
        </div>
      </div>
    </>
  );
}

export default PiChart;
