import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Jumbotron,
  Spinner,
  Button,
  Row,
  Badge,
  Container,
  Modal,
  Form,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaCheckCircle,
  FaDownload,
  FaFileExcel,
  FaFileInvoice,
  FaTicketAlt,
  FaTimes,
  FaTruckLoading,
} from "react-icons/fa";
import api from "../../services/api";
import { formatDate } from "../../utils/formatDate";
import MissionaryCampaignSolicitationsTable from "../../components/MissionaryCampaignTable/solicition";
import PrevButton from "../../components/GoBackButton";

function MissionaryCampaignView() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [parishRegions, setParishRegions] = useState([]);
  const [parishRegion, setParishRegion] = useState(0);
  const [type, setType] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = useCallback(
    async (type) => {
      if (!parishRegions.length) {
        const { data } = await api.get("/api/parish_region/simple");

        setParishRegions(data);

        setParishRegion(data[0].id);
      }

      setType(type);
      setShow(true);
    },
    [parishRegions]
  );

  const [generateLoading, setGenerateLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await api
        .get(`api/missionary-campaign/${id}`)
        .then((resp) => {
          setData(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("❌ Nenhuma Campanha encontrada!");

          setTimeout(() => {
            history.push("/missionary-campaign");
          }, 1000);
        });
    })();
  }, [history, id]);

  const handleDownload = useCallback(async (id) => {
    await api
      .get(`/api/missionary-campaign/solicitation/export/${id}`, {
        responseType: "blob",
      })
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );
      });
  }, []);

  const handleGenerateLabels = useCallback(
    async (e) => {
      e.preventDefault();

      setGenerateLoading(true);

      const url = `/api/missionary-campaign/solicitation/${type === "box-labels" ? "box-labels" : "box-transport"
        }?parish_region=${parishRegion}&missionary_campaign=${id}`;

      await api
        .get(url, {
          responseType: "blob",
        })
        .then((resp) => {
          let filename = resp.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");

          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}`); //or any other extension
          document.body.appendChild(link);
          link.click();

          setGenerateLoading(false);

          toast.success("✅ Download concluído!");
        })
        .catch((errors) => {
          setGenerateLoading(false);

          toast.error(
            "❌ Não foi possível fazer o download, tente novamente mais tarde!"
          );
        });
    },
    [id, parishRegion, type]
  );

  const sendInvoices = useCallback(async () => {
    await api
      .post(`/api/missionary-campaign/invoice/${id}`)
      .then((resp) => {
        toast.success("✅ Foi enviado um job para geração/envio dos boleto!");
      })
      .catch((error) => {
        toast.error(
          "❌ Não foi possível criar o job para geração/envio dos boleto!"
        );
      });
  }, [id]);

  const getInvoiceReport = useCallback(async () => {
    await api
      .get(`/api/missionary-campaign/invoices/reports/status/${id}`, {
        responseType: "blob",
      })
      .then((resp) => {
        let filename = resp.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();

        toast.success("✅ Download concluído!");
      })
      .catch((errors) => {
        toast.error(
          "❌ Não foi possível fazer o download, tente novamente mais tarde!"
        );
      });
  }, [id]);

  const handleStatus = useCallback(async () => {
    try {
      const response = await api.put(`/api/missionary-campaign/${id}`);

      setData({
        ...data,
        finished: response.data.finished,
        end_dt: response.data.end_dt,
      });
    } catch (error) {
      console.log(error);
    }
  }, [data, id]);


  const handleSendErrataInvoices = useCallback(
    async () => {
      const { data } = await api.get("/api/missionary-campaign/errata/invoices");

      if (data) {
        toast.success("Errata enviada com sucesso");
      }

    },
    []

  );

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner variant="primary" animation="border" />
        </div>
      ) : (
        <>
          <Container fluid>
            <PrevButton />
            <Row>
              <Col>
                <Jumbotron className="p-4 h-100">
                  <Row>
                    <Col md={10}>
                      <div className="d-flex flex-column bd-highlight">
                        <div className="bd-highlight">
                          <h2>
                            {data.description}
                            {!!data.finished ? (
                              <Badge variant="success" className="mr-1 ml-1">
                                Finalizada
                              </Badge>
                            ) : (
                              <Badge variant="danger" className="mr-1 ml-1">
                                Em Aberto
                              </Badge>
                            )}
                          </h2>
                        </div>
                        <div className="bd-highlight">
                          <h5>
                            <strong>Ano Base:</strong>
                            <Badge variant="primary" className="mr-1 ml-1">
                              {data.base_year}
                            </Badge>
                          </h5>
                        </div>
                        <div className="bd-highlight">
                          <h6>
                            <strong>Código:</strong> {data.code}
                          </h6>
                        </div>

                        <div className="bd-highlight">
                          <strong>Data de Início: </strong>
                          {formatDate(data.begin_dt)}
                        </div>

                        {data.end_dt && (
                          <div className="bd-highlight">
                            <strong>Data de Finalização: </strong>
                            {formatDate(data.end_dt)}
                          </div>
                        )}
                        {data.due_date && (
                          <div className="bd-highlight">
                            <strong>Vencimento Boletos: </strong>
                            {formatDate(data.due_date)}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col>
                      <div className="d-flex flex-column bd-highlight">
                        <div className="p-2 bd-highlight">
                          <Button
                            variant={!!data.finished ? "danger" : "success"}
                            size="sm"
                            block
                            onClick={handleStatus}
                          >
                            {!!data.finished ? (
                              <>
                                <FaTimes className="mr-2" />
                                Já Finalizada
                              </>
                            ) : (
                              <>
                                <FaCheckCircle className="mr-2" />
                                Finalizar
                              </>
                            )}
                          </Button>
                        </div>
                        <div className="p-2 bd-highlight">
                          <Button
                            variant="primary"
                            size="sm"
                            block
                            onClick={() => {
                              handleDownload(id);
                            }}
                          >
                            <FaFileExcel className="mr-2" />
                            Relatório
                          </Button>
                        </div>
                        <div className="p-2 bd-highlight">
                          <Button
                            variant="primary"
                            size="sm"
                            block
                            onClick={() => handleShow("box-labels")}
                          >
                            <FaTicketAlt className="mr-2" />
                            Etiquetas
                          </Button>
                        </div>
                        <div className="p-2 bd-highlight">
                          <Button
                            variant="primary"
                            size="sm"
                            block
                            onClick={() => handleShow("box-transport")}
                          >
                            <FaTruckLoading className="mr-2" />
                            Transportadora
                          </Button>
                        </div>
                        <div className="p-2 bd-highlight">
                          <Button
                            variant="danger"
                            size="sm"
                            block
                            onClick={sendInvoices}
                          >
                            <FaFileInvoice className="mr-2" />
                            Enviar Boletos
                          </Button>
                        </div>
                        <div className="p-2 bd-highlight">
                          <Button
                            variant="warning"
                            size="sm"
                            block
                            onClick={getInvoiceReport}
                          >
                            <FaDownload className="mr-2" />
                            Relatório Boletos
                          </Button>
                        </div>

                        <div className="p-2 bd-highlight">
                          <Button
                            variant="primary"
                            size="sm"
                            block
                            onClick={handleSendErrataInvoices}
                          >
                            <FaDownload className="mr-2" />
                            Errata Boletos
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Jumbotron>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <MissionaryCampaignSolicitationsTable
                  campaingnId={id}
                  url={`/api/missionary-campaign/solicitation/${id}`}
                />
              </Col>
            </Row>
          </Container>

          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Gerar Etiquetas por Regional</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleGenerateLabels}>
                <Form.Group controlId="exampleForm.SelectCustomSizeLg">
                  <Form.Label>Selecione uma Regional</Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    custom
                    onChange={(e) => setParishRegion(e.target.value)}
                    value={parishRegion}
                    defaultValue={parishRegion[0]}
                    required
                  >
                    {parishRegions.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.region_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" size="lg" block type="submit">
                  {generateLoading ? "Gerando ..." : "Gerar"}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default MissionaryCampaignView;
