import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { formatDateAndHoursUS } from "../../utils/formatDate";

import api from "../../services/api";
import { Link, useHistory } from "react-router-dom";

function MissionaryCollectionCreate() {
  const history = useHistory();

  const [submitLoading, setSubmitLoading] = useState(false);

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { begin_date, description, base_year } = data;

    setSubmitLoading(true);

    await api
      .post("/api/missionary-collection", {
        begin_dt: formatDateAndHoursUS(begin_date),
        description,
        base_year,
      })
      .then((resp) => {
        const { id } = resp.data;

        setSubmitLoading(false);

        history.push(`/missionary-collection-view/${id}`);

        return toast.success("✅ Dados cadastrados com sucesso!");
      })
      .catch((error) => {
        setSubmitLoading(false);

        if (error.response.status === 409) {
          return toast.error(
            "❌ Já existe uma Coleta Cadastrada para esse ano!"
          );
        }
        return toast.error(
          "⚠️ Não foi possível enviar os dados, verifique e tente novamente!"
        );
      });
  };

  return (
    <>
      <Container>
        <h1>Criar Nova Coleta Missionária</h1>

        <Link to="/missionary-collection">Voltar</Link>

        <Form className="mt-5" onSubmit={handleSubmit(onSubmit)} method="POST">
          <Form.Group>
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="description"
              name="description"
              ref={register}
              className="w-50"
              required
              placeholder="Ex: Coleta Missionária 2020/2021"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="begin_date">Data de Início</Form.Label>

            <Form.Control
              type="datetime-local"
              id="begin_date"
              name="begin_date"
              ref={register}
              className="w-50"
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Ano Base</Form.Label>
            <Form.Control
              as="input"
              rows={3}
              id="base_year"
              name="base_year"
              ref={register}
              className="w-50"
              required
              placeholder="Ex: 2021"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            {submitLoading ? "Salvando ..." : "Salvar"}
          </Button>
        </Form>
      </Container>
    </>
  );
}

export default MissionaryCollectionCreate;
