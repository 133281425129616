import React from 'react';

import './styles.css';

export default function Loading() {
    return (
        <div id="preloader">
            <div id="loader">
            </div>
        </div>
    )
}
