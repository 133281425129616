import { Container } from "react-bootstrap";
import DioceseTable from "../../components/DioceseTable";

export default function Dioceses() {
  return (
    <Container fluid>
      <DioceseTable url="/api/diocese" />
    </Container>
  );
}
