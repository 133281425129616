import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import formatCurrency from "../../utils/formatCurrency";
import { formatDate } from "../../utils/formatDate";

function DonationGroupstable({ url, id }) {
  const history = useHistory();
  const [donations, setDonations] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);

  useEffect(() => {
    async function loadParishes() {
      setLoading(true);

      await api.get(`${url}${id}?page=${page}`).then((resp) => {
        const response = resp.data.data.map((r) => ({
          ...r,
          donation_value: r.pivot.value,
        }));

        setDonations(response);
        setTotal(resp.data.total);
        setLastPage(resp.data.last_page);
        setLoading(false);

        if (page === 1) {
          setIsFirstPage(true);
        } else {
          setIsFirstPage(false);
        }

        if (page === lastPage) {
          setIsLastPage(true);
        } else {
          setIsLastPage(false);
        }
      });
    }
    loadParishes();
  }, [page, lastPage, url, id]);

  function nextPage() {
    if (page === lastPage) {
      return;
    }
    setPage(page + 1);
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  function handleGo(id) {
    history.push(`/donation-view/${id}`);
  }

  return (
    <>
      <div className="text-center mb-4">
        <h1>Doações Cofrinho</h1>
      </div>

      <Table striped bordered responsive hover className="text-center">
        <thead>
          <tr>
            <th>Código da Doação</th>
            <th>Título</th>
            <th>Descrição</th>
            <th>Valor Total</th>
            <th>Valor Doado</th>
            <th>Doado em</th>
            <th>Finalizada</th>
            <th>Visualizar</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr key="loading">
              <td colSpan="8" className="text-center">
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {donations.map((d) => (
            <tr key={d.id}>
              <td>{d.cod}</td>
              <td>{d.title}</td>
              <td>{d.description}</td>
              <td>
                {d.total_value
                  ? formatCurrency(parseFloat(d.total_value))
                  : "-"}
              </td>
              <td>
                {d.donation_value
                  ? formatCurrency(parseFloat(d.donation_value))
                  : "-"}
              </td>
              <td>{formatDate(d.created_at)}</td>
              <td>
                {d.finished ? (
                  <div className="bg-success text-white">Finalizado</div>
                ) : (
                    <div className="bg-danger text-white">Não finalizado</div>
                  )}
              </td>
              <td>
                <Button variant="link" size="sm" onClick={() => handleGo(d.id)}>
                  Visualizar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <Button variant="link" onClick={() => setPage(1)}>
          Primeiro
        </Button>
        <Button variant="primary" onClick={prevPage} disabled={isFirstPage}>
          Anterior
        </Button>
        <h5>Total: {total}</h5>
        <Button variant="primary" onClick={nextPage} disabled={isLastPage}>
          Próximo
        </Button>
        <Button variant="link" onClick={() => setPage(lastPage)}>
          Último
        </Button>
      </div>
    </>
  );
}

export default DonationGroupstable;
