import React, { useState } from "react";

import { Container, Form, Button } from "react-bootstrap";

import api from "../../services/api";
import { Link, useHistory } from "react-router-dom";

export default function ParishRegionCreate() {
  const history = useHistory();

  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [regionName, setRegionName] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitButtonLoading(true);

    const { data } = await api.post("/api/parish_region", {
      region_name: regionName,
    });

    setSubmitButtonLoading(false);

    history.push(`/parish-region-view/${data.id}`);
  }

  return (
    <Container>
      <h1>Adicionar Nova Regional CNBB</h1>

      <Link to="/parish-region">Voltar</Link>

      <Form onSubmit={handleSubmit} className="mt-4">
        <Form.Group controlId="regionName">
          <Form.Label>Nome da Região</Form.Label>
          <Form.Control
            type="text"
            size="lg"
            placeholder="Ex: Centro-Oeste"
            onChange={(e) => setRegionName(e.target.value)}
            required
            value={regionName}
            className="w-50"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          {submitButtonLoading ? "Salvando..." : "Salvar"}
        </Button>
      </Form>
    </Container>
  );
}
