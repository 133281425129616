import React, { useState } from "react";

//libs
import * as Yup from "yup";
import { Form, Input } from "@rocketseat/unform";
import { Link } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

//style
import { Wrapper, Content } from "../_layouts/auth/authStyle";

//assets
import Logo from "../../utils/logo/Logo";
import LogoSeja from "../../assets/img/sejatech.png";

import { SubmitButton } from "../../styles/utils";


//context
import { useReactAuth } from "../../contexts/hooks/AuthContext";

//validations
const schema = Yup.object().shape({
  email: Yup.string()
    .email("*E-mail inválido")
    .required("*O campo e-mail é obrigatório"),
  password: Yup.string().required("*O campo senha é obrigatório"),
});

export default function SignIn() {

  const { signIn, loading } = useReactAuth();

  const [showPassword, setShowPassword] = useState(false);

  function handleSubmit({ email, password }) {
    signIn(email, password);
  }

  function toggleShowPass() {
    setShowPassword(!showPassword);
  }

  return (
    <Wrapper>
      <Content>
        <Logo />
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input
            name="email"
            type="email"
            placeholder="Seu endereço de e-mail"
          />

          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Sua senha secreta"
          />

          <small
            onClick={toggleShowPass}
            style={{ marginTop: 10, textAlign: "left", cursor: "pointer" }}
          >
            {showPassword ? "Esconder" : "Mostrar"} senha
          </small>

          <SubmitButton loading={loading ? 1 : 0}>
            {loading ? <FaSpinner color="#fff" size={14} /> : "Acessar"}
          </SubmitButton>

          <Link to="register">Criar conta</Link>

          <small>
            Desenvolvido por{" "}
            <a
              href="https://sejatech.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={LogoSeja} alt="SejaTech" />
            </a>
          </small>
        </Form>
      </Content>
    </Wrapper>
  );
}
